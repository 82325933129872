import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormComplementFundDataSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {SchemaOf, object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

const FormComplementFundResolver: SchemaOf<FormComplementFundDataSchema> = object().shape({
	giin: string().notRequired(),
	giinIRS: string().notRequired(),
	isFundDispersed: string().notRequired(),
	isFundExclusive: string().notRequired(),
	isCvmFund: string().notRequired(),
});

const FormComplementFund: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {register, handleSubmit} = useForm<FormComplementFundDataSchema>({
		resolver: yupResolver(FormComplementFundResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formComplementFundData,
		},
	});
	// handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_COMPLEMENT_FUND_DATA",
			payload: {
				formComplementFundData: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Informações Complementares</U.UIText>
			<div className="flex-1">
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNTextInput label="Informe o GIIN" {...register("giin")} />
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Seu GIIN já consta no site do IRS?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("giinIRS")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="O Fundo de Investimento é pulverizado"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isFundDispersed")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="O Fundo de Investimento é exclusiva"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isFundExclusive")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="O Fundo de Investimento é registrado na CVM"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isCvmFund")}
						/>
					</div>
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormComplementFund;
