import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormProfessionalDataSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

// interface FormProfessionalDataProps {}

const FormFinancialResolver: SchemaOf<FormProfessionalDataSchema> = object().shape({
	occupation: mixed().notRequired(),
});

const profissionOptions = [
	{
		id: uniqid(),
		label: "Autônomo",
		value: "Autônomo",
	},
	{
		id: uniqid(),
		label: "Aposentado",
		value: "Aposentado",
	},
	{
		id: uniqid(),
		label: "Assalariado",
		value: "Assalariado",
	},
	{
		id: uniqid(),
		label: "Estudante",
		value: "Estudante",
	},
	{
		id: uniqid(),
		label: "Sem Ocupação Profissional Atualmente",
		value: "Sem Ocupação Profissional Atualmente",
	},
	{
		id: uniqid(),
		label: "Outro",
		value: "Outro",
	},
];

const specialOptions = ["Outro", "Assalariado", "Autônomo"];

const FormProfessionalData: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {
		watch,
		register,
		handleSubmit,
		formState: {errors},
		reset,
	} = useForm<FormProfessionalDataSchema>({
		resolver: yupResolver(FormFinancialResolver),
		mode: "onBlur",
		defaultValues: {
			...state?.formProfessionalData,
		},
	});
	const showOtherFields = watch("occupation.name", "");
	// Effects
	React.useEffect(() => {
		// Clear form if option change
		if (showOtherFields && !specialOptions.includes(showOtherFields)) {
			reset({
				occupation: {
					mainOccupation: "",
					nameCompany: "",
					cnpjCompany: "",
				},
			});
		}
	}, [reset, showOtherFields]);
	// React.useEffect(() => {
	// 	const subscription = watch((value, {name, type}) => console.log(value, name, type));
	// 	return () => subscription.unsubscribe();
	// }, [watch]);
	// handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_PROFESSIONAL_DATA",
			payload: {
				formProfessionalData: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Dados Profissionais</U.UIText>
			<div className="flex-1 flex flex-col gap-2">
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNSelectInput
							label="Profissão"
							options={profissionOptions}
							type="SIMPLE"
							disabled={loading}
							error={errors.occupation?.name?.message}
							{...register("occupation.name")}
						/>
					</div>
					{specialOptions.includes(showOtherFields || "") && (
						<>
							<div className="input">
								<C.CPNTextInput
									label="Ocupação profissional"
									error={errors.occupation?.mainOccupation?.message}
									{...register("occupation.mainOccupation")}
								/>
							</div>
							<div className="input">
								<C.CPNTextInput
									label="Empresa onde Trabalha"
									error={errors.occupation?.nameCompany?.message}
									{...register("occupation.nameCompany")}
								/>
							</div>
							<div className="input">
								<C.CPNTextInput
									label="CNPJ da Empresa"
									error={errors.occupation?.cnpjCompany?.message}
									{...register("occupation.cnpjCompany")}
								/>
							</div>
						</>
					)}
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormProfessionalData;
