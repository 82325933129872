// Component generated with util/vox-create-component.js
import React from "react";
import * as C from "../../component";
import * as U from "../../ui";
import {getAllProductsFund} from "../../service/api/fund-api";
import {getAssetListByType} from "../../service/api/assets-api";
import {FundProduct, ResponseFundProduct} from "../../types/fund";
import {Asset, ResponseGetAsset} from "../../types/asset";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {CPNTable} from "../../component";
import {FundProductContext} from "../../context/FundProductContext";

interface FormFundProductListProps {
	list: any[];
	onEdit: (row: any) => void;
}

interface FundProductItemModel {
	id: string;
	name: string;
	fullName: string;
	consolidateDate: string;
	profitTarget: string;
}

const FormFundProductList: React.FC<FormFundProductListProps> = ({onEdit}) => {
	const {dispatch} = React.useContext(FundProductContext);
	const history = useHistory();
	const [loading, setLoading] = React.useState<boolean>(true);
	const [error, setError] = React.useState<boolean>(true);
	const [list, setList] = React.useState<any[]>([]);
	const [assetComboList, setAssetComboList] = React.useState<any>([]);
	// Effects Here
	React.useEffect(() => {
		getFundProductListHandler();
	}, []);
	// Handlers Here

	const getFundProductListHandler = async (): Promise<any> => {
		setLoading(true);
		const response: ResponseGetAsset = await getAssetListByType({type: "BUSINESS"});
		if (response.status !== "success") {
			console.error("Error", response.message);
			setLoading(false);
			setError(true);
			return;
		}
		setError(false);
		const assetListResult = response.message as Asset[];
		const resultList = assetListResult.map((asset: Asset) => {
			return {
				id: asset.identity?.type + "#" + asset.identity?.id,
				label: asset.name,
				value: asset.identity?.type + "#" + asset.identity?.id,
			};
		});
		setAssetComboList(resultList);
		await handleProductList();
	};

	const handleProductList = async () => {
		const result: ResponseFundProduct = await getAllProductsFund();
		if (result.status !== "success") {
			console.error("Error on load list", result.message);
			setError(true);
			return;
		}
		const resultList = (result.message as FundProduct[]).map((fundProduct: FundProduct) => {
			return {
				...fundProduct,
				consolidateDate: fundProduct?.consolidateDate
					? moment(new Date(fundProduct?.consolidateDate)).format("DD/MM/YYYY")
					: "",
			};
		});
		console.log(resultList);
		setList(resultList);
		setLoading(false);
	};

	const onFundProductItemSelected = (item: {values: FundProductItemModel}) => {
		dispatch({type: "CLEAR_FUND_PRODUCT_DATA"});
		history.push(`/FormFundProductDetail/${item.values.id}`);
	};

	const addNewFundProduct = () => {
		dispatch({type: "CLEAR_FUND_PRODUCT_DATA"});
		history.push("/FormFundProduct");
	};

	return (
		<div className="h-full overflow-y-auto">
			<br />
			<div className="header">
				<div className="header flex justify-between items-center mb-4">
					<div className="title flex flex-col">
						<U.UIText preset="HEADLINE_03">Lista de Fundos</U.UIText>
						<U.UIText preset="BODY">Lista de Fundos da Vox.</U.UIText>
					</div>
					<div className="action">
						<U.UIButton
							righticon="chevronRight"
							label="Adicionar novo Fundo"
							onClick={addNewFundProduct}
							// disabled={loading}
						/>
					</div>
				</div>
			</div>
			<br />
			<div className="content">
				<div className="list">
					{loading ? (
						<C.CPNAlert
							title="Aguarde"
							description="Aguarde um momento enquanto trazemos todos os fundos"
							type="LOADING"
							loading
						/>
					) : error ? (
						<C.CPNAlert
							title="Tentar novamente"
							description="Aconteceu um error, tente novamente"
							type="ERROR"
							firstActionLabel="Tentar novamente"
							onFirstAction={getFundProductListHandler}
						/>
					) : !list.length && !loading ? (
						<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
							<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
						</div>
					) : (
						<CPNTable
							onEdit={onFundProductItemSelected}
							columns={[
								{
									Header: "ID",
									accessor: "id",
								},
								{
									Header: "Nome",
									accessor: "name",
								},
								{
									Header: "Nome completo",
									accessor: "fullName",
								},
								{
									Header: "Data Consolidação",
									accessor: "consolidateDate",
								},
								{
									Header: "Rentabilidade Alvo",
									accessor: "profitTarget",
								},
							].map((e) => ({...e, Cell: ({cell: {value}}: any) => value || "-"}))}
							data={list}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
export default FormFundProductList;
