export const inputFormatterDate = (e: React.KeyboardEvent<HTMLInputElement>) => {
	const input: any = e.target;

	if (e.charCode < 47 || e.charCode > 57) {
		e.preventDefault();
	}
	const len = input.value.length;
	if (len >= 10) {
		e.preventDefault();
	}
	if (len !== 1 || len !== 3) {
		if (e.charCode === 47) {
			e.preventDefault();
		}
	}

	if (len === 2) {
		input.value += "/";
	}

	if (len === 5) {
		input.value += "/";
	}
};
