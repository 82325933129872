import {FormRegisterFundSchema} from "../FormRegister.types";
import {SchemaOf, object, mixed, string, number} from "yup";

export const FormRegisterFundResolver: SchemaOf<FormRegisterFundSchema> = object().shape({
	personId: string().notRequired(),
	email: string().notRequired(),
	phone: string().notRequired(),
	status: string().notRequired(),
	cvmType: string().notRequired(),
	statusLabel: string().notRequired(),
	relacionamento: string().notRequired(),
	corporateName: string().notRequired(),
	tradeName: string().notRequired(),
	cnpj: string().notRequired(),
	suitability: string().notRequired(),
	suitabilityDismiss: string().notRequired(),
	risk: string().notRequired(),
	reviewRegistration: string().notRequired(),
	relationship: string().notRequired(),
	address: mixed().notRequired(),
	incorporateDate: string().notRequired(),
	mainActivity: string().notRequired(),
	contactName: string().notRequired(),
	contactPhone: string().notRequired(),
	contactEmail: string().notRequired(),
	netPatrimony: number().notRequired(),
	application: number().notRequired(),
	property: number().notRequired(),
	dateFinancial: string().notRequired(),
	beneficiaries: mixed().notRequired(),
	giin: string().notRequired(),
	giinIRS: string().notRequired(),
	isFundDispersed: string().notRequired(),
	isFundExclusive: string().notRequired(),
	isCvmFund: string().notRequired(),
	externalAccount: mixed().notRequired(),
	representative: mixed().notRequired(),
	extraTaxDomicile: string().notRequired(),
	taxId: string().notRequired(),
	thirtCanOperateName: string().notRequired(),
	thirtCanOperateCPF: string().notRequired(),
	portfolioAdministrator: string().notRequired(),
	custodyResponsible: string().notRequired(),
	fileAddress: mixed().notRequired(),
	fileIdentity: mixed().notRequired(),
	fileProcuration: mixed().notRequired(),
	form: mixed().notRequired(),
	document: mixed().notRequired(),
	administrator: string().notRequired(),
	administratorCnpj: string().notRequired(),
	manager: string().notRequired(),
	managerCnpj: string().notRequired(),
	custodian: string().notRequired(),
	custodianCnpj: string().notRequired(),
});
