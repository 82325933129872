// Component generated with util/create-component.js
import styled from "styled-components";
import tw from "twin.macro";

/**
 * Components
 */
export const AppFormWrapper = styled.div`
	${tw`flex flex-col`}
	& > .form {
		${tw`flex flex-row flex-wrap`}
		& > .input {
			${tw`w-1/2 mb-7`}
			&:nth-child(2n + 1) {
				${tw`pr-4`}
			}
			&:nth-child(2n) {
				${tw`pl-4`}
			}
		}
	}
	& > .action {
		${tw`flex justify-end mb-6`}
	}
`;
