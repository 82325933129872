import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormBeneficiariesDataSchema} from "../FormRegister.types";
import {useForm, useFieldArray} from "react-hook-form";
import {SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

const FormBeneficiariesDataResolver: SchemaOf<FormBeneficiariesDataSchema> = object().shape({
	beneficiaries: mixed().notRequired(),
});

const FormBeneficiariesData: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {control, register, handleSubmit} = useForm<FormBeneficiariesDataSchema>({
		resolver: yupResolver(FormBeneficiariesDataResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formBeneficiariesData,
		},
	});
	const {
		fields: fieldsBeneficiaries,
		append: appendBeneficiaries,
		remove: removeBeneficiaries,
	} = useFieldArray({
		control,
		name: "beneficiaries",
	});
	// Handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_BENEFICIARIES_DATA",
			payload: {
				formBeneficiariesData: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<div className="flex gap-4 justify-between">
				<U.UIText preset="HEADLINE_05">Beneficiários Finais</U.UIText>
				<U.UIButton
					label="Adicionar Beneficiários"
					preset="LINK"
					onClick={() => appendBeneficiaries({id: uniqid()})}
					righticon="addCircle"
				/>
			</div>
			<div className="flex-1 flex flex-col gap-4">
				{fieldsBeneficiaries.map((_field, index) => (
					<div className="flex flex-col p-4 bg-gray-glow rounded border border-gray-light gap-4">
						<div className="input">
							<C.CPNTextInput label="Nome Completo" {...register(`beneficiaries.${index}.name`)} />
						</div>
						<div className="input">
							<C.CPNDatePicker
								label="Data de Nascimento"
								disabled={loading}
								{...register(`beneficiaries.${index}.birthDate`)}
							/>
						</div>
						<div className="input">
							<C.CPNTextInput
								label="Nacionalidade"
								{...register(`beneficiaries.${index}.nationality`)}
							/>
						</div>
						<div className="input">
							<C.CPNTextInput
								label="% de Participação (Direta e/ou Indireta)"
								{...register(`beneficiaries.${index}.sharePercentage`)}
							/>
						</div>
						<div className="input">
							<C.CPNTextInput
								label="Número de Identificação Fiscal (Tax ID/CPF)"
								{...register(`beneficiaries.${index}.cpf`)}
							/>
						</div>
						<div className="input">
							<C.CPNTextInput
								label="Domicílio Fiscal"
								{...register(`beneficiaries.${index}.taxDomicile`)}
							/>
						</div>
						<U.UIButton
							disabled={loading}
							icon="trash"
							preset="LINK"
							onClick={() => removeBeneficiaries(index)}
						/>
					</div>
				))}
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormBeneficiariesData;
