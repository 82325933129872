// Component generated with util/vox-create-component.js
import React from "react";
import {AppFormWrapper} from "./AppForm.styled";
import {AppFormProps, AppFormSchema} from "./AppForm.types";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {string, SchemaOf, object} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as C from "./../../component";
import * as U from "./../../ui";

const AppFormResolver: SchemaOf<AppFormSchema> = object().shape({
	name: string().required(),
	address: string().required(),
	cep: string().required(),
	email: string().required(),
	sex: string().required(),
});

export const AppForm: React.VFC<AppFormProps> = ({...args}) => {
	// Context Here
	// States Here
	// Hooks
	const history = useHistory();
	const {
		register,
		handleSubmit,
		formState: {errors},
	} = useForm<AppFormSchema>({
		resolver: yupResolver(AppFormResolver),
		mode: "onSubmit",
	});
	// Effects Here
	// Handlers Here
	// tslint:disable-next-line:no-console
	const onSubmit = handleSubmit((data) => console.log("Data: ", data));
	// Component
	return (
		<AppFormWrapper {...args}>
			<div className="title mb-4 flex flex-col pb-2">
				<U.UIText preset="HEADLINE_05">App Form</U.UIText>
				<U.UIText preset="BODY">Some description here</U.UIText>
			</div>
			<div className="form">
				<div className="input">
					<C.CPNTextInput label="Name" error={errors.sex?.message} {...register("name")} />
				</div>
				<div className="input">
					<C.CPNTextInput label="E-mail" error={errors.sex?.message} {...register("email")} />
				</div>
				<div className="input">
					<C.CPNTextInput label="Address" error={errors.sex?.message} {...register("address")} />
				</div>
				<div className="input">
					<C.CPNTextInput label="Cep" error={errors.sex?.message} {...register("cep")} />
				</div>
				<div className="input">
					<C.CPNSelectInput
						label="Código de Contrato da Empresa"
						options={[]} // Add here ll options nedded
						type="SIMPLE" // Single check or multicheck
						error={errors.sex?.message}
						{...register("sex")}
					/>
				</div>
			</div>
			<div className="action">
				<U.UIButton label="Submit" onClick={onSubmit} />
			</div>
		</AppFormWrapper>
	);
};

export default AppForm;
