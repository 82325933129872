import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormComplementDataSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {SchemaOf, object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

const FormComplementResolver: SchemaOf<FormComplementDataSchema> = object().shape({
	isNonProfit: string().notRequired(),
	donors: string().notRequired(),
	isTradedCompany: string().notRequired(),
	stockExchange: string().notRequired(),
	subsidizedTradeCompany: string().notRequired(),
	subsidizedTCompanyName: string().notRequired(),
	subsidizedTStockExchange: string().notRequired(),
	isRegulatedFinancialSystem: string().notRequired(),
	regulatedFinancialSystemName: string().notRequired(),
	isSubsidizedRegulateFinancial: string().notRequired(),
	subsidizedRegulateCompany: string().notRequired(),
	subsidizedRegulateEntity: string().notRequired(),
	isLinkedBancoCentral: string().notRequired(),
	isStartUp: string().notRequired(),
	isProfessionalInvestidor: string().notRequired(),
	isQualifiedInvestidor: string().notRequired(),
	isLinkedVox: string().notRequired(),
	linkedVox: string().notRequired(),
	ofThirtParty: string().notRequired(),
	isPep: string().notRequired(),
	pepName: string().notRequired(),
	pepCPF: string().notRequired(),
	pepRelationCompany: string().notRequired(),
	pepOcupation: string().notRequired(),
	pepTaxDomicile: string().notRequired(),
	pepNationality: string().notRequired(),
	pepCompany: string().notRequired(),
});

const FormComplement: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {register, handleSubmit} = useForm<FormComplementDataSchema>({
		resolver: yupResolver(FormComplementResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formComplementData,
		},
	});
	// handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_COMPLEMENT_DATA",
			payload: {
				formComplementData: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Informações Complementares</U.UIText>
			<div className="flex-1">
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNSelectInput
							label="O cliente é entidade sem fins lucrativos?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isNonProfit")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput label="Informe os doadores" {...register("donors")} />
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="O cliente é cia aberta listada em bolsa?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isTradedCompany")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Informe a bolsa onde as ações são listadas"
							{...register("stockExchange")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="O cliente é subsidiária integral de cia aberta listada em bolsa?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("subsidizedTradeCompany")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Informe o nome da empresa principal"
							{...register("subsidizedTCompanyName")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Informe o nome da bolsa onde as ações são negociadas"
							{...register("subsidizedTStockExchange")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="O cliente é empresa regulada pelo sistema financeiro?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isRegulatedFinancialSystem")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Informe o nome da entidade reguladora"
							{...register("regulatedFinancialSystemName")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="O cliente é subsidiária integral de empresa regulada pelo sistema financeiro?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isSubsidizedRegulateFinancial")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Informe o nome da empresa principal"
							{...register("subsidizedRegulateCompany")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Nome da entidade reguladora"
							{...register("subsidizedRegulateEntity")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="O cliente é entidade governamental ou vinculada ao Banco Central?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isLinkedBancoCentral")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="O cliente é uma StartUp (em estágio Inicial de desenvolvimento e operação, baixa rentabilidade e de pequeno porte)?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isStartUp")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="É Investidor Profissional?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isProfessionalInvestidor")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="É Investidor Qualificado?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isQualifiedInvestidor")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Beneficiário Final ou Administradores da Companhia são Pessoas Vinculadas à VOX Capital?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isLinkedVox")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput label="Informe o vínculo com a Vox" {...register("linkedVox")} />
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Opera por conta de terceiros, no caso dos gestores de fundos de investimento e de carteiras administradas"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("ofThirtParty")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="A empresa ou algum diretor, administrador, procurador, representante legal, sócio ou beneficiário final é uma pessoa 
								politicamente exposta (PEP)"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("isPep")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput label="Nome Completo" {...register("pepName")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="CPF" {...register("pepCPF")} />
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Relacionamento com a Companhia"
							{...register("pepRelationCompany")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput label="Cargo ou Função PEP" {...register("pepOcupation")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Domicílio Fiscal" {...register("pepTaxDomicile")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Nacionalidade" {...register("pepNationality")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Empresa/orgão público" {...register("pepCompany")} />
					</div>
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormComplement;
