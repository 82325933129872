import * as React from "react";
import UIText from "./../Text/Text";
import UIIcon from "./../Icon/Icon";

const Loading: React.FC<{}> = () => {
	return (
		<div className="fixed left-0 top-0 right-0 bottom-0 z-50 bg-gray bg-opacity-70 flex items-center justify-center">
			<div className="flex gap-2">
				<div className="animate-spin h-6 w-6  items-center justify-center flex">
					<UIIcon name="loading" color="WHITE" />
				</div>
				<UIText color="WHITE">Aguarde um momento</UIText>
			</div>
		</div>
	);
};

export default Loading;
