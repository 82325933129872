// Component generated with util/create-component.js
import styled from "styled-components";
import tw from "twin.macro";

/**
 * Components
 */
export const FormImpactWrapper = styled.div`
	${tw`flex flex-col`}
	& > .forms {
		& > .form-container {
			> .form {
				${tw`flex flex-row flex-wrap flex-1`}
				& > .input:not(.free) {
					${tw`sm:w-full lg:w-1/2 2xl:w-1/3`}
				}
				& > .input {
					${tw`mb-7 px-2`}
					&.group {
						${tw`flex flex-col gap-1`}
						.control {
							${tw`flex gap-2`}
							[data-name="text-input"] {
								${tw`flex-1`}
							}
						}
						.action {
							${tw`bg-primary-lightest rounded-lg flex justify-center items-center`}
						}
					}
				}
			}
			/* & > .preview {
				${tw`flex px-4 pb-7`}
				& > .container {
					width: 800px;
					${tw`flex flex-col gap-6`}
					> .data {
						${tw`flex flex-col xl:w-2/3 gap-2`}
						span {
							line-height: 1.2em;
						}
					}
					> .media {
						.video,
						.images {
							${tw`flex flex-col gap-2`}
						}
						.content {
							${tw`flex flex-row flex-wrap gap-8`}
							.media-img {
								height: 360px;
								width: 360px;
								background-position: center;
								background-size: cover;
								background-repeat: no-repeat;
								border-radius: 8px;
								overflow: hidden;
							}
						}
					}
				}
			} */
		}
		& > .action {
			${tw`flex justify-end mb-6`}
		}
	}
`;
