// Component generated with util/vox-create-component.js
import React from "react";
import {UIICon} from "../../ui";
import {Dropdown} from "./../Dropdown/Dropdown";
import {HeaderWrapper, HeaderBodyWrapper} from "./Header.styled";
import {HeaderProps} from "./Header.types";
import {AuthContext} from "./../../context/AuthContext";
import Logo from "./../../assets/images/vox-logo-pink.svg";

export const Header: React.VFC<HeaderProps> = ({...args}) => {
	// Context Here
	const {user, removeSession} = React.useContext(AuthContext);
	// States Here
	// Effects Here
	// Handlers Here
	return (
		<HeaderWrapper {...args}>
			<HeaderBodyWrapper>
				<div className="logo">
					<img src={Logo} alt="" width={120} />
				</div>
				<div className="flex flex-row items-center">
					<UIICon name="user" size="xs" color="DARK" />
					<Dropdown
						label={`Olá, ${user?.name || user?.userName}`}
						options={[{label: "Sair", action: () => removeSession()}]}
					/>
				</div>
			</HeaderBodyWrapper>
		</HeaderWrapper>
	);
};

export default Header;
