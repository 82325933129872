import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormResidentSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {SchemaOf, object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

const FormResidentResolver: SchemaOf<FormResidentSchema> = object().shape({
	extraTaxDomicile: string().notRequired(),
	taxId: string().notRequired(),
	thirtCanOperateName: string().notRequired(),
	thirtCanOperateCPF: string().notRequired(),
	portfolioAdministrator: string().notRequired(),
	custodyResponsible: string().notRequired(),
});

const FormInvestorResident: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {register, handleSubmit} = useForm<FormResidentSchema>({
		resolver: yupResolver(FormResidentResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formResidentInvestors,
		},
	});
	// handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_RESIDENT_INVESTORS",
			payload: {
				formResidentInvestors: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Investidores não Residentes</U.UIText>
			<div className="flex-1">
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNTextInput label="Domicílio Fiscal" {...register("extraTaxDomicile")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="TAX ID" {...register("taxId")} />
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Nome da Pessoa autorizada a emitir ordens"
							{...register("thirtCanOperateName")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="CPF da pessoa autorizada a emitir ordens"
							{...register("thirtCanOperateCPF")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Administradores da instituição ou responsáveis pela administração da carteira"
							{...register("portfolioAdministrator")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Responsável pela custódia dos seus valores mobiliários no Brasil"
							{...register("custodyResponsible")}
						/>
					</div>
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormInvestorResident;
