import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormContactDataSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {SchemaOf, object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

const FormContactDataResolver: SchemaOf<FormContactDataSchema> = object().shape({
	contactName: string().notRequired(),
	contactPhone: string().notRequired(),
	contactEmail: string().notRequired(),
});

const FormContactData: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {register, handleSubmit} = useForm<FormContactDataSchema>({
		resolver: yupResolver(FormContactDataResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formContactData,
		},
	});
	// handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_CONTACT_DATA",
			payload: {
				formContactData: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Dados de Contato</U.UIText>
			<div className="flex-1">
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNTextInput label="Nome" {...register("contactName")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Telefone" {...register("contactPhone")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="E-mail" {...register("contactEmail")} />
					</div>
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormContactData;
