/* eslint-disable react/style-prop-object */
// Component generated with util/vox-create-component.js
import React from "react";
import {CSVLink} from "react-csv";
import {FormStepper, WrapperContainer, WrapperGrid, WrapperTitle} from "./FormFundProduct.styled";
import * as C from "../../component";
import * as U from "../../ui";
import {
	getProductFundById,
	consolidateProductFund,
	getImageDownloadLink,
	downloadFile,
	getQuarterlyReportFund,
	getHistoryCalcBondFund,
	getHistoryCalcByFundId,
} from "../../service/api/fund-api";
import {FundProduct, ResponseFundProduct} from "../../types/fund";
import moment from "moment";
import {Document} from "../../types/document";
import FundProductContext from "../../context/FundProductContext";
import {useHistory, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {SchemaOf, object, string} from "yup";
import * as Forms from "./Forms/Forms";

type TTab = {
	id: string;
	label: string;
};

const TABS: TTab[] = [
	{
		label: "Geral",
		id: "FormOverview",
	},
	{
		label: "Gerenciar Fundo",
		id: "FormFund",
	},
	// {
	// 	label: "Cotistas",
	// 	id: "FormShareholders",
	// },
	// {
	// 	label: "Extratos",
	// 	id: "FormStatment",
	// },
	{
		label: "Mútuos",
		id: "FormLoan",
	},
	// {
	// 	label: "Performance",
	// 	id: "FormPerformance",
	// },
	{
		label: "Chamada de Capital",
		id: "FormTransaction",
	},
];

const FormDatePickerResolver: SchemaOf<{readonly date?: string}> = object().shape({
	date: string().required(),
});

export const FormFundProductDetail: React.FC<any> = () => {
	// Context
	const {state, dispatch} = React.useContext(FundProductContext);
	const [list, setList] = React.useState<any>([]);
	const [showForm, setShowForm] = React.useState<boolean>(false);
	const [currentConsolidate, setCurrentConsolidate] = React.useState<any>(null);
	const [dateQuarterlyReport, setDateQuarterlyReport] = React.useState<boolean>();
	const [historyCalcBondReport, setHistoryCalcBondReport] = React.useState<boolean>();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [currentError, setCurrentError] = React.useState<boolean>(false);
	const [isEdit, setIsEdit] = React.useState<boolean>(false);
	const [image, setImage] = React.useState<any>(null);
	const [fund, setFundProduct] = React.useState<FundProduct>();
	const [listSumaryHistoryCalc, setListSumaryHistoryCalc] = React.useState<any>([]);
	const [listHistoryCalc, setListHistoryCalc] = React.useState<any>([]);
	const [currentForm, setcurrentForm] = React.useState<TTab>(TABS[0]);

	const {fundId} = useParams<any>();
	const history = useHistory();
	// Effects Here
	React.useEffect(() => {
		if (fundId) {
			getFundProduct();
			getListHistoryCalc();
		}
	}, [fundId]);
	// Handlers Here
	const {
		register,
		handleSubmit,
		formState: {errors},
		trigger,
		reset,
	} = useForm<{readonly date?: string}>({
		resolver: yupResolver(FormDatePickerResolver),
		mode: "onSubmit",
	});

	const download = () => {};

	const downloadImage = async () => {
		setLoading(true);
		try {
			const downloadInfo = await getImageDownloadLink(image);
			const documentInfo = downloadInfo as Document;
			if (documentInfo.name && documentInfo.fileUrl) {
				const response = await downloadFile(documentInfo.fileUrl);
				const objectURL = URL.createObjectURL(response.data);
				const link = document.createElement("a");
				link.href = objectURL;
				link.setAttribute("download", documentInfo.name + "." + documentInfo.fileExtension);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (err: any) {
			console.error("Error on dowloading file", err.message);
			setCurrentError(true);
		}
		setLoading(false);
	};

	const onDateQuarterlyReport = () => setDateQuarterlyReport(true);
	const downloadQuarterlyReport = handleSubmit(async (data) => {
		setLoading(true);
		try {
			if (!fund?.id || !data || !data.date) return;
			const referenceDate = moment(data.date, "DD/MM/YYYY").format("YYYY-MM-DD");
			const response = await getQuarterlyReportFund(fund?.id, referenceDate);
			const blob = new Blob([response.message], {type: "application/xml"});
			const objectURL = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = objectURL;
			link.setAttribute("download", `Informe Trimestral CVM_${fund?.name}_${referenceDate}.xml`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			setDateQuarterlyReport(false);
		} catch (err: any) {
			console.error("Error on dowloading file", err.message);
			setCurrentError(true);
		}
		setLoading(false);
	});

	const onHistoryCalcBondReport = () => {
		generateCSVHistoryCalcBond();
		setHistoryCalcBondReport(true);
	};

	const onConsolidate = (row: any) => setCurrentConsolidate(true);
	const consolidateItem = async () => {
		if (!fund || !fund.id) {
			console.error("Doesn't exist the fund.");
			setCurrentError(true);
			return;
		}
		console.log("Current consolidate", fund.id);
		setLoading(true);
		try {
			const response = await consolidateProductFund(fund.id);
			console.log("Response consolidate", response);

			if (response.status !== "success") {
				console.error("Error on consolidate fund", response.message);
				throw new Error();
			}
			setLoading(false);

			const fundIndex = list.findIndex((fundAux: FundProduct) => fundAux.id === fund.id);
			if (response.message && (response.message as number)) {
				list[fundIndex].consolidateDate = moment(new Date(response.message as number)).format(
					"DD/MM/YYYY",
				);
			}
			setList(list);

			setCurrentConsolidate(null);
		} catch (err: any) {
			console.error("Error on consolidate fund", err.message);
			setLoading(false);
			setCurrentConsolidate(null);
			setCurrentError(true);
			return;
		}
	};

	const getFundProduct = async () => {
		const fundResult: ResponseFundProduct = await getProductFundById(fundId);
		if (fundResult.status !== "success") {
			console.error("Error on getting fund", fundResult.message);
			setCurrentError(true);
			return;
		}
		const fundProduct = fundResult.message as FundProduct;
		const dataToReset = {
			...fundProduct,
		};
		if (fundProduct.image) {
			setImage(fundProduct.image);
		}
		console.log(dataToReset);
		setFundProduct(dataToReset);
		dispatch({
			type: "SET_FUND_PRODUCT_DATA",
			payload: {
				FormFundProduct: dataToReset,
			},
		});
		toogle();
	};
	const toogle = () => {
		setIsEdit(true);
		setShowForm(!showForm);
	};

	const getListHistoryCalc = async () => {
		const historyCalcResult = await getHistoryCalcByFundId(fundId);
		if (historyCalcResult.status !== "success") {
			console.error("Error on getting fund", historyCalcResult.message);
			setCurrentError(true);
			return;
		}
		const historyCalc = historyCalcResult.message;
		setListSumaryHistoryCalc(historyCalc);
		toogle();
	};

	const editCurrentFund = () => {
		dispatch({
			type: "SET_FUND_PRODUCT_DATA",
			payload: {
				mode: "EDIT",
				FormFundProduct: {
					// Common data
					id: fund?.id,
					active: fund?.active,
					consolidateDate: fund?.consolidateDate,
					expectation: fund?.expectation,
					fullName: fund?.fullName,
					image: fund?.image,
					imageFile: fund?.imageFile,
					imagePath: fund?.imagePath,
					assetList: fund?.assetList,
					documentList: fund?.documentList,
					name: fund?.name,
					profitTarget: fund?.profitTarget,
					risk: fund?.risk,
					summary: fund?.summary,
					taxation: fund?.taxation,
				},
			},
		});
	};

	const generateCSVHistoryCalcBond = async () => {
		const listCSV: any[] = [];
		for (const historySummary of listSumaryHistoryCalc) {
			const historyCalcResult = await getHistoryCalcBondFund(fundId, historySummary?.asset?.id);
			if (historyCalcResult.status !== "success") {
				console.error("Error on getting fund", historyCalcResult.message);
				setCurrentError(true);
				return;
			}
			const historyCalc = historyCalcResult.message;
			listCSV.push(...historyCalc);
		}
		setListHistoryCalc(listCSV);
		toogle();
	};

	const filterColumns = (data: any) => {
		if (!data || data.length <= 0) return;
		const columns = Object.keys(data[0]);
		const headers: any = [];
		columns.forEach((col, idx) => {
			headers.push({label: col, key: col});
		});
		return headers;
	};

	const setForm = (tab: any) => {
		if (tab === TABS[1]) {
			editCurrentFund();
		}
		setcurrentForm(tab);
	};

	return (
		<div className="flex gap-8 h-full">
			{currentConsolidate && (
				<C.CPNAlert
					isModal
					title="Consolidar"
					description="Tem certeza que consolidar este fundo?"
					type="INFO"
					loading={loading}
					onCancel={() => !loading && setCurrentConsolidate(null)}
					onAccept={() => !loading && consolidateItem()}
				/>
			)}
			{dateQuarterlyReport && (
				<C.CPNAlert
					isModal
					title="Informe Trimestral CVM"
					description="Qual data de referência para gerar o informe?"
					body={
						<>
							<div className="input">
								<C.CPNDatePicker disabled={loading} {...register("date")} />
							</div>
						</>
					}
					type="INFO"
					loading={loading}
					onCancel={() => !loading && setDateQuarterlyReport(false)}
					onAccept={() => !loading && downloadQuarterlyReport()}
				/>
			)}
			<div className="container h-full overflow-y-auto">
				<div>
					<U.UIButton preset="LINK" icon="arrowBack" label="Voltar" onClick={history.goBack} />
				</div>
				<U.UIText preset="HEADLINE_05">Informações do Fundo</U.UIText>
				<br />
				<div className="flex flex-col justify-between">
					<div>
						<U.UIText preset="BODY" color="GREY">
							ID: {fundId}.
						</U.UIText>
					</div>
				</div>
				{loading ? (
					<C.CPNAlert
						title="Carregando"
						description="Aguarde um momento enquanto trazemos as informações do cotista"
						type="LOADING"
						loading
					/>
				) : (
					<>
						<div className={`${currentForm.id === "FormOverview" ? "" : "hidden"}`}>
							<U.UIText preset="BODY">
								<div className="flex items-center rounded gap-2">
									<U.UIButton
										label={"Consolidar"}
										outline
										preset="DARK"
										loading={currentConsolidate}
										onClick={onConsolidate}
									/>
									<U.UIButton
										onClick={onDateQuarterlyReport}
										icon="download"
										label="Informe Trimestral CVM"
										outline
									/>
								</div>
							</U.UIText>
							<br />
							<WrapperContainer>
								<WrapperTitle>
									<U.UIText preset="SUBTITLE" color="INHERIT">
										Dados básicos
									</U.UIText>
								</WrapperTitle>
								<WrapperGrid>
									<FundMetaData label="ID Carteira (Inoa)" value={fund?.id} />
									<FundMetaData label="Nome" value={fund?.name} />
									<FundMetaData label="Nome Completo" value={fund?.fullName} />
									<FundMetaData label="Rentabilidade Alvo" value={fund?.profitTarget} />
								</WrapperGrid>
								{!image ? (
									<div className="border p-2 flex gap-2 items-center rounded">
										<U.UIICon name="info" /> <U.UIText preset="SUBTITLE">Fundo sem imagem</U.UIText>
									</div>
								) : (
									<U.UIButton
										onClick={downloadImage}
										icon="download"
										label="Baixar imagem do fundo"
										outline
									/>
								)}
							</WrapperContainer>
						</div>
						<div className="flex-1 h-full">
							<div className={`${currentForm.id === "FormFund" ? "" : "hidden"}`}>
								<Forms.FormFund />
							</div>
							<div className={`${currentForm.id === "FormLoan" ? "" : "hidden"}`}>
								<Forms.FormLoan />
							</div>
							<div className={`${currentForm.id === "FormPerformance" ? "" : "hidden"}`}>
								<Forms.FormPerformance />
							</div>
							<div className={`${currentForm.id === "FormTransaction" ? "" : "hidden"}`}>
								<Forms.FormTransaction />
							</div>
							<div className={`${currentForm.id === "FormStatment" ? "" : "hidden"}`}>
								<Forms.FormStatment />
							</div>
						</div>
					</>
				)}
			</div>
			<FormStepper>
				<div className="px-3 mb-1">
					<U.UIText preset="BODY_03" color="GREY">
						Menu
					</U.UIText>
				</div>
				<ul>
					{TABS.map((tab, _) => (
						<li
							onClick={() => {
								setForm(tab);
							}}
							className={`py-1 px-3 cursor-pointer mb-0.5 rounded prose prose-sm text-gray ${
								tab.id === currentForm.id
									? "font-medium bg-gray text-white border "
									: "hover:bg-gray-glow "
							}`}
							key={tab.id}>
							{tab.label}
						</li>
					))}
				</ul>
			</FormStepper>
		</div>
	);
};

const FundMetaData: React.FC<{label: string; value?: string}> = ({label, value}) => {
	return (
		<div className="flex flex-col text-gray-common">
			<U.UIText preset="BODY_02" color="INHERIT">
				{label}
			</U.UIText>
			<U.UIText>{value || "-"}</U.UIText>
		</div>
	);
};

export default FormFundProductDetail;
