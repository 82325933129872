import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormbankDataSchema} from "../FormRegister.types";
import {useForm, useFieldArray} from "react-hook-form";
import {SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

const FormBankResolver: SchemaOf<FormbankDataSchema> = object().shape({
	externalAccount: mixed().notRequired(),
});

const FormbankAccounts: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {control, register, handleSubmit} = useForm<FormbankDataSchema>({
		resolver: yupResolver(FormBankResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formBankAccout,
		},
	});
	const {
		fields: fieldsBankAccount,
		append: appendBankAccount,
		remove: removeBankAccount,
	} = useFieldArray({
		control,
		name: "externalAccount",
	});
	// Handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_BANK_ACCOUNT",
			payload: {
				formBankAccout: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<div className="flex gap-4 justify-between">
				<U.UIText preset="HEADLINE_05">Contas Bancárias</U.UIText>
				<U.UIButton
					label="Adicionar nova conta"
					preset="LINK"
					onClick={() => appendBankAccount({id: uniqid()})}
					righticon="addCircle"
				/>
			</div>
			<div className="flex-1 flex flex-col gap-4">
				{fieldsBankAccount.map((_field, index) => (
					<div className="flex flex-col p-4 bg-gray-glow rounded border border-gray-light gap-4">
						<div className="font-bold">
							<U.UIText>Conta bancaria {index + 1}</U.UIText>
						</div>
						<div className="grid grid-cols-3 gap-4">
							<div className="input">
								<C.CPNTextInput
									label="Nome da Conta"
									{...register(`externalAccount.${index}.name`)}
								/>
							</div>
							<div className="input">
								<C.CPNTextInput label="Conta Nº" {...register(`externalAccount.${index}.number`)} />
							</div>
							<div className="input">
								<C.CPNTextInput
									label="Agência Nº"
									{...register(`externalAccount.${index}.branch`)}
								/>
							</div>
							<div className="input">
								<C.CPNTextInput label="Dígito" {...register(`externalAccount.${index}.digit`)} />
							</div>
							<div className="input">
								<C.CPNSelectInput
									label="Tipo de Conta"
									options={[
										{
											id: uniqid(),
											label: "Poupança",
											value: "SAVINGS",
										},
										{
											id: uniqid(),
											label: "Conta Corrente",
											value: "CHECKING_ACCOUNT",
										},
									]}
									type="SIMPLE"
									disabled={loading}
									{...register(`externalAccount.${index}.type`)}
								/>
							</div>
							<div className="input">
								<C.CPNTextInput
									label="Nome do Banco"
									{...register(`externalAccount.${index}.bank.name`)}
								/>
							</div>
							<div className="input">
								<C.CPNTextInput
									label="Código do Banco"
									{...register(`externalAccount.${index}.bank.code`)}
								/>
							</div>
						</div>
						<div className="flex justify-end pt-4 border-t border-gray-light cursor-pointer">
							<div className="flex gap-1 items-center font-bold">
								<U.UIText onClick={() => removeBankAccount(index)}>Remover conta</U.UIText>
								<U.UIICon name="trash" color="DARK" />
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormbankAccounts;
