import * as React from "react";
import * as U from "../../../ui";
import * as C from "../../../component";
import {RegisterContext} from "../../../context/RegisterContext";
import {FormDeclarationSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {SchemaOf, object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

export const FormDeclarationResolver: SchemaOf<FormDeclarationSchema> = object().shape({
	usPerson: string().notRequired(),
	isProfessionalInvestidor: string().notRequired(),
	isQualifiedInvestidor: string().notRequired(),
	isLinkedVox: string().notRequired(),
	linkedVox: string().notRequired(),
	isPep: string().notRequired(),
	pep: string().notRequired(),
	ofThirtParty: string().notRequired(),
});

const FormDeclarations: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	console.log(state);

	const {
		register,
		handleSubmit,
		formState: {errors},
	} = useForm<FormDeclarationSchema>({
		resolver: yupResolver(FormDeclarationResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formDeclaration,
		},
	});
	// Handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_FUND_DECLARATION",
			payload: {
				formDeclaration: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Declarações legais</U.UIText>
			<div className="flex-1">
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNSelectInput
							label="É Investidor Profissional?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							error={errors.isProfessionalInvestidor?.message}
							disabled={loading}
							{...register("isProfessionalInvestidor")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="É Investidor Qualificado?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							error={errors.isQualifiedInvestidor?.message}
							disabled={loading}
							{...register("isQualifiedInvestidor")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Declara-se Pessoa Politicamente Exposta (PEP)?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							error={errors.isPep?.message}
							disabled={loading}
							{...register("isPep")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Qual o vínculo político?"
							error={errors.pep?.message}
							{...register("pep")}
						/>
					</div>
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormDeclarations;
