import * as React from "react";
import * as U from "../../../ui";
import * as C from "../../../component";
import * as R from "ramda";
import {RegisterContext} from "./../../../context/RegisterContext";
import {getOdsList, linkOdsAsset} from "../../../service/api/impact-api";
import {ODS} from "../../../types/impact";
import {ApiError} from "../../../types/apiError";
interface DetailOdsProps {
	assetId: string;
	odsListInput?: any[];
	error: boolean;
}

const DetailOds: React.FC<DetailOdsProps> = ({assetId, odsListInput = [], error}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// States
	const [modal, setmodal] = React.useState<boolean>(false);
	const [loading, setloading] = React.useState<boolean>(false);
	const [addingResponsable, setaddingOds] = React.useState<boolean>(false);
	const [list, setlist] = React.useState<any[]>([]);
	const [odsList, setodsList] = React.useState<any[]>([]);
	const [itemSelected, setItemSelected] = React.useState<any>({});
	// Handlers
	const clear = () => {
		setmodal(false);
		setlist([]);
		setItemSelected({});
	};

	const getAllOds = async () => {
		try {
			setloading(true);
			const result = await getOdsList();
			if ((result as ApiError).message) {
				alert((result as ApiError).message);
			}
			const ods = result as ODS[];
			setlist([...ods]);
			setmodal(true);
		} catch (error) {
			alert("Aconteceu um erro ao trazer algumas ODS");
		}
		setloading(false);
	};

	const addOdsToAsset = async () => {
		try {
			setaddingOds(true);
			const result: any = await linkOdsAsset(itemSelected.id, assetId);
			if (result.status === "success") {
				dispatch({
					type: "SET_USER_ACCESS_PERSON",
					payload: {
						accessPerson: [itemSelected],
					},
				});
				clear();
				setodsList([itemSelected, ...odsList]);
			} else {
				alert("Aconteceu um error associando aODS com o cotista, tente novamente");
			}
		} catch (error) {
			alert("Aconteceu um error tentando associar a ODS");
		}
		setaddingOds(false);
	};
	// Component
	return (
		<div className="fle flex-col mt-6">
			{loading && <U.UILoading />}
			<C.CPNModal
				showModal={modal}
				title="ODS disponíveis para associar"
				onModalClose={clear}
				secondaryAction={{
					label: "Fechar",
					onClick: clear,
				}}
				primaryAction={{
					label: itemSelected.id ? `Associar ODS` : "Selecione uma ODS",
					disabled: !list.length,
					loading: addingResponsable,
					onClick: () => (list.length > 0 ? addOdsToAsset() : {}),
				}}>
				{list.length > 0 ? (
					<div className="w-full">
						{/* TODO: Add filter */}
						{/* <div className="w-full mb-4">
							<input
								type="text"
								className="w-full py-3 px-3 focus:outline-none focus:border-gray-common focus:ring-gray-common border border-gray-light rounded"
								placeholder="Buscar fundo"
							/>
						</div> */}
						<div className="w-full flex flex-col border rounded border-gray-light">
							{(odsList || odsListInput) &&
								R.sortBy(R.prop("name"), list).map((item: any) => {
									const exists = [...odsList, ...odsListInput].find((r: any) => r.id === item.id);
									return (
										<div
											key={item.id}
											className={`py-3 px-4 flex hover:bg-gray-glow border-b border-gray-light justify-between ${
												exists ? "cursor-not-allowed bg-gray-glow" : "cursor-pointer"
											}`}
											onClick={() => (!exists ? setItemSelected(item) : {})}>
											<div className="flex flex-col justify-between flex-1">
												<U.UIText preset="BODY_02">{item.name}</U.UIText>
												<U.UIText preset="BODY_03" color="GREY">
													{item.description}
												</U.UIText>
											</div>
											{itemSelected.id === item.id && !exists ? (
												<U.UIICon name="checkFilled" size="xs" color="PRIMARY" />
											) : exists ? (
												<U.UIText preset="BODY_03" color="PRIMARY">
													Esta ODS já está associado
												</U.UIText>
											) : (
												<></>
											)}
										</div>
									);
								})}
						</div>
					</div>
				) : (
					<C.CPNAlert
						title="Oops!"
						description="Aparentemente não há ODS disponiveis no momento."
						type="INFO"
					/>
				)}
			</C.CPNModal>
			<div className="flex pb-2 justify-between items-center">
				<U.UIText preset="SUBTITLE">ODS</U.UIText>
				{(odsList || odsListInput) && (odsList.length > 0 || odsListInput.length > 0) && (
					<div className="flex gap-1 items-center cursor-pointer" onClick={getAllOds}>
						<span className="prose prose-sm font-bold underline text-gray-common hover:text-gray">
							Adicionar nova ODS
						</span>
						<U.UIICon color="GREY" name="addCircle" />
					</div>
				)}
			</div>
			{error ? (
				<C.CPNAlert
					title="Oops!"
					description="Aconteceu um error trazendo as ODS do ativo, tente novamente"
					type="ERROR"
				/>
			) : (!odsList && !odsListInput) || (!odsList?.length && !odsListInput?.length) ? (
				<C.CPNAlert
					title="Sem ODS"
					description="Este ativo não possui ODS."
					type="INFO"
					onFirstAction={getAllOds}
					firstActionLabel="Associar ODS"
					loading={loading}
				/>
			) : (
				<C.CPNTable
					columns={[
						{
							Header: "ID",
							accessor: "id",
						},
						{
							Header: "Nome",
							accessor: "name",
						},
						{
							Header: "Descrição",
							accessor: "description",
						},
					]}
					data={[...odsList, ...odsListInput]}
				/>
			)}
		</div>
	);
};

export default DetailOds;
