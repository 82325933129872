// tslint:disable:indent
// Component generated with util/create-component.js
import styled from "styled-components";
import {preset} from "./Button.types";
import classNames from "classnames";

type ButtonProps = {
	bp: keyof typeof preset;
	outline?: boolean;
};
/**
 * Components
 */
export const ButtonWrapper = styled.button.attrs<ButtonProps>(({bp, disabled, outline}) => {
	const className = outline
		? classNames({
				"flex flex-row items-center justify-center": true,
				"border py-1 px-4 rounded leading-tight bg-transparent": true,
				"border-primary text-primary hover:bg-primary-lightest": bp === "PRIMARY",
				"border-secondary text-secondary hover:bg-secondary-lightest": bp === "SECONDARY",
				"border-red text-red  hover:bg-red-lightest": bp === "DANGER",
				"border-gray text-gray hover:bg-gray-glow": bp === "DARK",
		  })
		: classNames({
				"cursor-not-allowed": disabled,
				"flex flex-row items-center justify-center": true,
				"py-1 px-4 rounded leading-tight": bp !== "LINK",
				"text-white": bp !== "SECONDARY" && bp !== "LINK",
				"bg-gray-disabled hover:bg-gray-disabled": disabled && bp !== "LINK",
				"bg-primary hover:bg-primary-dark": bp === "DEFAULT",
				"bg-white border border-current text-primary hover:text-primary-light":
					bp === "SECONDARY" && !disabled,
				"border-current text-gray-disabled hover:text-gray-disabled hover:bg-white":
					bp === "SECONDARY" && disabled,
				"min-w-0 text-primary underline hover:text-primary-light": bp === "LINK" && !disabled,
				"bg-transparent text-gray-disabled hover:text-gray-disabled": bp === "LINK" && disabled,
				"bg-red hover:bg-red-light": bp === "DANGER",
				"bg-gray hover:bg-gray-dark": bp === "DARK",
		  });
	return {
		className,
	};
})<ButtonProps>`
	& svg * {
		fill: currentColor;
	}
`;
