import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormAdministratorSchema} from "../FormRegister.types";
import {useForm, useFieldArray} from "react-hook-form";
import {SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

const FormAdministratorResolver: SchemaOf<FormAdministratorSchema> = object().shape({
	administrator: mixed().notRequired(),
});

const FormAdministrator: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {control, register, handleSubmit} = useForm<FormAdministratorSchema>({
		resolver: yupResolver(FormAdministratorResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formAdministrator,
		},
	});
	const {
		fields: fieldsAdministrator,
		append: appendAdministrator,
		remove: removeAdministrator,
	} = useFieldArray({
		control,
		name: "administrator",
	});
	// Handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_ADMINISTRATOR",
			payload: {
				formAdministrator: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<div className="flex gap-4 justify-between">
				<U.UIText preset="HEADLINE_05">Administradores</U.UIText>
				<U.UIButton
					label="Adicionar Administradores"
					preset="LINK"
					onClick={() => appendAdministrator({id: uniqid()})}
					righticon="addCircle"
				/>
			</div>
			<div className="flex-1 flex flex-col gap-4">
				{fieldsAdministrator.map((_field, index) => (
					<div className="flex flex-col p-4 bg-gray-glow rounded border border-gray-light gap-4">
						<div className="input">
							<C.CPNTextInput label="Nome" {...register(`administrator.${index}.name`)} />
						</div>
						<div className="input">
							<C.CPNTextInput label="Cargo" {...register(`administrator.${index}.position`)} />
						</div>
						<div className="input">
							<C.CPNTextInput label="CPF" {...register(`administrator.${index}.cpf`)} />
						</div>
						<div className="input">
							<C.CPNTextInput
								label="País de domicílio Fiscal"
								{...register(`administrator.${index}.taxDomicile`)}
							/>
						</div>
						<U.UIButton
							disabled={loading}
							icon="trash"
							preset="LINK"
							onClick={() => removeAdministrator(index)}
						/>
					</div>
				))}
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormAdministrator;
