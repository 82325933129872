import * as React from "react";
import * as U from "../../../ui";
import * as C from "../../../component";
import * as R from "ramda";
import {getRegister, putRegister} from "./../../../service/api/registry-api";
import {RegisterContext} from "./../../../context/RegisterContext";
interface DetailAgentProps {
	registerid?: string;
	fullName?: string;
	agents?: any;
	disabledAdd?: boolean;
	error: boolean;
	category: string;
}

const DetailAgent: React.FC<DetailAgentProps> = ({
	registerid,
	disabledAdd = false,
	fullName,
	agents = [],
	error,
	category,
}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// States
	const [modal, setmodal] = React.useState<boolean>(false);
	const [loading, setloading] = React.useState<boolean>(false);
	const [addingResponsable, setaddingResponsable] = React.useState<boolean>(false);
	const [list, setlist] = React.useState<any[]>([]);
	const [itemSelected, setItemSelected] = React.useState<any>({});
	// Handlers
	const clear = () => {
		setmodal(false);
		setlist([]);
		setItemSelected({});
	};
	const getAllResponsables = async () => {
		try {
			setloading(true);
			const {data} = await getRegister(category);
			if (data && data.message.length > 0) {
				setlist([...data.message]);
				setmodal(true);
			} else if (data.status === "success") {
				alert("Não há nehum responsável disponível no momento");
			} else {
				alert("Aconteceu um erro ao trazer os responsáveis disponiveis");
			}
		} catch (error) {
			alert("Aconteceu um erro ao trazer alguns responsáveis");
		}
		setloading(false);
	};
	const addResponsablesToUser = async () => {
		try {
			setaddingResponsable(true);
			const request: any = {
				individual: {
					personId: registerid,
					fullName,
					accessPerson: [
						...agents,
						{
							fullName: itemSelected.fullName,
							email: itemSelected.email,
							personId: itemSelected.personId,
						},
					],
				},
				id: registerid,
			};
			const result: any = await putRegister(request);
			if (result.status === 200) {
				dispatch({
					type: "SET_USER_ACCESS_PERSON",
					payload: {
						accessPerson: [itemSelected],
					},
				});
				clear();
			} else {
				alert("Aconteceu um error associando o responsável com o cotista, tente novamente");
			}
		} catch (error) {
			alert("Aconteceu um error tentando associar o responsável");
		}
		setaddingResponsable(false);
	};
	const deleteResponsable = () => {};
	// Component
	return (
		<div className="fle flex-col mt-6">
			{loading && <U.UILoading />}
			<C.CPNModal
				showModal={modal}
				title="Responsáveis disponíveis para associar"
				onModalClose={clear}
				secondaryAction={{
					label: "Fechar",
					onClick: clear,
				}}
				primaryAction={{
					label: itemSelected.personId ? `Associar responsável` : "Selecione um responsável",
					disabled: !list.length || addingResponsable || !itemSelected.personId,
					loading: addingResponsable,
					onClick: () => (list.length > 0 ? addResponsablesToUser() : {}),
				}}>
				{list.length > 0 ? (
					<div className="w-full">
						{/* TODO: Add filter */}
						{/* <div className="w-full mb-4">
							<input
								type="text"
								className="w-full py-3 px-3 focus:outline-none focus:border-gray-common focus:ring-gray-common border border-gray-light rounded"
								placeholder="Buscar fundo"
							/>
						</div> */}
						<div className="w-full flex flex-col border rounded border-gray-light">
							{R.sortBy(R.prop("fullName"), list).map((item: any) => {
								const exists = [...agents, ...(state?.accessPerson || [])].find(
									(r: any) => r.personId === item.personId,
								);
								return (
									<div
										key={item.personId}
										className={`py-3 px-4 flex hover:bg-gray-glow border-b border-gray-light justify-between ${
											exists ? "cursor-not-allowed bg-gray-glow" : "cursor-pointer"
										}`}
										onClick={() => (!exists ? setItemSelected(item) : {})}>
										<div className="flex flex-col justify-between flex-1">
											<U.UIText preset="BODY_02">{item.fullName}</U.UIText>
											<U.UIText preset="BODY_03" color="GREY">
												{item.email}
											</U.UIText>
										</div>
										{itemSelected.personId === item.personId && !exists ? (
											<U.UIICon name="checkFilled" size="xs" color="PRIMARY" />
										) : exists ? (
											<U.UIText preset="BODY_03" color="PRIMARY">
												Este responsável já está associado
											</U.UIText>
										) : (
											<></>
										)}
									</div>
								);
							})}
						</div>
					</div>
				) : (
					<C.CPNAlert
						title="Oops!"
						description="Aparentemente não há fundos dispon;iveis no momento, adicione novos fundos."
						type="INFO"
					/>
				)}
			</C.CPNModal>
			<div className="flex pb-2 justify-between items-center">
				<U.UIText preset="SUBTITLE">
					{category === "AGENT" ? "Cotistas" : "Responsáveis PJ"}
				</U.UIText>
				{!disabledAdd && (agents.length > 0 || (state?.accessPerson || []).length > 0) && (
					<div className="flex gap-1 items-center cursor-pointer" onClick={getAllResponsables}>
						<span className="prose prose-sm font-bold underline text-gray-common hover:text-gray">
							Adicionar novo representante
						</span>
						<U.UIICon color="GREY" name="addCircle" />
					</div>
				)}
			</div>
			{error ? (
				<C.CPNAlert
					title="Oops!"
					description="Aconteceu um error trazendo os fundos do usuário, tente novamente"
					type="ERROR"
				/>
			) : disabledAdd && !agents.length && !(state?.accessPerson || []).length ? (
				<C.CPNAlert
					title="Sem responsáveis"
					description="Este responsável não possui costistas."
					type="INFO"
					loading={loading}
				/>
			) : !disabledAdd && !agents.length && !(state?.accessPerson || []).length ? (
				<C.CPNAlert
					title="Sem responsáveis"
					description="Este responsável não possui costistas."
					type="INFO"
					onFirstAction={getAllResponsables}
					firstActionLabel="Associar responsável"
					loading={loading}
				/>
			) : (
				<C.CPNTable
					columns={[
						{
							Header: "ID",
							accessor: "personId",
						},
						{
							Header: "E-mail",
							accessor: "email",
						},
						{
							Header: "Nome completo",
							accessor: "fullName",
						},
					]}
					data={[...agents, ...(() => (state?.accessPerson ? state.accessPerson : []))()]}
				/>
			)}
		</div>
	);
};

export default DetailAgent;
