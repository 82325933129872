import React from "react";
import {TableSearch} from "./Table.styled";
import {Input} from "./../TextInput/TextInput.styled";
import {useAsyncDebounce} from "react-table";
import {UIICon, UIText} from "../../ui";

export const TableFilter: React.FC<any> = ({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
}) => {
	const count = preGlobalFilteredRows.length;
	const [filterValue, setFilterValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<TableSearch>
			<div className="mb-0">
				<UIText preset="BODY_02" color="GREY">
					Filtrar
				</UIText>
			</div>
			<div className="search">
				<Input
					value={filterValue || ""}
					onChange={(e: any) => {
						setFilterValue(e.target.value);
						onChange(e.target.value);
					}}
					placeholder={`${count} resultados...`}
				/>
				<div className="icon">
					<UIICon color="PRIMARY" name="search" size="sm" />
				</div>
			</div>
		</TableSearch>
	);
};
