import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormFinancialDataSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {SchemaOf, object, mixed, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

// interface FormFinancialDataProps {}

const resourceOriginOptions = [
	{
		id: uniqid(),
		label: "Herança",
		value: "Herança",
	},
	{
		id: uniqid(),
		label: "Doação",
		value: "Doação",
	},
	{
		id: uniqid(),
		label: "Renda de Ocupação Profissional",
		value: "Renda de Ocupação Profissional",
	},
	{
		id: uniqid(),
		label: "Partilha de Bens",
		value: "Partilha de Bens",
	},
	{
		id: uniqid(),
		label: "Aposentadoria",
		value: "Aposentadoria",
	},
	{
		id: uniqid(),
		label: "Aluguel de Propriedades",
		value: "Aluguel de Propriedades",
	},
	{
		id: uniqid(),
		label: "Outro",
		value: "Outro",
	},
];

const FormFinancialResolver: SchemaOf<FormFinancialDataSchema> = object().shape({
	income: mixed().notRequired(),
	socialCapital: string().notRequired(),
	dateFinancial: string().notRequired(),
});

const FormFinancialData: React.FC<any> = ({sendFormData, loading, visible, category}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {register, handleSubmit, watch} = useForm<FormFinancialDataSchema>({
		resolver: yupResolver(FormFinancialResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formFinancialData,
			// income: {
			// 	resourceOrigin: "",
			// },
		},
	});
	// Watchs
	const resourceOrigin = watch("income.resourceOrigin", "") || "";
	// Handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_FINANCIAL_DATA",
			payload: {
				formFinancialData: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});

	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Informações Financeiras</U.UIText>
			<div className="flex-1 flex flex-col gap-2">
				<div className="grid grid-cols-3 gap-4">
					{(category === "INDIVIDUAL" || category === "LEGAL") && (
						<div className="input">
							<C.CPNTextInput
								type="number"
								label="Faturamento Total Médio Mensal em 
								reais (média dos últimos 12 meses)"
								{...register("income.monthlyBilling")}
							/>
						</div>
					)}
					<div className="input">
						<C.CPNTextInput
							type="number"
							label="Patrimônio Líquido  
								Total em reais"
							{...register("income.netPatrimony")}
						/>
					</div>
					{category === "INDIVIDUAL" && (
						<>
							<div className="input">
								<C.CPNTextInput
									type="number"
									label="Valor aproximado de aplicações financeiras  
									Total em reais"
									{...register("income.application")}
								/>
							</div>
							<div className="input">
								<C.CPNTextInput
									type="number"
									label="Valor em móveis e imoveis  
									Total em reais"
									{...register("income.property")}
								/>
							</div>
							<div className="input">
								<C.CPNSelectInput
									label="Origem dos Recursos"
									options={resourceOriginOptions}
									type="MULTICHECK"
									disabled={loading}
									{...register("income.resourceOrigin")}
								/>
							</div>
							{(resourceOrigin || "").split(",").includes("Outro") && (
								<div className="input">
									<C.CPNTextInput
										label="Origem dos Recursos"
										{...register("income.differentResourceOrigin")}
									/>
								</div>
							)}
						</>
					)}
					{category === "LEGAL" && (
						<div className="input">
							<C.CPNTextInput
								label="Capital Social
								em reais"
								{...register("socialCapital")}
							/>
						</div>
					)}
					{(category === "FUND" || category === "LEGAL") && (
						<div className="input">
							<C.CPNDatePicker label="Data" disabled={loading} {...register("dateFinancial")} />
						</div>
					)}
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormFinancialData;
