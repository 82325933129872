import * as React from "react";
import * as U from "../../../ui";
import {FormStepper} from "../FormRegister.styled";
import {useHistory} from "react-router-dom";
import {RegisterContext} from "../../../context/RegisterContext";
import * as Forms from "../Forms/Forms";
import {postRegister, putRegister} from "../../../service/api/registry-api";

type TTab = {
	id: string;
	label: string;
};

const TABS: TTab[] = [
	{
		label: "Dados Pessoais",
		id: "FormPersonalData",
	},
	{
		label: "Documentos de Identificação",
		id: "FormPersonalDocuments",
	},
	{
		label: "Dados Profissionais",
		id: "FormProfessionalData",
	},
	{
		label: "Informações Financeiras",
		id: "FormFinancialData",
	},
	{
		label: "Contas Bancárias",
		id: "FormBankAccount",
	},
	{
		label: "Declarações",
		id: "FormDeclarations",
	},
	{
		label: "Representantes Legais",
		id: "FormRepresentative",
	},
	{
		label: "Investidores não Residentes",
		id: "FormInvestorResident",
	},
	{
		label: "Outros Documentos",
		id: "FormOtherDocuments",
	},
];

const category = "INDIVIDUAL";

function FormRegister() {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// const {registerForm, saveformPersonId, formPersonId} = React.useContext(RegisterContext);
	// States
	const [loading, setloading] = React.useState<boolean>(false);
	const [currentForm, setcurrentForm] = React.useState<TTab>(TABS[0]);
	// Router
	const history = useHistory();

	// Handlers
	const removeEmptyParameters = (entry: any): any => {
		if (!entry) {
			return;
		}
		for (const subObject of Object.entries(entry)) {
			if (typeof subObject[1] === "object") {
				const result = removeEmptyParameters(subObject[1]);
				if (!result || Object.keys(result).length === 0) {
					entry[subObject[0]] = null;
				} else {
					entry[subObject[0]] = result;
				}
			}
		}
		const filter = Object.fromEntries(
			Object.entries(entry).filter(([_, v]) => v != null && v !== "" && v !== undefined),
		);
		if (entry.length) {
			return Object.values(filter);
		}
		return filter;
	};

	const sendFormData = async (data: any) => {
		try {
			setloading(true);
			const cloneData = {...data};
			let newResourceOrigin = "";
			if (cloneData.income) {
				const resourceOrigin = cloneData.income.resourceOrigin || [];
				delete cloneData.statusLabel;
				newResourceOrigin =
					typeof resourceOrigin === "string" ? resourceOrigin.split(",") : resourceOrigin;
			}
			const request = {
				individual: {
					...cloneData,
					income: {
						...cloneData.income,
						resourceOrigin: newResourceOrigin,
					},
					category,
				},
				id: cloneData.personId,
			};
			request.individual = removeEmptyParameters(request.individual);
			if (cloneData.personId) {
				await putRegister(request);
			} else {
				const personId = await postRegister(request);
				dispatch({type: "SET_PERSON_ID", payload: {formPersonId: personId}});
			}
			const nextForm = TABS[TABS.findIndex((t) => t.id === currentForm.id) + 1];
			setcurrentForm(nextForm || currentForm);
		} catch (error) {
			alert("Aconteceu um error, tente novamente");
		}
		setloading(false);
	};
	// Effects
	React.useEffect(() => {
		return () => {
			dispatch({type: "CLEAR_REGISTER_DATA"});
		};
	}, [dispatch]);
	// Component
	return (
		<div className="flex gap-8 h-full">
			{loading && <U.UILoading />}
			<div className="content flex-1 flex flex-col gap-4 h-full overflow-y-auto">
				<div className="flex justify-between items-center">
					<div className="title flex flex-col pb-2 justify-start">
						<span>
							<U.UIButton preset="LINK" icon="arrowBack" label="Voltar" onClick={history.goBack} />
						</span>
						<U.UIText preset="HEADLINE_03">
							{state?.mode === "EDIT" ? "Editar cotista" : "Adicionar novo cotista"}
						</U.UIText>
						{state?.mode === "EDIT" && state.formPersonId ? (
							<U.UIText preset="BODY_02" color="GREY">
								ID: {state.formPersonId}
							</U.UIText>
						) : (
							<></>
						)}
					</div>
				</div>
				<div className="flex-1 h-full">
					<Forms.FormBankAccount
						{...{sendFormData, loading, visible: currentForm.id === "FormBankAccount", category}}
					/>
					<Forms.FormDeclarations
						{...{sendFormData, loading, visible: currentForm.id === "FormDeclarations", category}}
					/>
					<Forms.FormRepresentative
						{...{sendFormData, loading, visible: currentForm.id === "FormRepresentative", category}}
					/>
					<Forms.FormFinancialData
						{...{sendFormData, loading, visible: currentForm.id === "FormFinancialData", category}}
					/>
					<Forms.FormInvestorResident
						{...{
							sendFormData,
							loading,
							visible: currentForm.id === "FormInvestorResident",
							category,
						}}
					/>
					<Forms.FormOtherDocuments
						{...{sendFormData, loading, visible: currentForm.id === "FormOtherDocuments", category}}
					/>
					<Forms.FormPersonalData
						{...{sendFormData, loading, visible: currentForm.id === "FormPersonalData", category}}
					/>
					<Forms.FormPersonalDocuments
						{...{
							sendFormData,
							loading,
							visible: currentForm.id === "FormPersonalDocuments",
							category,
						}}
					/>
					<Forms.FormProfessionalData
						{...{
							sendFormData,
							loading,
							visible: currentForm.id === "FormProfessionalData",
							category,
						}}
					/>
				</div>
			</div>
			<FormStepper>
				<div className="px-3 mb-1">
					<U.UIText preset="BODY_03" color="GREY">
						Passos
					</U.UIText>
				</div>
				<ul>
					{TABS.map((tab, _) => (
						<li
							onClick={() => {
								state?.formPersonId
									? setcurrentForm(tab)
									: alert(
											"Prencha as informações básicas do cotista para continuar: Nome social, nome completo e E-mail",
									  );
							}}
							className={`py-1 px-3 cursor-pointer mb-0.5 rounded prose prose-sm text-gray ${
								tab.id === currentForm.id
									? "font-medium bg-gray text-white border "
									: "hover:bg-gray-glow "
							}`}
							key={tab.id}>
							{tab.label}
						</li>
					))}
				</ul>
			</FormStepper>
		</div>
	);
}

export default FormRegister;
