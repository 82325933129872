import {ApiError} from "../../types/apiError";
import {ResponseDocument} from "../../types/document";
import {Kaleidoscope, ODS, ResponseImpact, Theme} from "../../types/impact";
import {HTTP, axios} from "../http";

const BASE_IMPACT_URL = `/impacts`;
const BASE_DOC_URL = `/documents`;

export const persistKaleidoscope = async (props: Kaleidoscope): Promise<ResponseImpact> => {
	try {
		const impact = props;
		if (impact.imageFile) {
			const imageList: {id: string; filePath: string}[] = await Promise.all(
				impact.imageFile.map((file: File) => uploadFile(file, true)),
			);
			const imageIdList = imageList.map((image) => image.id);
			const imagePathList = imageList.map((image) => image.filePath);
			impact.imageId = impact.imageId ? impact.imageId.concat(imageIdList) : imageIdList;
			impact.imagePath = impact.imagePath ? impact.imagePath.concat(imagePathList) : imagePathList;
		}
		let result;
		if (!impact.id) {
			result = await HTTP.post<Kaleidoscope, any>(`${BASE_IMPACT_URL}/kaleidoscopes`, impact);
		} else {
			result = await HTTP.put<Kaleidoscope, any>(
				`${BASE_IMPACT_URL}/kaleidoscopes/${impact.id}`,
				impact,
			);
		}
		return result.data as ResponseImpact;
	} catch (err: any) {
		return {
			status: "error",
			message: err.message,
		};
	}
};

export const removeKaleidoscope = async (impact: Kaleidoscope): Promise<ResponseImpact> => {
	const result = await HTTP.delete<any>(`${BASE_IMPACT_URL}/kaleidoscopes/${impact.id}`);
	return result.data as ResponseImpact;
};

export const getKaleidoscopeList = async (): Promise<ResponseImpact> => {
	const result = await HTTP.get<any>(`${BASE_IMPACT_URL}/kaleidoscopes`);
	return result.data as ResponseImpact;
};

export const getKaleidoscopeById = async (impact: any): Promise<ResponseImpact> => {
	const result = await HTTP.get<any>(`${BASE_IMPACT_URL}/kaleidoscopes/${impact.id}`);
	console.log(result);
	return result.data as ResponseImpact;
};

export const linkOdsAsset = async (odsId: string, assetId: string): Promise<ODS[] | undefined> => {
	const result = await HTTP.patch<any>(`${BASE_IMPACT_URL}/ods/${odsId}`, {assetId});
	return result.data;
};

export const getOdsByAssetList = async (assetId: string): Promise<ODS[] | ApiError> => {
	const result = await HTTP.get<any>(`${BASE_IMPACT_URL}/assets/${assetId}/ods`);
	let message = "";
	if (result && result.data) {
		if (result.data.message) {
			return result.data.message as ODS[];
		} else if (result.data.status === "success") {
			message = "Não há nehuma ODS disponível no momento";
		}
	} else {
		message = "Aconteceu um erro ao trazer as ODS disponiveis";
	}
	return {
		status: result.data.status,
		message,
	};
};

export const getOdsList = async (): Promise<ODS[] | ApiError> => {
	const result = await HTTP.get<any>(`${BASE_IMPACT_URL}/ods`);
	let message = "";
	if (result && result.data) {
		if (result.data.message) {
			return result.data.message as ODS[];
		} else if (result.data.status === "success") {
			message = "Não há nehuma ODS disponível no momento";
		}
	} else {
		message = "Aconteceu um erro ao trazer as ODS disponiveis";
	}
	return {
		status: result.data.status,
		message,
	};
};

export const getThemeList = async (): Promise<Theme[] | ApiError> => {
	const result = await HTTP.get<any>(`${BASE_IMPACT_URL}/themes`);
	let message = "";
	if (result && result.data) {
		if (result.data.message) {
			return result.data.message as Theme[];
		} else if (result.data.status === "success") {
			message = "Não há nehuma temática disponível no momento";
		}
	} else {
		message = "Aconteceu um erro ao trazer as temáticas disponiveis";
	}
	return {
		status: result.data.status,
		message,
	};
};

export const getThemeByAssetList = async (assetId: string): Promise<Theme[] | ApiError> => {
	const result = await HTTP.get<any>(`${BASE_IMPACT_URL}/assets/${assetId}/themes`);
	let message = "";
	if (result && result.data) {
		if (result.data.message) {
			return result.data.message as Theme[];
		} else if (result.data.status === "success") {
			message = "Não há nehuma temática disponível no momento";
		}
	} else {
		message = "Aconteceu um erro ao trazer as temáticas disponiveis";
	}
	return {
		status: result.data.status,
		message,
	};
};

export const linkThemeAsset = async (themeId: string, assetId: string): Promise<ResponseImpact> => {
	const result = await HTTP.patch<any>(`${BASE_IMPACT_URL}/themes/${themeId}`, {assetId});
	return result.data as ResponseImpact;
};

const uploadFile = async (
	file: File,
	isPublic = false,
): Promise<{id: string; filePath: string}> => {
	try {
		let responseDocument: ResponseDocument | any = null;
		const fileNameSplit = file.name.split(".");
		const fileExtension = fileNameSplit[fileNameSplit.length - 1];
		const name = file.name.replace(`.${fileExtension}`, "");
		const documentType = "impact/images";
		const contentType = file.type;
		const bodyUpload = {
			name,
			fileExtension,
			documentType,
			contentType,
			isPublic,
		};
		const result = await HTTP.post<any>(`${BASE_DOC_URL}`, bodyUpload);
		responseDocument = result.data as ResponseDocument;
		if (!responseDocument.fileUrl) {
			console.log("Error on generate file url", responseDocument);
			throw new Error("Something went wrong on file upload.");
		}
		// notice: axios directly because aws s3 url interceptor Authorization header error
		const resultUpload = await axios({
			method: "put",
			url: responseDocument.fileUrl,
			data: file,
			headers: {"Content-Type": file.type},
		});
		if (resultUpload.status !== 200) {
			console.error("Error on file upload", resultUpload.data);
			throw new Error("Something went wrong on file upload.");
		}
		const resultStatus = await HTTP.patch<any>(`${BASE_DOC_URL}/${responseDocument.id}`, {
			status: "ACTIVE",
		});
		if (resultStatus.status !== 200) {
			console.error("Error on updating file upload status", resultStatus.data.message);
			throw new Error("Somethin went wrong on file upload.");
		}
		return {id: responseDocument.id, filePath: responseDocument.filePath};
	} catch (error) {
		throw error;
	}
};

export const getImageDownloadLink = async (imageId: string): Promise<any> => {
	const result = await HTTP.get<any>(`${BASE_DOC_URL}/url/adm/${imageId}`);
	if (result.status !== 200) {
		console.error("Error status code", result.status);
		throw new Error("Something went wrong");
	}
	return result.data;
};

export const downloadFile = async (fileUrl: string): Promise<any> => {
	// notice: axios directly because aws s3 url interceptor Authorization header error
	const downloadResult = await axios.get(fileUrl, {responseType: "blob"});
	return downloadResult;
};
