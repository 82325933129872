import React from "react";
import Router from "./../router";
import {AuthProvider} from "./../context/AuthContext";
import {RegisterProvider} from "./../context/RegisterContext";
import {UILayout, UIContainer} from "./../ui";
import {FundProductProvider} from "../context/FundProductContext";

function App() {
	return (
		<AuthProvider>
			<UIContainer>
				<UILayout>
					<RegisterProvider>
						<FundProductProvider>
							<Router />
						</FundProductProvider>
					</RegisterProvider>
				</UILayout>
			</UIContainer>
		</AuthProvider>
	);
}

export default App;
