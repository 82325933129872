// Component generated with util/create-component.js
import styled from "styled-components";
import tw from "twin.macro";

/**
 * Components
 */
export const TextareaWrapper = styled.div`
	> .footer {
		${tw`flex justify-between`}
	}
`;

export const TextareaInput = styled.textarea.attrs({
	rows: 4,
})`
	${tw`border border-gray-light border-opacity-60 rounded-lg w-full px-3 py-2`}
`;
