// Component generated with util/create-component.js
import styled from "styled-components";
import tw from "twin.macro";

/**
 * Components
 */
export const FormDocumentsWrapper = styled.div`
	${tw`flex flex-col`}
	& > .description {
		${tw`flex flex-col border-b border-gray-light pb-2 mb-4`}
	}
	& .forms {
		& > .form {
			${tw`flex flex-row flex-wrap mb-4`}
			& > .input {
				${tw`sm:w-full md:w-1/2 lg:w-1/3 2xl:w-1/4 mb-7 px-2`}
			}
		}
		& > .action {
			${tw`flex justify-end mb-6`}
		}
	}
`;
