// Component generated with util/vox-create-component.js
import React from "react";
import * as R from "ramda";
import {MenuWrapper, MenuItemWrapper, SubMenuItemWrapper} from "./Menu.styled";
import {MenuProps} from "./Menu.types";
import MENU from "./../../data/menu.json";
import {UIText} from "./../../ui";
import {useHistory} from "react-router-dom";
import uniqid from "uniqid";

export const Menu: React.VFC<MenuProps> = ({menu = MENU, ...args}) => {
	const history = useHistory();
	// Context Here
	// States Here
	const [selected, setselected] = React.useState<string>();
	const [subMenu, setSubMenu] = React.useState<string>(window.location.pathname);
	// Handlers Here
	const navigate = React.useCallback(
		(route: string) => {
			setSubMenu(route);
			history.push(route);
		},
		[history],
	);
	// Effects Here
	React.useEffect(() => {
		navigate("/");
	}, [navigate]);
	return (
		<MenuWrapper {...args}>
			{menu.map(({label, links = []}, i) => {
				return (
					<MenuItemWrapper key={uniqid()}>
						<div className="item  px-4">
							<UIText color="GREY" preset="BODY_02">
								{label}
							</UIText>
						</div>
						<div className="subitem">
							{!R.isEmpty(links) &&
								links.map(({label: linkLabel, route: linkRoute, name}, _) => (
									<SubMenuItemWrapper
										key={uniqid()}
										onClick={(e) => {
											e.stopPropagation();
											setselected(name || "");
											navigate(linkRoute);
										}}
										selected={name === selected}>
										<UIText preset="BODY" color="DARK">
											{linkLabel}
										</UIText>
									</SubMenuItemWrapper>
								))}
						</div>
					</MenuItemWrapper>
				);
			})}
		</MenuWrapper>
	);
};

export default Menu;
