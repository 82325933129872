// Component generated with util/vox-create-component.js
import React from "react";
import {FormLoginWrapper} from "./FormLogin.styled";
import {FormLoginProps, FormLoginSchema} from "./FormLogin.types";
import {useForm} from "react-hook-form";
import {string, SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as C from "../../component";
import * as U from "../../ui";
import {userList} from "../../service/api/login-api";
import {statusCognitoUser} from "../../enum/formEnums";
import {CognitoLogin, ResponseCognitoLogin} from "../../types/login";

const FormLoginResolver: SchemaOf<FormLoginSchema> = object().shape({
	id: string().required(),
	fileName: string(),
	date: string().required(),
	type: string().required(),
	file: mixed().required(),
	fileExtension: string(),
	createdAt: string().optional(),
});

export const FormLogin: React.VFC<FormLoginProps> = ({...args}) => {
	const [list, setList] = React.useState<any>([]);
	const [showForm, setShowForm] = React.useState<boolean>(false);
	const [currentError, setCurrentError] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [loadingData, setLoadingData] = React.useState<boolean>(true);

	const {
		formState: {errors},
	} = useForm<FormLoginSchema>({
		resolver: yupResolver(FormLoginResolver),
		mode: "onSubmit",
	});

	React.useEffect(() => {
		setLoadingData(true);
		getLoginList();
	}, []);

	const getLoginList = (): any => {
		userList()
			.then((result: any) => {
				const resultList = result.message as ResponseCognitoLogin[];
				const userCognitoList: CognitoLogin[] = [];
				resultList.forEach((user: any) => {
					const email = user.Attributes ? user.Attributes[0].Value : undefined;
					userCognitoList.push({
						username: user.Username,
						status: statusCognitoUser
							? Object.entries(statusCognitoUser).find(([key, val]) => key === user.UserStatus)?.[1]
							: undefined,
						email,
					});
				});
				setList(userCognitoList);
				setLoadingData(false);
			})
			.catch((reason: any) => {
				return () => setList([]);
			});
	};

	return (
		<FormLoginWrapper {...args}>
			{currentError && (
				<C.CPNAlert
					isModal
					title="Erro interno"
					description="Aconteceu um erro na execução da operação."
					type="ERROR"
					loading={false}
					onAccept={() => {
						setLoading(false);
						setCurrentError(false);
					}}
				/>
			)}
			<div className="header flex justify-between items-center mb-4">
				<div className={showForm ? "hidden" : "title mb-4 flex flex-col pb-2"}>
					<U.UIText preset="HEADLINE_05">Lista de logins</U.UIText>
				</div>
			</div>
			{loadingData ? (
				<div className="loading bg-primary-lightest p-4 flex gap-2 items-center rounded-lg">
					<div className="animate-spin">
						<U.UIICon name="loading" />
					</div>
					<U.UIText preset="SUBTITLE">Trazendo informações, por favor aguarde...</U.UIText>
				</div>
			) : (
				<div className={showForm ? "hidden" : "table"}>
					{!list.length ? (
						<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
							<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
						</div>
					) : (
						<C.CPNTable
							columns={[
								{
									Header: "Username",
									accessor: "username",
								},
								{
									Header: "Email",
									accessor: "email",
								},
								{
									Header: "Status",
									accessor: "status",
								},
							]}
							data={list}
						/>
					)}
				</div>
			)}
		</FormLoginWrapper>
	);
};

export default FormLogin;
