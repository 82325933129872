import axios, {AxiosInstance, AxiosRequestConfig} from "axios";

// Request Interceptor
function requestInterceptor(confInterceptor: any, config: AxiosRequestConfig) {
	const token = localStorage.getItem("@Auth:token");

	if (confInterceptor && confInterceptor.headers && token) {
		confInterceptor.headers.Authorization = `${token}`;
	}
	confInterceptor.baseURL = config.baseURL;
	return confInterceptor;
}

function requestError(error: any) {
	return Promise.reject(error);
}

function responseError(error: any) {
	return Promise.reject(error);
}

const configHTTP = (URL?: string): AxiosInstance => {
	const config: AxiosRequestConfig = {
		baseURL: URL,
	};
	const baseHTTP = axios.create(config);
	baseHTTP.interceptors.request.use(
		(conf) => {
			return requestInterceptor(conf, config);
		},
		(error: any) => {
			return requestError(error);
		},
	);
	baseHTTP.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			return responseError(error);
		},
	);
	return baseHTTP;
};

const HTTP = configHTTP(process.env.REACT_APP_BASE_API_URL);

export {HTTP, axios};
