// Component generated with util/vox-create-component.js
import React from "react";
import {AppHomeWrapper} from "./AppHome.styled";
import {AppHomeProps, FormHomeSchema} from "./AppHome.types";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {string, SchemaOf, object, number} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as C from "./../../component";
import * as U from "./../../ui";
import uniqid from "uniqid";

const mockDataTable = [
	{
		input1: "Input value 1",
		input2: "Input value 1",
		input3: "Input value 1",
		input4: 1,
		input5: "Input value 1",
		input6: "Input value 1",
		input7: "Input value 1",
		input9: 1,
		input10: "2,1",
		input11: "Input value 1",
		input12: "Input value 1",
		input13: "Input value 1",
	},
	{
		input1: "Input value 2",
		input2: "Input value 2",
		input3: "Input value 2",
		input4: 2,
		input5: "Input value 2",
		input6: "Input value 2",
		input7: "Input value 2",
		input9: 2,
		input10: "2,1",
		input11: "Input value 2",
		input12: "Input value 2",
		input13: "Input value 2",
	},
	{
		input1: "Input value 3",
		input2: "Input value 3",
		input3: "Input value 3",
		input4: 3,
		input5: "Input value 3",
		input6: "Input value 3",
		input7: "Input value 3",
		input9: 2,
		input10: 2,
		input11: "Input value 3",
		input12: "Input value 3",
		input13: "Input value 3",
	},
	{
		input1: "Input value 4",
		input2: "Input value 4",
		input3: "Input value 4",
		input4: 4,
		input5: "Input value 4",
		input6: "Input value 4",
		input7: "Input value 4",
		input9: 2,
		input10: 2,
		input11: "Input value 4",
		input12: "Input value 4",
		input13: "Input value 4",
	},
	{
		input1: "Input value 5",
		input2: "Input value 5",
		input3: "Input value 5",
		input4: 5,
		input5: "Input value 5",
		input6: "Input value 5",
		input7: "Input value 5",
		input9: 2,
		input10: 2,
		input11: "Input value 5",
		input12: "Input value 5",
		input13: "Input value 5",
	},
	{
		input1: "Input value 6",
		input2: "Input value 6",
		input3: "Input value 6",
		input4: 6,
		input5: "Input value 6",
		input6: "Input value 6",
		input7: "Input value 6",
		input9: 1,
		input10: 2,
		input11: "Input value 6",
		input12: "Input value 6",
		input13: "Input value 6",
	},
];

const FormHomeResolver: SchemaOf<FormHomeSchema> = object().shape({
	input1: string().required(),
	input2: string().required(),
	input3: string().required(),
	input4: number().required("Error input4 is required"),
	input5: string().required(),
	input6: string().required(),
	input7: string().required(),
	input8: string(),
	input9: number().required(),
	input11: string().required(),
	input12: string().required(),
	input13: string().required(),
});

export const AppHome: React.VFC<AppHomeProps> = ({...args}) => {
	// Context Here
	// States Here
	const [list, setList] = React.useState<any>([]);
	const [showForm, setShowForm] = React.useState<boolean>(false);
	const [currentDelete, setCurrentDelete] = React.useState<any>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [loadingData, setLoadingData] = React.useState<boolean>(true);
	// Navigation
	const history = useHistory(); // Controla a navegação da página
	// Effects Here
	React.useEffect(() => {
		/**
		 * Aqui inicializamos o table com informacoes que podem ser trazidas de uma chamada api rest,
		 * em este caso estou utilizando valores mocados para simular
		 * Utilizaremos o state setList para clonar o mock
		 */
		(async () => {
			setLoadingData(true);
			// Fazer a chamada rest aquí
			const data = await new Promise((resolve) => {
				setTimeout(() => {
					resolve(mockDataTable);
				}, 2000);
			});
			setList(data);
			setLoadingData(false);
		})();
	}, []);
	/**
	 * useForm
	 * Configuração do formulario
	 */
	const {
		register,
		handleSubmit,
		formState: {errors},
		trigger,
		reset,
	} = useForm<FormHomeSchema>({
		resolver: yupResolver(FormHomeResolver),
		mode: "onSubmit",
	});
	// Handlers Here
	/**
	 * Submit
	 * Chama a function handleSubmit que valida o formulario
	 */
	const onSubmit = handleSubmit(async (data) => {
		setLoading(true);
		// Chamar o rest api para salvar as informações
		await new Promise((resolve) => {
			setTimeout(() => {
				resolve(true);
			}, 3000);
		});
		console.log("Data: ", data);
		// Adiciona o item no inicio da lista
		setList([data, ...list]);
		setLoading(false);
	});
	const clearForm = () =>
		reset({
			input1: "",
			input2: "",
			input3: "",
			input4: 0,
			input5: "",
			input6: "",
			input7: "",
			input8: "",
			input9: 0,
			input11: "",
			input12: "",
			input13: "",
		});
	const onEdit = (row: any) => {
		reset(row.values);
		trigger();
		toogle();
	};
	const onDelete = (row: any) => setCurrentDelete(row);
	const onAdd = () => {
		clearForm();
		toogle();
	};
	const toogle = () => {
		setShowForm(!showForm);
	};
	const deleteItem = async () => {
		setLoading(true);
		// Chamar o rest api para salvar as informações
		await new Promise((resolve) => {
			setTimeout(() => {
				resolve(true);
			}, 3000);
		});
		setCurrentDelete(null);
		setLoading(false);
	};
	const body = (
		<AppHomeWrapper {...args}>
			{currentDelete && (
				<C.CPNAlert
					isModal
					title="Eliminar"
					description="Tem certeza que deseja eliminar este item?"
					type="INFO"
					loading={loading}
					onCancel={() => !loading && setCurrentDelete(null)}
					onAccept={() => !loading && deleteItem()}
				/>
			)}
			<div className="header flex justify-between items-center mb-4">
				<div className="title flex flex-col pb-2">
					<U.UIText preset="HEADLINE_05">FormFiles</U.UIText>
					<U.UIText preset="BODY">Some description here</U.UIText>
				</div>
				<div className={showForm ? "hidden" : "action"}>
					<U.UIButton icon="addCircle" label="Adicionar" outline onClick={onAdd} />
				</div>
			</div>
			<div className={!showForm ? "hidden" : "forms"}>
				<div className="description">
					<U.UIText preset="BODY" color="PRIMARY">
						Text inputs
					</U.UIText>
					<U.UIText preset="BODY_02">Todos os tipos de inputs</U.UIText>
				</div>
				<div className="form">
					<div className="input">
						<C.CPNTextInput
							label="Common text input"
							placeholder="Common text input"
							error={errors.input1?.message}
							disabled={loading}
							{...register("input1")}
						/>
					</div>
					<div className="input">
						<C.CPNDatePicker
							label="Date picker"
							error={errors.input2?.message}
							disabled={loading}
							range
							{...register("input2")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Password text input"
							placeholder="Password text input"
							type="password"
							error={errors.input3?.message}
							disabled={loading}
							{...register("input3")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Number text input"
							placeholder="Number text input"
							type="number"
							error={errors.input4?.message}
							disabled={loading}
							{...register("input4")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Input with right icon"
							placeholder="Input with right icon"
							error={errors.input5?.message}
							disabled={loading}
							icon="search"
							{...register("input5")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Input with badge"
							placeholder="Input with badge"
							error={errors.input6?.message}
							disabled={loading}
							badge={2}
							{...register("input6")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Common text input with helpo text"
							placeholder="Common text input with helpo text"
							help="Common text input with helpo text"
							error={errors.input7?.message}
							disabled={loading}
							{...register("input7")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Common text input with helpo text"
							type="file"
							placeholder="Common text input with helpo text"
							help="Common text input with helpo text"
							error={errors.input8?.message}
							disabled={loading}
							{...register("input8")}
						/>
					</div>
				</div>
				<div className="description">
					<U.UIText preset="BODY" color="PRIMARY">
						Selects
					</U.UIText>
					<U.UIText preset="BODY_02">Todos os tipos de select disponiveis</U.UIText>
				</div>
				<div className="form">
					<div className="input">
						<C.CPNSelectInput
							label="Simple select input with options"
							placeholder="Please select one option"
							options={[
								{
									id: uniqid(), // Autogenerate or custom id
									label: "Option label 1",
									value: 1,
								},
								{
									id: uniqid(), // Autogenerate or custom id
									label: "Option label 2",
									value: 2,
								},
							]} // Add here ll options nedded
							type="SIMPLE" // Single check or multicheck
							error={errors.input9?.message}
							disabled={loading}
							{...register("input9")}
						/>
					</div>
				</div>
				<div className="form">
					<div className="input">
						<C.CPNTextarea label="Textarea input" maxLength={250} {...register("input11")} />
					</div>
				</div>
				<div className="action gap-4">
					<U.UIButton label="Cancelar" outline loading={loading} onClick={toogle} />
					<U.UIButton label="Enviar" loading={loading} onClick={onSubmit} />
				</div>
			</div>
			{loadingData ? (
				<div className="loading bg-primary-lightest p-4 flex gap-2 items-center rounded-lg">
					<div className="animate-spin">
						<U.UIICon name="loading" />
					</div>
					<U.UIText preset="SUBTITLE">Trazendo informações, porfavor aguarde...</U.UIText>
				</div>
			) : (
				<div className={showForm ? "hidden" : "table"}>
					{!list.length ? (
						<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
							<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
						</div>
					) : (
						<C.CPNTable
							onEdit={onEdit}
							onDelete={onDelete}
							columns={[
								{
									Header: "input 1",
									accessor: "input1",
								},
								{
									Header: "input 2",
									accessor: "input2",
								},
								{
									Header: "input 3",
									accessor: "input3",
								},
								{
									Header: "input 4",
									accessor: "input4",
								},
								{
									Header: "input 5",
									accessor: "input5",
								},
								{
									Header: "input 6",
									accessor: "input6",
								},
								{
									Header: "input 7",
									accessor: "input7",
								},
								{
									Header: "input 9",
									accessor: "input9",
								},
								{
									Header: "input 10",
									accessor: "input10",
								},
								{
									Header: "input 11",
									accessor: "input11",
								},
								{
									Header: "input 12",
									accessor: "input12",
								},
								{
									Header: "input 13",
									accessor: "input13",
								},
							]}
							data={list}
						/>
					)}
				</div>
			)}
		</AppHomeWrapper>
	);
	return <AppHomeWrapper />;
};

export default AppHome;
