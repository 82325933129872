// Component generated with util/vox-create-component.js
import React from "react";
import {FormAgentWrapper} from "./FormAgent.styled";
import {FormAgentProps, FormAgentSchema} from "./FormAgent.types";
import {useForm} from "react-hook-form";
import {string, SchemaOf, object} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as C from "../../component";
import * as U from "../../ui";
import {
	getAgentByAgentLegal,
	getIndividualByAgent,
	getRegister,
	getRegisterById,
	postRegister,
	putRegister,
} from "../../service/api/registry-api";
import {Individual, ResponseRegister} from "../../types/register";
import DetailAgent from "./Detail/DetailAgent";
import {signupVC} from "../../service/api/login-api";
import {SignUpDTO} from "../../types/signup";

const FormAgentResolver: SchemaOf<FormAgentSchema> = object().shape({
	fullName: string().required(),
	email: string().required(),
	personId: string().notRequired(),
});

export const FormAgentLegal: React.VFC<FormAgentProps> = ({...args}) => {
	const [list, setList] = React.useState<any>([]);
	const [showForm, setShowForm] = React.useState<boolean>(false);
	const [currentDelete, setCurrentDelete] = React.useState<any>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [login, setLogin] = React.useState<boolean>(false);
	const [loadingData, setLoadingData] = React.useState<boolean>(true);
	const [individual, setindiviual] = React.useState<Individual>();
	const [error, seterror] = React.useState<boolean>(false);
	const [listIndividualByAgent, setlistIndividualByAgent] = React.useState<any[]>([]);
	const [listAgentLegalByAgent, setlistAgentLegalByAgent] = React.useState<any[]>([]);

	const category = "AGENT_LEGAL";

	React.useEffect(() => {
		setLoadingData(true);
		getAgentListHandler();
	}, []);

	const {
		register,
		handleSubmit,
		formState: {errors},
		trigger,
		reset,
	} = useForm<FormAgentSchema>({
		resolver: yupResolver(FormAgentResolver),
		mode: "onSubmit",
	});

	const getIndividualAgent = async (personId: string) => {
		try {
			const agent = await getIndividualByAgent(personId);
			if (agent) {
				const agentList = (agent.data.message as any[]).map((agentProduct: any) => {
					return {
						...agentProduct,
					};
				});
				setlistIndividualByAgent(agentList);
			}
		} catch (error) {
			seterror(true);
		}
	};

	const getIndividualAgentLegal = async (personId: string) => {
		try {
			const agent = await getAgentByAgentLegal(personId);
			if (agent) {
				const agentList = (agent.data.message as any[]).map((agentProduct: any) => {
					return {
						...agentProduct,
					};
				});
				setlistAgentLegalByAgent(agentList);
			}
		} catch (error) {
			seterror(true);
		}
	};

	const getAgentListHandler = (): any => {
		getRegister(category)
			.then((result: ResponseRegister) => {
				const resultList: FormAgentSchema[] = Array<FormAgentSchema>();
				result.data.message.forEach((element: any) => {
					resultList.push({
						fullName: element.fullName,
						email: element.email,
						personId: element.personId,
					});
				});
				setList(resultList);
				setLoadingData(false);
			})
			.catch((reason: any) => {
				return () => setList([]);
			});
	};

	const onLogin = handleSubmit(async (data: any) => {
		data.status = "USER_ACTIVE";
		const signUP: SignUpDTO = {
			email: data.email,
			socialName: data.fullName,
			personId: data.personId,
		};
		await signupVC(signUP);
		await saveRegister(data);
		toogle();
	});

	const onSubmit = handleSubmit(async (data: any) => {
		setLoading(true);
		await saveRegister(data);
		setLoading(false);
	});

	const saveRegister = async (data: any) => {
		if (!data) return;
		const request = {
			individual: {
				...data,
				category,
			},
			id: data.personId,
		};
		if (data.personId) {
			await putRegister(request);
		} else {
			request.individual.status = "USER_CREATING";
			request.individual.personId = await postRegister(request);
		}
	};
	const clearForm = () =>
		reset({
			fullName: "",
			email: "",
			personId: "",
		});

	const getIndividual = async (id: string) => {
		return await getRegisterById(id, category);
	};

	const onEdit = async (row: any) => {
		if (row.values.personId) {
			const result: any = await getIndividual(row.values.personId);
			reset({
				...result,
			});
			setindiviual(result);
			if (result.status === "USER_CREATING") {
				setLogin(true);
			} else {
				setLogin(false);
			}
			await getIndividualAgent(row.values.personId);
			await getIndividualAgentLegal(row.values.personId);
		}
		trigger();
		toogle();
	};

	const onAdd = () => {
		clearForm();
		toogle();
	};

	const cancel = () => {
		setindiviual(undefined);
		clearForm();
		getAgentListHandler();
		toogle();
	};

	const toogle = () => {
		setShowForm(!showForm);
	};

	const deleteItem = async () => {
		setLoading(true);
		// Chamar o rest api para salvar as informações
		await new Promise((resolve) => {
			setTimeout(() => {
				resolve(true);
			}, 3000);
		});
		setCurrentDelete(null);
		setLoading(false);
	};

	const body = (
		<FormAgentWrapper {...args}>
			{currentDelete && (
				<C.CPNAlert
					isModal
					title="Eliminar"
					description="Tem certeza que deseja eliminar este item?"
					type="INFO"
					loading={loading}
					onCancel={() => !loading && setCurrentDelete(null)}
					onAccept={() => !loading && deleteItem()}
				/>
			)}
			<div className="header flex justify-between items-center mb-4">
				<div className="title flex flex-col pb-2">
					<U.UIText preset="HEADLINE_05">Lista de Responsáveis</U.UIText>
					<U.UIText preset="BODY">Lista de Responsáveis</U.UIText>
				</div>
				<div className={showForm ? "hidden" : "action"}>
					<U.UIButton icon="addCircle" label="Adicionar" outline onClick={onAdd} />
				</div>
			</div>
			<div className={!showForm ? "hidden" : "forms"}>
				<div className="description">
					<U.UIText preset="BODY_02">Editar Responsável</U.UIText>
				</div>
				<div className="form">
					<div className="input">
						<C.CPNTextInput
							label="Nome completo"
							error={errors.fullName?.message}
							disabled={loading}
							{...register("fullName")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Email"
							error={errors.email?.message}
							disabled={loading}
							{...register("email")}
						/>
					</div>
				</div>
				<div className="action gap-4">
					<U.UIButton label="Voltar" outline loading={loading} onClick={cancel} />
					<U.UIButton label="Salvar" loading={loading} onClick={onSubmit} />
					<U.UIButton disabled={!login} label="Login" loading={loading} onClick={onLogin} />
				</div>
				{/* <DetailAgent
						registerid={individual?.personId}
						fullName={individual?.fullName}
						agents={listIndividualByAgent || []}
						error={error}
						category={category}
						disabledAdd={true}
					/> */}
				<DetailAgent
					registerid={individual?.personId}
					fullName={individual?.fullName}
					agents={listAgentLegalByAgent || []}
					error={error}
					category={"AGENT"}
				/>
			</div>
			{loadingData ? (
				<div className="loading bg-primary-lightest p-4 flex gap-2 items-center rounded-lg">
					<div className="animate-spin">
						<U.UIICon name="loading" />
					</div>
					<U.UIText preset="SUBTITLE">Trazendo informações, por favor aguarde...</U.UIText>
				</div>
			) : (
				<div className={showForm ? "hidden" : "table"}>
					{!list.length ? (
						<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
							<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
						</div>
					) : (
						<C.CPNTable
							onEdit={onEdit}
							columns={[
								{
									accessor: "personId",
								},
								{
									Header: "Nome",
									accessor: "fullName",
								},
								{
									Header: "Email",
									accessor: "email",
								},
							]}
							data={list}
						/>
					)}
				</div>
			)}
		</FormAgentWrapper>
	);
	return body;
};

export default FormAgentLegal;
