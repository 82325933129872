// Component generated with util/create-component.js
import classNames from "classnames";
import styled from "styled-components";
import {AlertProps, Type} from "./Alert.types";
import tw from "twin.macro";
/**
 * Components
 */
export const AlertWrapper = styled.div.attrs<AlertProps>(({type}) => {
	const className = classNames({
		"rounded-lg px-5 py-4 flex flex-row w-full bg-gray-glow border border-gray-light": true,
		// "bg-secondary-lightest": type === "ATENTION",
		// "bg-gray-glow": type === "ERROR",
		// "bg-primary-lightest": type === "INFO",
		// "bg-green-lightest": type === "SUCCESS",
		// "bg-amber-lightest": type === "LOADING",
	});
	return {
		className,
	};
})<{type: keyof typeof Type}>``;

export const AlertModal = styled.div`
	${tw`fixed flex items-center justify-center z-50`}
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	.overlay {
		${tw`fixed z-30 bg-gray bg-opacity-70`}
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	.content {
		${tw`shadow-md bg-white rounded-lg px-5 py-5 z-40`}
		min-width: 320px;
		.body {
			${tw`flex flex-col mb-4`}
		}
		.footer {
			${tw`flex gap-4 justify-end`}
		}
	}
`;
