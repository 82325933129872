import {FormRegisterSchema} from "../FormRegister.types";
import {SchemaOf, object, mixed, string} from "yup";

export const FormRegisterResolver: SchemaOf<FormRegisterSchema> = object().shape({
	personId: string().notRequired(),
	status: string().notRequired(),
	cvmType: string().notRequired(),
	statusLabel: string().notRequired(),
	relacionamento: string().notRequired(),
	socialName: string().required("Nome do cotista é obrigatório."),
	fullName: string().required("Nome completo do cotista é obrigatório."),
	email: string().required("Email do cotista é obrigatório."),
	cpf: string().notRequired(),
	suitability: string().notRequired(),
	suitabilityDismiss: string().notRequired(),
	risk: string().notRequired(),
	reviewRegistration: string().notRequired(),
	relationship: string().notRequired(),
	birthDate: string().notRequired(),
	naturalnessCity: string().notRequired(),
	naturalnessState: string().notRequired(),
	nationality: string().notRequired(),
	extraNationality: string().notRequired(),
	nationality2: string().notRequired(),
	taxDomicile: string().notRequired(),
	civilStatus: string().notRequired(),
	gender: string().notRequired(),
	differentGender: string().notRequired(),
	motherName: string().notRequired(),
	partnerName: string().notRequired(),
	partnerCpf: string().notRequired(),
	address: mixed().notRequired(),
	phone: string().notRequired(),
	identityDocument: mixed().notRequired(),
	occupation: mixed().notRequired(),
	income: mixed().notRequired(),
	externalAccount: mixed().notRequired(),
	usPerson: string().notRequired(),
	isProfessionalInvestidor: string().notRequired(),
	isQualifiedInvestidor: string().notRequired(),
	isLinkedVox: string().notRequired(),
	linkedVox: string().notRequired(),
	isPep: string().notRequired(),
	pep: string().notRequired(),
	ofThirtParty: string().notRequired(),
	representative: mixed().notRequired(),
	extraTaxDomicile: string().notRequired(),
	taxId: string().notRequired(),
	thirtCanOperateName: string().notRequired(),
	thirtCanOperateCPF: string().notRequired(),
	portfolioAdministrator: string().notRequired(),
	custodyResponsible: string().notRequired(),
	fileAddress: mixed().notRequired(),
	fileIdentity: mixed().notRequired(),
	fileProcuration: mixed().notRequired(),
	form: mixed().notRequired(),
	document: mixed().notRequired(),
	category: mixed().notRequired(),
});
