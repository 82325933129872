/* eslint-disable react-hooks/exhaustive-deps */
// Component generated with util/vox-create-component.js
import React, {useEffect} from "react";
import * as R from "ramda";
import {SelectInputWrapper, SelectInputOptions, FakeInput} from "./SelectInput.styled";
import {SelectInputProps, Option} from "./SelectInput.types";
import {Input} from "./../TextInput/TextInput.styled";
import {CPNTextInput, CPNCheckBox} from "./../";
import {UIText, UIICon} from "./../../ui";

export const SelectInput = React.forwardRef<HTMLInputElement, SelectInputProps>(
	(
		{
			options = [],
			label,
			placeholder,
			enableSearch = false,
			disabled,
			type = "SIMPLE",
			error,
			...args
		},
		inRef,
	) => {
		// Refs
		const ref = React.useRef<HTMLInputElement>(null);
		React.useImperativeHandle(inRef, () => ref.current!, [ref]);
		// Context Here
		// States Here
		const [showOptions, setOptions] = React.useState<boolean>(false);
		const [selectValue, setSelectValue] = React.useState<Option>({
			id: "",
			value: "",
			label: "",
			hidden: false,
		});
		const [multiValue, setMultiValue] = React.useState<string[]>([]);
		const [visibleOptions, setVisibleOptions] = React.useState<Option[]>(options);
		// // Effects Here
		React.useEffect(() => {
			document.body.addEventListener("click", () => {
				setOptions(false);
			});
			return () => {
				document.body.removeEventListener("click", () => setOptions(false));
			};
		}, []);
		// Handlers Here
		const toggleOptions = () => {
			if (disabled) return;
			setOptions(!showOptions);
		};
		const setInputValue = (val: string) => {
			if (ref.current) {
				const valueSetter = Object.getOwnPropertyDescriptor(ref.current, "value")?.set;
				const prototype = Object.getPrototypeOf(ref.current);
				const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, "value")?.set;
				if (valueSetter && valueSetter !== prototypeValueSetter) {
					prototypeValueSetter?.call(ref.current, val);
				} else {
					valueSetter?.call(ref.current, val);
				}
				ref.current.click();
				ref.current.focus();
				ref.current.dispatchEvent(new Event("change", {bubbles: true}));
			}
		};

		const selectOption = (opt: Option) => {
			setSelectValue(opt);
			setInputValue(opt.value);
			setOptions(false);
		};

		const onSearch = (val: any) => {
			val = R.trim(val);
			setVisibleOptions(
				val
					? options.map((opt) => ({
							...opt,
							hidden: !R.toUpper(opt.label).includes(R.toUpper(val)),
							// tslint:disable-next-line:indent
					  }))
					: options,
			);
		};
		const addOption = (e: any) => {
			const exists = R.indexOf(e, multiValue);
			const newValue = exists === -1 ? [...multiValue, e] : [...R.remove(exists, 1, multiValue)];
			setMultiValue(newValue);
			setInputValue(newValue.toString() || "");
		};
		const setTextInputValue = () => {
			const iptValue = ref.current?.value.toString() || "";
			const splitIpt: string[] = iptValue.split(",").filter((i) => i);
			const newValue =
				splitIpt.length > 1
					? options
							.find((m) => m.value.toString() === splitIpt[0])
							?.label.concat(multiValue.length > 1 ? " ..." : "")
					: options.find((o) => o.value.toString() === ref.current?.value.toString())?.label ||
					  selectValue.label ||
					  options
							.find((o) => o.value.toString() === multiValue[0])
							?.label.concat(multiValue.length > 1 ? " ..." : "") ||
					  "";
			return newValue;
		};

		useEffect(() => {
			const iptValue = ref.current?.value.toString() || "";
			const splitIpt: string[] = iptValue.split(",").filter((i) => i);
			// const filterOptios: any[] = options.filter((opt) => splitIpt.includes(opt.value));
			setMultiValue(splitIpt);
		}, []);

		useEffect(() => {
			if (showOptions) {
				setVisibleOptions(
					options.map((opt) => ({
						...opt,
						checked: !!multiValue.includes(opt.value),
					})),
				);
			}
		}, [showOptions, multiValue]);

		return (
			<SelectInputWrapper
				onClick={(e) => {
					e.stopPropagation();
				}}>
				{/* <span>{ref.current?.value.toString().split(',').filter(i => i).length}</span>
					<span>{ref.current?.value.toString()}</span> */}
				<FakeInput ref={ref} {...args} tabIndex={-1} />
				<div
					className={`w-full ${
						(!!selectValue.value || !R.isEmpty(multiValue)) && showOptions ? "selected" : ""
					}`}
					onClick={toggleOptions}>
					<CPNTextInput
						{...{label, placeholder, error}}
						disabled
						icon="chevronDown"
						badge={
							type === "SIMPLE" || !ref.current?.value
								? 0
								: (ref.current?.value.toString() || "").split(",").filter((i) => i).length
						}
						value={setTextInputValue()}
					/>
				</div>
				<SelectInputOptions visible={showOptions}>
					{enableSearch && showOptions && (
						<div className="search">
							<Input onChange={(e) => onSearch(e.currentTarget.value)} autoFocus />
							<UIICon color="PRIMARY" name="search" size="sm" />
						</div>
					)}
					<ul>
						{R.sortBy(R.prop("label"), visibleOptions).map((opt) => {
							return type === "SIMPLE" ? (
								<li
									key={opt.id}
									onClick={() => selectOption(opt)}
									className={opt.hidden ? "hidden" : ""}>
									<UIText>{opt.label}</UIText>
								</li>
							) : (
								<li key={opt.id} className={opt.hidden ? "hidden" : ""}>
									<CPNCheckBox
										value={opt.value}
										label={`${opt.label}`}
										checked={!!opt.checked}
										onChangeCapture={addOption}
									/>
								</li>
							);
						})}
					</ul>
				</SelectInputOptions>
			</SelectInputWrapper>
		);
	},
);

export default SelectInput;
