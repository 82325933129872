import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormRelatedCompanySchema} from "../FormRegister.types";
import {useForm, useFieldArray} from "react-hook-form";
import {SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

const FormRelatedCompanyResolver: SchemaOf<FormRelatedCompanySchema> = object().shape({
	relatedCompany: mixed().notRequired(),
});

const FormRelatedCompany: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {control, register, handleSubmit} = useForm<FormRelatedCompanySchema>({
		resolver: yupResolver(FormRelatedCompanyResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formRelatedCompany,
		},
	});
	const {
		fields: fieldsRelatedCompany,
		append: appendRelatedCompany,
		remove: removeRelatedCompany,
	} = useFieldArray({
		control,
		name: "relatedCompany",
	});
	// Handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_RELATED_COMPANY",
			payload: {
				formRelatedCompany: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<div className="flex gap-4 justify-between">
				<U.UIText preset="HEADLINE_05">Controladoras, coligadas e controladas</U.UIText>
				<U.UIButton
					label="Adicionar Controladoras, Coligadas e Controladas"
					preset="LINK"
					onClick={() => appendRelatedCompany({id: uniqid()})}
					righticon="addCircle"
				/>
			</div>
			<div className="flex-1 flex flex-col gap-4">
				{fieldsRelatedCompany.map((_field, index) => (
					<div className="flex flex-col p-4 bg-gray-glow rounded border border-gray-light gap-4">
						<div className="input">
							<C.CPNTextInput label="Razão Social" {...register(`relatedCompany.${index}.name`)} />
						</div>
						<div className="input">
							<C.CPNTextInput label="CNPJ" {...register(`relatedCompany.${index}.cnpj`)} />
						</div>
						<div className="input">
							<C.CPNTextInput
								label="Capital Volante (%)"
								{...register(`relatedCompany.${index}.floatingCapital`)}
							/>
						</div>
						<div className="input">
							<C.CPNTextInput
								label="Capital Total"
								{...register(`relatedCompany.${index}.totalCapital`)}
							/>
						</div>
						<div className="input">
							<C.CPNTextInput
								label="País de domicílio Fiscal"
								{...register(`relatedCompany.${index}.taxDomicile`)}
							/>
						</div>
						<U.UIButton
							disabled={loading}
							icon="trash"
							preset="LINK"
							onClick={() => removeRelatedCompany(index)}
						/>
					</div>
				))}
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormRelatedCompany;
