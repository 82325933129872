import {Asset, AssetIdentity, ResponseCreateAssetBody, ResponseGetAsset} from "../../types/asset";
import {HTTP} from "../http";

const BASE_ASSETS_URL = `/assets`;

export const createAsset = async (props: Asset): Promise<ResponseCreateAssetBody> => {
	const asset = props;
	return (await HTTP.post(`${BASE_ASSETS_URL}`, asset)) as ResponseCreateAssetBody;
};

export const getAssetListByType = async (props: AssetIdentity): Promise<ResponseGetAsset> => {
	const assetIdentity = props;
	const result = await HTTP.get<any>(`${BASE_ASSETS_URL}/${assetIdentity.type}`);
	return result.data as ResponseGetAsset;
};

export const getAssetByIdentifier = async (props: AssetIdentity): Promise<Asset> => {
	const assetIdentity = props;
	const result = await HTTP.get<any>(
		`${BASE_ASSETS_URL}/${assetIdentity.type}/${assetIdentity.id}`,
	);
	return result.data.message[0] as Asset;
};

export const removeAssetByIdentifier = async (props: AssetIdentity): Promise<ResponseGetAsset> => {
	const assetIdentity = props;
	return (await HTTP.delete(
		`${BASE_ASSETS_URL}/${assetIdentity.type}/${assetIdentity.id}`,
	)) as ResponseGetAsset;
};

export const updateAsset = async (asset: Asset): Promise<ResponseGetAsset> => {
	const assetIdentity = asset.identity;
	return (await HTTP.put(
		`${BASE_ASSETS_URL}/${assetIdentity?.type}/${assetIdentity?.id}`,
		asset,
	)) as ResponseGetAsset;
};
