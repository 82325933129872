import Amplify, {Auth, Hub} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";

type AuthResponse = {
	readonly data?: any;
	readonly error?: any;
};

Amplify.configure({
	Auth: {
		mandatorySignIn: process.env.REACT_APP_AMPLIFY_AUTH_CONFIG_MANDATORY_SIGNIN,
		region: process.env.REACT_APP_AMPLIFY_AUTH_CONFIG_REGION,
		userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_CONFIG_COGNITO_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_AMPLIFY_AUTH_CONFIG_COGNITO_POOL_WEB_CLIENT_ID,
		oauth: {
			scope: ["email", "profile", "openid"],
			redirectSignIn: process.env.REACT_APP_AMPLIFY_AUTH_CONFIG_OAUTH_REDIRECT_URL_SIGNIN,
			redirectSignOut: process.env.REACT_APP_AMPLIFY_AUTH_CONFIG_OAUTH_REDIRECT_URL_SIGNOUT,
			domain: process.env.REACT_APP_AMPLIFY_AUTH_CONFIG_OAUTH_DOMAIN,
			responseType: "code",
		},
	},
});

const federatedSignIn = () => {
	Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
};

const signout = async () => {
	await Auth.signOut();
};

const currentAuthenticatedUser = async () => {
	return await Auth.currentAuthenticatedUser();
};

export {federatedSignIn, signout, currentAuthenticatedUser, Auth, Amplify, Hub};
