import * as React from "react";
import * as U from "../../../ui";
import * as C from "../../../component";
import {RegisterContext} from "../../../context/RegisterContext";
import {FormFundStructureSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {SchemaOf, object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

export const FormFundStructureResolver: SchemaOf<FormFundStructureSchema> = object().shape({
	administrator: string().notRequired(),
	administratorCnpj: string().notRequired(),
	custodian: string().notRequired(),
	custodianCnpj: string().notRequired(),
	manager: string().notRequired(),
	managerCnpj: string().notRequired(),
});

const FormFundStructure: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	console.log(state);

	const {
		register,
		handleSubmit,
		formState: {errors},
	} = useForm<FormFundStructureSchema>({
		resolver: yupResolver(FormFundStructureResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formFundStructure,
		},
	});
	// Handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_FUND_STRUCTURE",
			payload: {
				formFundStructure: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Declarações legais</U.UIText>
			<div className="flex-1">
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNTextInput
							label="Administrador"
							error={errors.administrator?.message}
							{...register("administrator")}
						/>
					</div>

					<div className="input">
						<C.CPNTextInput
							label="CNPJ do Administrador"
							error={errors.administratorCnpj?.message}
							{...register("administratorCnpj")}
						/>
					</div>

					<div className="input">
						<C.CPNTextInput
							label="Gestor"
							error={errors.manager?.message}
							{...register("manager")}
						/>
					</div>

					<div className="input">
						<C.CPNTextInput
							label="Gestor do CNPJ"
							error={errors.managerCnpj?.message}
							{...register("managerCnpj")}
						/>
					</div>

					<div className="input">
						<C.CPNTextInput
							label="Custodiante"
							error={errors.custodian?.message}
							{...register("custodian")}
						/>
					</div>

					<div className="input">
						<C.CPNTextInput
							label="CNPJ do Custodiante"
							error={errors.custodianCnpj?.message}
							{...register("custodianCnpj")}
						/>
					</div>
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormFundStructure;
