import {FormRegisterPJSchema} from "../FormRegister.types";
import {SchemaOf, object, mixed, string, number} from "yup";

export const FormRegisterPJResolver: SchemaOf<FormRegisterPJSchema> = object().shape({
	personId: string().notRequired(),
	relacionamento: string().notRequired(),
	corporateName: string().notRequired(),
	tradeName: string().notRequired(),
	cnpj: string().notRequired(),
	suitability: string().notRequired(),
	suitabilityDismiss: string().notRequired(),
	risk: string().notRequired(),
	reviewRegistration: string().notRequired(),
	relationship: string().notRequired(),
	address: mixed().notRequired(),
	phone: string().notRequired(),
	email: string().notRequired(),
	incorporateDate: string().notRequired(),
	incorporateType: string().notRequired(),
	differentIncorporateType: string().notRequired(),
	mainActivity: string().notRequired(),
	contactName: string().notRequired(),
	contactPhone: string().notRequired(),
	contactEmail: string().notRequired(),
	monthlyBilling: number().notRequired(),
	netPatrimony: number().notRequired(),
	socialCapital: string().notRequired(),
	dateFinancial: string().notRequired(),
	administrator: mixed().notRequired(),
	relatedCompany: mixed().notRequired(),
	beneficiaries: mixed().notRequired(),
	isNonProfit: string().notRequired(),
	donors: string().notRequired(),
	isTradedCompany: string().notRequired(),
	stockExchange: string().notRequired(),
	subsidizedTradeCompany: string().notRequired(),
	subsidizedTCompanyName: string().notRequired(),
	subsidizedTStockExchange: string().notRequired(),
	isRegulatedFinancialSystem: string().notRequired(),
	regulatedFinancialSystemName: string().notRequired(),
	isSubsidizedRegulateFinancial: string().notRequired(),
	subsidizedRegulateCompany: string().notRequired(),
	subsidizedRegulateEntity: string().notRequired(),
	isLinkedBancoCentral: string().notRequired(),
	isStartUp: string().notRequired(),
	isProfessionalInvestidor: string().notRequired(),
	isQualifiedInvestidor: string().notRequired(),
	isLinkedVox: string().notRequired(),
	linkedVox: string().notRequired(),
	ofThirtParty: string().notRequired(),
	isPep: string().notRequired(),
	pepName: string().notRequired(),
	pepCPF: string().notRequired(),
	pepRelationCompany: string().notRequired(),
	pepOcupation: string().notRequired(),
	pepTaxDomicile: string().notRequired(),
	pepNationality: string().notRequired(),
	pepCompany: string().notRequired(),
	externalAccount: mixed().notRequired(),
	representative: mixed().notRequired(),
	extraTaxDomicile: string().notRequired(),
	taxId: string().notRequired(),
	thirtCanOperateName: string().notRequired(),
	thirtCanOperateCPF: string().notRequired(),
	portfolioAdministrator: string().notRequired(),
	custodyResponsible: string().notRequired(),
	fileAddress: mixed().notRequired(),
	fileIdentity: mixed().notRequired(),
	fileProcuration: mixed().notRequired(),
	form: mixed().notRequired(),
	document: mixed().notRequired(),
	status: string().notRequired(),
	cvmType: string().notRequired(),
	statusLabel: string().notRequired(),
});
