// Component generated with util/vox-create-component.js
import React from "react";
import {Overlay, ModalWrapper, ModalContainer} from "./Modal.styled";
import {ModalProps} from "./Modal.types";
import {UIText, UIButton, UIICon} from "./../../ui";

export const Modal: React.FC<ModalProps> = ({
	title,
	description,
	primaryAction,
	secondaryAction,
	showModal = false,
	loading = false,
	onModalClose = () => {},
	children,
	...args
}) => {
	// Context Here
	// States Here
	const [visible, setVisible] = React.useState<boolean>();
	// Effects Here
	React.useEffect(() => {
		setVisible(showModal);
	}, [showModal]);
	// Handlers Here
	const hideModal = () => {
		if (loading) return;
		setVisible(!visible);
		onModalClose();
	};
	if (!visible) return null;
	return (
		<ModalContainer {...args}>
			<Overlay onClick={hideModal} />
			<ModalWrapper>
				<div className="header">
					{title && <UIText preset="HEADLINE_05">{title}</UIText>}{" "}
					<UIICon name="times" color="DARK" onClick={hideModal} />
				</div>
				<div className="body">{children}</div>
				<div className="footer">
					{secondaryAction && (
						<UIButton
							outline
							preset="DARK"
							loading={secondaryAction.loading}
							disabled={secondaryAction.loading || secondaryAction.disabled || loading}
							{...{onClick: secondaryAction.onClick, label: secondaryAction.label}}
						/>
					)}
					{primaryAction && (
						<UIButton
							loading={primaryAction.loading}
							disabled={primaryAction.loading || primaryAction.disabled || loading}
							{...{onClick: primaryAction.onClick, label: primaryAction.label}}
						/>
					)}
				</div>
			</ModalWrapper>
		</ModalContainer>
	);
};

export default Modal;
