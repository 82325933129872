import * as React from "react";
import * as U from "../../../ui";
import * as C from "../../../component";
import {RegisterContext} from "../../../context/RegisterContext";
import {FormRepresentativeSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

const FormRepresentativeResolver: SchemaOf<FormRepresentativeSchema> = object().shape({
	representative: mixed().notRequired(),
});

const FormRepresentative: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {register, handleSubmit, watch} = useForm<FormRepresentativeSchema>({
		resolver: yupResolver(FormRepresentativeResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formProcurator,
		},
	});
	// Watch
	const isShareSignature = watch("representative.isShareSignature", "") || "";
	// handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_REPRESENTATIVE",
			payload: {
				formProcurator: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Representantes Legais</U.UIText>
			<div className="flex-1">
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNTextInput label="Nome" {...register("representative.name")} />
					</div>
					<div className="input">
						<C.CPNDatePicker
							label="Data Nascimento"
							disabled={loading}
							{...register("representative.birthDate")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Outorga de Poderes"
							options={[
								{
									id: uniqid(),
									label: "Amplo",
									value: "Amplo",
								},
								{
									id: uniqid(),
									label: "Específico",
									value: "Específico",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("representative.power")}
						/>
					</div>
					<div className="input free w-full">
						<C.CPNTextarea
							label="Poderes Específicos"
							maxLength={10000}
							disabled={loading}
							{...register(`representative.powerEspecific`)}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Assina em conjunto"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register(`representative.isShareSignature`)}
						/>
					</div>
					{isShareSignature === "Sim" && (
						<div className="input">
							<C.CPNTextInput
								label="Assina com quem?"
								{...register(`representative.shareSignature`)}
							/>
						</div>
					)}
					<div className="input">
						<C.CPNTextInput label="Nacionalidade" {...register("representative.nationality")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Cpf" {...register("representative.cpf")} />
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="É Pessoa Politicamente Exposta (PEP)?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("representative.pep")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput label="Logradouro" {...register("representative.address.street")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Número" {...register("representative.address.number")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Bairro" {...register("representative.address.neighborhood")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="CEP" {...register("representative.address.cep")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Cidade" {...register("representative.address.city")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Estado" {...register("representative.address.state")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="País" {...register("representative.address.country")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Telefone" {...register("representative.phone")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Email" {...register("representative.email")} />
					</div>
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormRepresentative;
