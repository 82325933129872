// Component generated with util/vox-create-component.js
import React from "react";
import {useForm} from "react-hook-form";
import {SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as C from "../../../component";
import * as U from "../../../ui";
import {useHistory} from "react-router-dom";
import FundProductContext from "../../../context/FundProductContext";
import {CPNTable} from "../../../component";
import {CSVLink} from "react-csv";
import {
	postCapitalCommited,
	getCapitalCommited,
	checkCapitalCommited,
} from "../../../service/api/fund-api";
import moment from "moment";

const FormTransactionResolver: SchemaOf<{readonly file?: any}> = object().shape({
	file: mixed().required(),
});

const FormTransaction: React.FC<any> = () => {
	const {state, dispatch} = React.useContext(FundProductContext);
	const [loading, setloading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<boolean>(false);
	const history = useHistory();
	const [list, setList] = React.useState<any[]>([]);
	const [listTransaction, setlistTransaction] = React.useState<any>([]);
	const [modalImportTransactions, setmodalImportTransactions] = React.useState<boolean>();
	const [modalCheck, setmodalCheck] = React.useState<boolean>(false);
	const [checkId, setCheckId] = React.useState<string>();
	const [array, setArray] = React.useState<any[]>([]);
	const [file, setFile] = React.useState<any>();

	// Effects
	React.useEffect(() => {
		(async () => {
			setloading(true);
			if (state?.FormFundProduct?.id) {
				const listCapitalCommited = await getCapitalCommited(state?.FormFundProduct?.id);
				setList(listCapitalCommited.message);
			}
			setlistTransaction([
				{
					legalId: "",
					name: "",
					subscriptionValue: "",
					capital: "",
				},
			]);
			setloading(false);
		})();
	}, [state]);

	const headerTransactionColumn = (key: string) => {
		switch (key) {
			case "personId":
				return "personId";
			case "legalId":
				return "Número Documento";
			case "Número Documento":
				return "legalId";
			case "subscriptionValue":
				return "Valor de Subscrição";
			case "Valor de Subscrição":
				return "subscriptionValue";
			case "capital":
				return "Valor para Caixa";
			case "Valor para Caixa":
				return "capital";
			case "name":
				return "Nome Cotista";
			case "Nome Cotista":
				return "name";
			default:
				return "";
		}
	};

	const filterColumns = (data: any) => {
		if (!data || data.length <= 0) return;
		const columns = Object.keys(data[0]);
		const headers: any = [];
		columns.forEach((col, idx) => {
			headers.push({label: headerTransactionColumn(col), key: col});
		});
		return headers;
	};

	const {register, handleSubmit} = useForm<{readonly file?: any}>({
		resolver: yupResolver(FormTransactionResolver),
		mode: "onSubmit",
	});

	const download = () => {};
	const onmodalImportTransactions = () => setmodalImportTransactions(true);
	const fileReader = new FileReader();
	const importTransactions = handleSubmit(async () => {
		if (file) {
			fileReader.onload = readCsv;

			fileReader.readAsText(file);
		}
	});

	const readCsv = (event: any) => {
		const csvOutput = event?.target?.result;
		csvFileToArray(csvOutput);
	};

	const csvFileToArray = async (text: any) => {
		setloading(true);
		const csvHeader = text.slice(0, text.indexOf("\n")).split(",");
		const csvRows = text.slice(text.indexOf("\n") + 1).split("\n");

		let transactions = csvRows.map((i: any) => {
			const values = i.split(",");
			const obj = csvHeader.reduce((linha: any, header: any, index: any) => {
				const parameter = headerTransactionColumn(header?.replace("\r", ""));
				linha[parameter] = values[index]?.replace("\r", "");
				return linha;
			}, {});
			if ((obj?.legalId && obj.legalId !== "") || (obj?.personId && obj.personId !== ""))
				return obj;
		});
		transactions = transactions.filter((item: any) => {
			return item !== undefined;
		});
		await postCapitalCommited(state?.FormFundProduct?.id, {transactions});
		setArray(transactions);
		setmodalImportTransactions(false);
		const listCapitalCommited = await getCapitalCommited(state?.FormFundProduct?.id);
		setList(listCapitalCommited.message);
		setloading(false);
	};
	const handleOnChange = (event: any) => {
		setFile(event.target.files[0]);
	};
	const onAction = (row: any) => {
		setmodalCheck(true);
		setCheckId(row.values.personId);
	};
	const checkTransaction = async () => {
		setloading(true);
		if (checkId) {
			await checkCapitalCommited(state?.FormFundProduct?.id, checkId);
		}
		setCheckId(undefined);
		setmodalCheck(false);
		setloading(false);
	};

	return (
		<>
			{modalCheck && (
				<C.CPNAlert
					isModal
					title="Check Chamada de Capital"
					description="Tem certeza que deseja confirmar o pagamento da chamada de capital?"
					type="INFO"
					loading={loading}
					onCancel={() => !loading && setmodalCheck(false)}
					onAccept={() => !loading && checkTransaction()}
				/>
			)}
			{modalImportTransactions && (
				<C.CPNAlert
					isModal
					title="Chamada de Capital"
					description="Faça o upload do arquivo da chamada de capital."
					body={
						<>
							<div className="input">
								<C.CPNTextInput
									type="file"
									label="Arquivo"
									{...register("file")}
									onChange={handleOnChange}
								/>
							</div>
						</>
					}
					type="INFO"
					loading={loading}
					onCancel={() => !loading && setmodalImportTransactions(false)}
					onAccept={() => !loading && importTransactions()}
				/>
			)}
			<div className="h-full overflow-y-auto">
				<br />
				<div className="header">
					<div className="header flex justify-between items-center mb-4">
						<div className="title flex flex-col">
							<U.UIText preset="HEADLINE_03">Chamada de Capital</U.UIText>
						</div>
					</div>
				</div>
				<br />
				<U.UIText preset="BODY">
					<div className="flex items-center rounded gap-2">
						<CSVLink
							data={listTransaction}
							headers={filterColumns(listTransaction)}
							filename={`ChamdaCapital_${state?.FormFundProduct?.id}.csv`}>
							<U.UIButton
								onClick={download}
								icon="download"
								label="Template Chamada de Capital"
								outline
							/>
						</CSVLink>
						<U.UIButton
							onClick={onmodalImportTransactions}
							label="Upload Chamada Capital"
							outline
						/>
						<CSVLink
							data={listTransaction}
							headers={[
								"Carteira",
								"Cliente",
								"Operação",
								"Data",
								"Valor",
								"Data Solicitações",
								"Digitação por",
								"Modo de Liq.",
								"Banco",
								"Agência",
								"Conta",
								"Nome",
								"Quantidade",
								"Valor da Cota",
								"Valor Efetivado",
							]}
							filename={`Daycoval_${state?.FormFundProduct?.id}_${moment(
								new Date(Date.now()),
								"DD/MM/YYYY",
							).format("YYYY-MM-DD")}.csv`}>
							<U.UIButton onClick={download} icon="download" label="Daycoval Boleta" outline />
						</CSVLink>
					</div>
				</U.UIText>
				<br />
				<div className="content">
					<div className="list">
						{loading ? (
							<C.CPNAlert
								title="Aguarde"
								description="Aguarde um momento enquanto trazemos todos as transações solicitadas aos cotistas"
								type="LOADING"
								loading
							/>
						) : error ? (
							<C.CPNAlert
								title="Tentar novamente"
								description="Aconteceu um error, tente novamente"
								type="ERROR"
								firstActionLabel="Tentar novamente"
								// onFirstAction={getFundProductListHandler}
							/>
						) : !list?.length ? (
							<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
								<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
							</div>
						) : (
							<CPNTable
								action="Check"
								onAction={onAction}
								columns={[
									{
										accessor: "personId",
									},
									{
										Header: "Número de Documento",
										accessor: "legalId",
									},
									{
										Header: "Nome",
										accessor: "shareholderName",
									},
									{
										Header: "Valor Subscrito",
										accessor: "subscriptionValue",
									},
									{
										Header: "Valor não Subscrito",
										accessor: "value",
									},
								].map((e) => ({...e, Cell: ({cell: {value}}: any) => value || "-"}))}
								data={list}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default FormTransaction;
