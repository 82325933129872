// Component generated with util/vox-create-component.js
import React from "react";
import * as C from "../../../component";
import * as U from "../../../ui";
import FundProductContext from "../../../context/FundProductContext";
import {CPNTable} from "../../../component";
import {CSVLink} from "react-csv";
import {getCapitalCommited} from "../../../service/api/fund-api";

const FormLoan: React.FC<any> = () => {
	const {state, dispatch} = React.useContext(FundProductContext);
	const [loading, setloading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<boolean>(false);
	const [list, setList] = React.useState<any[]>([]);
	const [listTransaction, setlistTransaction] = React.useState<any>([]);

	// Effects
	React.useEffect(() => {
		(async () => {
			setloading(true);
			if (state?.FormFundProduct?.id) {
				const listCapitalCommited = await getCapitalCommited(state?.FormFundProduct?.id);
				setList(listCapitalCommited.message);
			}
			setloading(false);
		})();
	}, [state]);

	const download = () => {};

	return (
		<>
			<div className="h-full overflow-y-auto">
				<br />
				<div className="header">
					<div className="header flex justify-between items-center mb-4">
						<div className="title flex flex-col">
							<U.UIText preset="HEADLINE_03">Cálculo de Mútuo</U.UIText>
						</div>
					</div>
				</div>
				<br />
				<U.UIText preset="BODY">
					<div className="flex items-center rounded gap-2">
						<CSVLink
							data={listTransaction}
							headers={[
								"Carteira",
								"Cliente",
								"Operação",
								"Data",
								"Valor",
								"Data Solicitações",
								"Digitação por",
								"Modo de Liq.",
								"Banco",
								"Agência",
								"Conta",
								"Nome",
								"Quantidade",
								"Valor da Cota",
								"Valor Efetivado",
							]}
							filename={`ChamdaCapital_${state?.FormFundProduct?.id}.csv`}>
							<U.UIButton onClick={download} icon="download" label="Histórico de Mútuo" outline />
						</CSVLink>
					</div>
				</U.UIText>
				<br />
				<div className="content">
					<div className="list">
						{loading ? (
							<C.CPNAlert
								title="Aguarde"
								description="Aguarde um momento enquanto trazemos todos os mútuos do fundo"
								type="LOADING"
								loading
							/>
						) : error ? (
							<C.CPNAlert
								title="Tentar novamente"
								description="Aconteceu um error, tente novamente"
								type="ERROR"
								firstActionLabel="Tentar novamente"
								// onFirstAction={getFundProductListHandler}
							/>
						) : !list?.length ? (
							<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
								<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
							</div>
						) : (
							<CPNTable
								columns={[
									{
										accessor: "id",
									},
									{
										Header: "Nome",
										accessor: "name",
									},
									{
										Header: "Valor Mercado",
										accessor: "marketValue",
									},
									{
										Header: "Valor de Custo",
										accessor: "costValue",
									},
								].map((e) => ({...e, Cell: ({cell: {value}}: any) => value || "-"}))}
								data={list}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default FormLoan;
