import {ResponseCognitoLogin} from "../../types/login";
import {SignUpDTO} from "../../types/signup";
import {HTTP} from "./../http";

const BASE_LOGIN_URL = `/login`;

export const signupVC = async (signUpDTO?: SignUpDTO): Promise<void> => {
	const query = `${BASE_LOGIN_URL}/vc/signup`;
	return await HTTP.post(query, signUpDTO);
};

export const userList = async (): Promise<ResponseCognitoLogin[]> => {
	const result = await HTTP.get(`${BASE_LOGIN_URL}/cognito/users`);
	return result.data as ResponseCognitoLogin[];
};
