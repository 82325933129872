import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormRegisterResolver} from "./../Resolvers/FormRegisterResolver";
import {FormRegisterSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {statusUser} from "./../../../enum/formEnums";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

const FormPersonalData: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {
		register,
		handleSubmit,
		formState: {errors},
		getValues,
	} = useForm<FormRegisterSchema>({
		resolver: yupResolver(FormRegisterResolver),
		mode: "onSubmit",
		defaultValues: {
			...(() => {
				let allstatus = {};
				if (state?.mode === "NEW") {
					allstatus = {
						status: "CUSTOMER_WITHOUT_LOGIN",
						statusLabel: statusUser.CUSTOMER_WITHOUT_LOGIN,
					};
				}
				return {...state?.formPersonalData, ...allstatus};
			})(),
		},
	});
	// Handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_PERSONAL_DATA",
			payload: {
				formPersonalData: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Dados pessoais</U.UIText>
			<div className="flex flex-col gap-2">
				<span className="prose prose-sm text-gray-light font-medium">Informações obrigatorias</span>
				<div className="grid grid-cols-3 gap-4">
					<div className="input hidden">
						<C.CPNTextInput
							label="Status"
							error={errors.status?.message}
							disabled
							{...register("status")}
						/>
					</div>
					<div className="input hidden">
						<C.CPNTextInput
							label="Status"
							error={errors.statusLabel?.message}
							disabled
							{...register("statusLabel")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Nome Social"
							error={errors.socialName?.message}
							disabled={loading}
							{...register("socialName")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Nome Completo"
							error={errors.fullName?.message}
							disabled={loading}
							{...register("fullName")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Email"
							error={errors.email?.message}
							disabled={loading}
							{...register("email")}
						/>
					</div>
				</div>
				<span className="prose prose-sm text-gray-light font-medium mt-4">Outras informações</span>
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNTextInput
							label="CPF"
							error={errors.cpf?.message}
							disabled={loading}
							{...register("cpf")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Suitability"
							options={[
								{
									id: uniqid(),
									label: "Conservador",
									value: "Conservador",
								},
								{
									id: uniqid(),
									label: "Moderado",
									value: "Moderado",
								},
								{
									id: uniqid(),
									label: "Agressivo",
									value: "Agressivo",
								},
								{
									id: uniqid(),
									label: "Dispensa",
									value: "Dispensa",
								},
							]}
							type="SIMPLE"
							error={errors.suitability?.message}
							disabled={loading}
							{...register("suitability")}
						/>
					</div>
					{getValues("suitability") && (
						<div className="input">
							<C.CPNTextInput
								label="Justificativa"
								error={errors.suitabilityDismiss?.message}
								{...register("suitabilityDismiss")}
							/>
						</div>
					)}
					<div className="input">
						<C.CPNSelectInput
							label="Risco"
							options={[
								{
									id: uniqid(),
									label: "Baixo",
									value: "Baixo",
								},
								{
									id: uniqid(),
									label: "Médio",
									value: "Médio",
								},
								{
									id: uniqid(),
									label: "Alto",
									value: "Alto",
								},
							]}
							type="SIMPLE"
							error={errors.risk?.message}
							disabled={loading}
							{...register("risk")}
						/>
					</div>
					<div className="input">
						<C.CPNDatePicker
							label="Data da próxima revisão cadastral"
							error={errors.reviewRegistration?.message}
							disabled={loading}
							{...register("reviewRegistration")}
						/>
					</div>
					<div className="input">
						<C.CPNDatePicker
							label="Data Nascimento"
							error={errors.birthDate?.message}
							disabled={loading}
							{...register("birthDate")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Naturalidade Cidade"
							error={errors.naturalnessCity?.message}
							disabled={loading}
							{...register("naturalnessCity")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Naturalidade Estado"
							error={errors.naturalnessState?.message}
							disabled={loading}
							{...register("naturalnessState")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Nacionalidade"
							error={errors.nationality?.message}
							disabled={loading}
							{...register("nationality")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Possui outra nacionalidade?"
							options={[
								{
									id: uniqid(),
									label: "Sim",
									value: "Sim",
								},
								{
									id: uniqid(),
									label: "Não",
									value: "Não",
								},
							]}
							type="SIMPLE"
							error={errors.extraNationality?.message}
							disabled={loading}
							{...register("extraNationality")}
						/>
					</div>
					{getValues("extraNationality") && (
						<div className="input">
							<C.CPNTextInput
								label="Segunda Nacionalidade"
								error={errors.nationality2?.message}
								{...register("nationality2")}
							/>
						</div>
					)}
					<div className="input">
						<C.CPNTextInput
							label="País de Domicílio Fiscal"
							error={errors.taxDomicile?.message}
							{...register("taxDomicile")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Estado Civil"
							placeholder="Qual o estado civil?"
							options={[
								{
									id: uniqid(),
									label: "Solteiro(a)",
									value: "Solteiro(a)",
								},
								{
									id: uniqid(),
									label: "Casado(a)",
									value: "Casado(a)",
								},
								{
									id: uniqid(),
									label: "Viúvo(a)",
									value: "Viúvo(a)",
								},
								{
									id: uniqid(),
									label: "Divorciado(a)",
									value: "Divorciado(a)",
								},
							]}
							type="SIMPLE"
							error={errors.civilStatus?.message}
							disabled={loading}
							{...register("civilStatus")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Sexo"
							options={[
								{
									id: uniqid(),
									label: "Feminino",
									value: "Feminino",
								},
								{
									id: uniqid(),
									label: "Masculino",
									value: "Masculino",
								},
								{
									id: uniqid(),
									label: "Outro",
									value: "Outro",
								},
							]}
							type="SIMPLE"
							error={errors.gender?.message}
							disabled={loading}
							{...register("gender")}
						/>
					</div>
					{getValues("gender") === "Outro" && (
						<div className="input">
							<C.CPNTextInput
								label="Sexo"
								error={errors.differentGender?.message}
								disabled={loading}
								{...register("differentGender")}
							/>
						</div>
					)}
					<div className="input">
						<C.CPNTextInput
							label="Nome da mãe"
							error={errors.motherName?.message}
							disabled={loading}
							{...register("motherName")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Nome do Cônjuge"
							error={errors.partnerName?.message}
							disabled={loading}
							{...register("partnerName")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="CPF do Cônjuge"
							error={errors.partnerCpf?.message}
							disabled={loading}
							{...register("partnerCpf")}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Tipo de Cotista (CVM)"
							options={[
								{
									id: uniqid(),
									label: "Pessoa física",
									value: "Pessoa física",
								},
								{
									id: uniqid(),
									label: "Pessoa jurídica não financeira",
									value: "Pessoa jurídica não financeira",
								},
								{
									id: uniqid(),
									label: "Banco comercial",
									value: "Banco comercial",
								},
								{
									id: uniqid(),
									label: "Corretora ou distribuidora",
									value: "Corretora ou distribuidora",
								},
								{
									id: uniqid(),
									label: "Outras pessoas jurídicas financeiras",
									value: "Outras pessoas jurídicas financeiras",
								},
								{
									id: uniqid(),
									label: "Investidores não residentes",
									value: "Investidores não residentes",
								},
								{
									id: uniqid(),
									label: "Entidade aberta de previdência complementar",
									value: "Entidade aberta de previdência complementar",
								},
								{
									id: uniqid(),
									label: "Entidade fechada de previdência complementar",
									value: "Entidade fechada de previdência complementar",
								},
								{
									id: uniqid(),
									label: "Regime próprio de previdência dos servidores públicos",
									value: "Regime próprio de previdência dos servidores públicos",
								},
								{
									id: uniqid(),
									label: "Sociedade seguradora ou resseguradora",
									value: "Sociedade seguradora ou resseguradora",
								},
								{
									id: uniqid(),
									label: "Sociedade de capitalização e de arrendamento mercantil",
									value: "Sociedade de capitalização e de arrendamento mercantil",
								},
								{
									id: uniqid(),
									label: "Fundos de investimento imobiliário",
									value: "Fundos de investimento imobiliário",
								},
								{
									id: uniqid(),
									label: "Outros fundos de investimento",
									value: "Outros fundos de investimento",
								},
								{
									id: uniqid(),
									label: "Cotistas de distribuidores do fundo (distribuição por conta e ordem)",
									value: "Cotistas de distribuidores do fundo (distribuição por conta e ordem)",
								},
								{
									id: uniqid(),
									label: "Outros tipos de cotistas não relacionados",
									value: "Outros tipos de cotistas não relacionados",
								},
							]}
							type="SIMPLE"
							error={errors.cvmType?.message}
							disabled={loading}
							{...register("cvmType")}
						/>
					</div>
				</div>
				<span className="prose prose-sm text-gray-light font-medium mt-4">
					Dados de Endereço e Contato
				</span>
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNTextInput label="CEP" {...register("address.cep")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Logradouro" {...register("address.street")} />
					</div>
					<div className="input">
						<C.CPNTextInput type="number" label="Nº" {...register("address.number")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Bairro" {...register("address.neighborhood")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Complemento" {...register("address.complement")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Cidade" {...register("address.city")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="Estado" {...register("address.state")} />
					</div>
					<div className="input">
						<C.CPNTextInput label="País" {...register("address.country")} />
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Telefone / Celular"
							error={errors.phone?.message}
							{...register("phone")}
						/>
					</div>
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormPersonalData;
