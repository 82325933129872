// Component generated with util/create-component.js
import styled from "styled-components";
import tw from "twin.macro";

/**
 * Components
 */
export const TableWrapper = styled.div.attrs({
	className: "w-full",
})`
	max-width: 100%;
	table,
	thead,
	tbody,
	tr {
		width: 100%;
		border-spacing: 0;
		border-collapse: separate;
	}

	.table-container {
		${tw`w-full flex`}
		table {
			${tw`flex-1`}
		}
		table th {
			/* min-width: calc(100vh / 5); */
			text-align: left;
		}
	}
	.search {
		.icon {
			${tw`absolute right-6 bottom-4`};
			/* right: 0;
			top: 50%; */
		}
	}
`;

export const TableSearch = styled.div`
	${tw` relative`}
	.search {
		${tw`pb-1 mb-2 relative`};
		/* input {
			${tw`w-full text-gray-dark rounded px-3 border rounded-lg border-gray-light focus:border-primary placeholder-gray-light disabled:border-gray-light disabled:bg-gray-lightest`};
		} */

		.icon {
			${tw`absolute right-2 bottom-4`};
		}
	}
`;

export const PaginationWrapper = styled.div.attrs({
	className: "flex justify-center items-center mt-7",
})`
	& button {
		${tw`mx-0.5 px-2`}
		min-width: unset;
		height: 32px;
	}
`;

export const CommonTh = styled.th`
	${tw`relative px-2 pt-3 font-medium`}
`;

export const CommonTd = styled.td`
	${tw`px-2 py-3 border-b border-gray-light`}
	${({width}) => (width ? `width: ${width}` : ``)}
`;

export const ActionTh = styled.th`
	${tw`font-medium prose prose-xs text-center px-2 flex items-center`}
	width: 40px;
`;
