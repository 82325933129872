import React, {createContext, useState, useEffect, useReducer} from "react";

export type FundProductForm = {
	formFundId?: string;
	FormFundProduct?: any;
};

export type FundProductState = {
	formFundId?: string;
	FormFundProduct?: any;
	mode?: "EDIT" | "NEW";
};

enum EType {
	"SET_FUND_PRODUCT_DATA",
	"CLEAR_FUND_PRODUCT_DATA",
}

interface IAppAction {
	readonly type?: keyof typeof EType;
	readonly payload?: FundProductState;
}

interface IAppContextData {
	readonly state: FundProductState | undefined;
	readonly dispatch: (data: IAppAction) => void;
}

const initialState: FundProductState = {
	formFundId: "",
	FormFundProduct: {},
	mode: "NEW",
};

const FundProductReducer = (state: FundProductState = initialState, action: IAppAction = {}) => {
	console.log("reducer: ", action);
	switch (action.type) {
		case "SET_FUND_PRODUCT_DATA":
			return {...state, FormFundProduct: action.payload?.FormFundProduct};
		case "CLEAR_FUND_PRODUCT_DATA":
			return {...initialState};
		default:
			return {...initialState};
	}
};

export interface IFundProductContextData {
	formFundId?: string;
	fundProductForm: FundProductForm;
	saveFundProductForm: (form: FundProductForm) => void;
	sendFundProductData: () => void;
	saveformFundId: (id: string) => void;
}

export const FundProductContext = createContext<IAppContextData>({} as IAppContextData);

export const FundProductProvider: React.FC = ({children}) => {
	const [state, dispatch] = useReducer(FundProductReducer, initialState);

	return (
		<FundProductContext.Provider
			value={{
				state,
				dispatch,
			}}>
			{children}
		</FundProductContext.Provider>
	);
};

export default FundProductContext;
