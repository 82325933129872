// Component generated with util/vox-create-component.js
import React from "react";
import {FormFundProductWrapper} from "../FormFundProduct/FormFundProduct.styled";
import {FormFundProductProps, FormFundProductDocumentSchema} from "./FormFundProduct.types";
import {useForm} from "react-hook-form";
import {string, SchemaOf, object} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as C from "../../component";
import * as U from "../../ui";
import {FundProduct} from "../../types/fund";
import {getAllProductsFund, getProductFundById, putProductFund} from "../../service/api/fund-api";
import {getDocumentList, getDocumentById} from "../../service/api/doc-api";
import {Document} from "../../types/document";
import {mixed} from "yup";

const FormFundProductDocumentsResolver: SchemaOf<FormFundProductDocumentSchema> = object().shape({
	id: string().required(),
	fullName: string().required(),
	documentType: string().required(),
	documentListSelect: string().required(),
	documentList: mixed().notRequired(),
	highlights: string().required(),
});

export const FormFundProductDocuments: React.VFC<FormFundProductProps> = ({...args}) => {
	const [list, setList] = React.useState<any>([]);
	const [docList, setDocList] = React.useState<any>([]);
	const [fundProduct, setFundProduct] = React.useState<any>([]);
	const [showForm, setShowForm] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [loadingData, setLoadingData] = React.useState<boolean>(true);
	const [currentError, setCurrentError] = React.useState<boolean>(false);

	const TYPE_FUND = "fund"; // from document api

	const {
		register,
		handleSubmit,
		formState: {errors},
		trigger,
		reset,
	} = useForm<FormFundProductDocumentSchema>({
		resolver: yupResolver(FormFundProductDocumentsResolver),
		mode: "onSubmit",
	});

	React.useEffect(() => {
		setLoadingData(true);
		getFundListHandler();
		setLoadingData(false);
	}, []);

	const getDocListHandler = async (documentRegisteredList: Document[] | undefined) => {
		const result = await getDocumentList();
		if (result.status !== "success") {
			console.error("Error on getting fund list", result.message);
			setCurrentError(true);
			return;
		}

		const documentListResult = result.message as Document[];
		if (documentRegisteredList) {
			setDocList(
				documentListResult
					.filter((document) => {
						return (
							document.path === TYPE_FUND &&
							documentRegisteredList.findIndex(
								(documentRegistered) => documentRegistered.id === document.id,
							) === -1
						);
					})
					.map((document: Document) => {
						return {
							id: document.id,
							label: document.name,
							value: document.id,
						};
					}),
			);
		} else {
			setDocList(
				documentListResult
					.filter((document) => {
						return document.path === TYPE_FUND;
					})
					.map((document: Document) => {
						return {
							id: document.id,
							label: document.name,
							value: document.id,
						};
					}),
			);
		}
	};

	const getFundListHandler = async () => {
		const result = await getAllProductsFund();
		if (result.status !== "success") {
			console.error("Error on getting fund list", result.message);
			setCurrentError(true);
			return;
		}
		const fundList = result.message as FundProduct[];
		setList(fundList);
	};

	const onEdit = (row: any) => {
		handleEdit(row.values.id);
		trigger();
		toogle();
	};

	const handleEdit = async (id: string) => {
		const result = await getProductFundById(id);
		if (result.status !== "success") {
			console.error("Error on getting fund by id", result.message);
			setCurrentError(true);
			return;
		}
		const fund = result.message as FundProduct;
		reset({
			...fund,
		});
		setFundProduct(fund);
		setList(fund.documentList ? fund.documentList : []);
		getDocListHandler(fund.documentList);
		setLoadingData(false);
	};

	const toogle = () => {
		setShowForm(!showForm);
	};

	const backListFund = () => {
		getFundListHandler();
		toogle();
	};

	const onSubmit = handleSubmit(async (data) => {
		setLoading(true);
		await handleAdd(data);
		handleEdit(data.id);
		setLoading(false);
	});

	const onDeleteDocument = async (row: any) => {
		setLoading(true);
		const doc = row.values;
		fundProduct.documentList = fundProduct.documentList.filter(
			(document: any) => document.id !== doc.id,
		);
		updateList(fundProduct);
		setLoading(false);
	};

	const updateList = async (data: any | undefined) => {
		const convertList: any[] = Array<any>();
		data.documentList.forEach((doc: any) =>
			convertList.push({
				documentId: doc.id,
				documentName: doc.name,
				documentType: doc.type,
				documentDate: doc.date,
				highlights: doc.highlights,
			}),
		);
		const request: FundProduct = {
			id: data.id,
			documentList: convertList,
		};
		const updateResponse = await putProductFund(request);
		if (updateResponse.status !== "success") {
			console.error("Error on update fund with document", updateResponse.message);
			setCurrentError(true);
			return;
		}
		setList(data.documentList);
	};

	const handleAdd = async (data: any | undefined) => {
		const responseDoc = await getDocumentById(data.documentListSelect);
		if (responseDoc.status !== "success") {
			console.error("Error on getting document by id", responseDoc.message);
			setCurrentError(true);
			return;
		}
		const documentDB: any = responseDoc.message;
		if (documentDB) {
			if (!data.documentList || data.documentList.length <= 0) {
				data.documentList = [];
			}
			data.documentList.push({
				id: documentDB.id,
				name: documentDB.name + "." + documentDB.fileExtension,
				type: data.documentType,
				date: documentDB.date,
				highlights: data.highlights,
			});
			updateList(data);
		}
	};

	return (
		<FormFundProductWrapper {...args}>
			{currentError && (
				<C.CPNAlert
					isModal
					title="Erro interno"
					description="Aconteceu um erro na execução da operação."
					type="ERROR"
					loading={false}
					onAccept={() => {
						setLoading(false);
						setCurrentError(false);
					}}
				/>
			)}
			<div className="header flex justify-between items-center mb-4">
				<div className={showForm ? "hidden" : "title mb-4 flex flex-col pb-2"}>
					<U.UIText preset="HEADLINE_05">Documentos do Fundo</U.UIText>
					<U.UIText preset="BODY">Relação de Documentos dos Fundos.</U.UIText>
				</div>
			</div>
			<div className={!showForm ? "hidden" : "forms"}>
				<div className="title mb-4 flex flex-col pb-2">
					<U.UIText preset="HEADLINE_05">Associação Fundo e Documento</U.UIText>
					<U.UIText preset="BODY">Fazer associação de documentos com fundos.</U.UIText>
				</div>
				<div className="title mb-4 flex flex-col pb-2">
					<C.CPNTextInput
						label="Nome completo do fundo"
						error={errors.fullName?.message}
						{...register("fullName")}
						disabled
					/>
				</div>
				<div className="form">
					<div className="input">
						<C.CPNSelectInput
							label="Documentos"
							enableSearch={true}
							options={docList}
							{...register("documentListSelect")}
							error={errors.documentListSelect?.message}
						/>
					</div>
					<div className="input">
						<C.CPNSelectInput
							label="Categoria do Documento"
							enableSearch={true}
							options={[
								{
									id: 1,
									label: "Relatório",
									value: "Relatório",
								},
								{
									id: 2,
									label: "Assembléias de Cotistas",
									value: "Assembléias de Cotistas",
								},
								{
									id: 3,
									label: "Fatos Relevantes",
									value: "Fatos Relevantes",
								},
								{
									id: 4,
									label: "Atas Comitês de Investimento",
									value: "Atas Comitês de Investimento",
								},
								{
									id: 5,
									label: "Análise da gestora sobre o fundo",
									value: "Análise da gestora sobre o fundo",
								},
								{
									id: 6,
									label: "Demonstrações Financeiras",
									value: "Demonstrações Financeiras",
								},
								{
									id: 7,
									label: "Regulamento",
									value: "Regulamento",
								},
								{
									id: 8,
									label: "Resultados de Impacto",
									value: "Resultados de Impacto",
								},
								{
									id: 9,
									label: "Comunicado",
									value: "Comunicado",
								},
								{
									id: 10,
									label: "Lâmina",
									value: "Lâmina",
								},
								{
									id: 11,
									label: "Prospecto",
									value: "Prospecto",
								},
							]}
							error={errors.documentType?.message}
							{...register("documentType")}
						/>
					</div>
					<div className="input">
						<C.CPNCheckBox label="Destaque" value="Destaque" {...register("highlights")} />
					</div>
				</div>
				<div className="action gap-4">
					<U.UIButton label="Cancelar" outline loading={loading} onClick={backListFund} />
					<U.UIButton label="Enviar" loading={loading} onClick={onSubmit} />
				</div>
				{!list.length ? (
					<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
						<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
					</div>
				) : (
					<C.CPNTable
						onDelete={onDeleteDocument}
						columns={[
							{
								accessor: "id",
							},
							{
								Header: "Nome",
								accessor: "name",
							},
							{
								Header: "Data",
								accessor: "date",
							},
							{
								Header: "Destaque",
								accessor: "highlights",
							},
						]}
						data={list}
					/>
				)}
			</div>
			{loadingData ? (
				<div className="loading bg-primary-lightest p-4 flex gap-2 items-center rounded-lg">
					<div className="animate-spin">
						<U.UIICon name="loading" />
					</div>
					<U.UIText preset="SUBTITLE">Trazendo informações, por favor aguarde...</U.UIText>
				</div>
			) : (
				<div className={showForm ? "hidden" : "table"}>
					{!list.length ? (
						<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
							<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
						</div>
					) : (
						<C.CPNTable
							onEdit={onEdit}
							columns={[
								{
									Header: "Id Britech",
									accessor: "id",
								},
								{
									Header: "Nome",
									accessor: "name",
								},
							]}
							data={list}
						/>
					)}
				</div>
			)}
		</FormFundProductWrapper>
	);
};

export default FormFundProductDocuments;
