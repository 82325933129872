/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as U from "../../../ui";
import * as C from "../../../component";
import {WrapperContainer, WrapperTitle, WrapperGrid} from "../FormRegister.styled";
import {LegalPerson} from "../../../types/register";
import {useHistory, useParams} from "react-router-dom";
import {getRegisterById} from "../../../service/api/registry-api";
import {statusUser} from "../../../enum/formEnums";
import {RegisterContext} from "../../../context/RegisterContext";

import DetailFunds from "../Detail/DetailFunds";
import DetailDocuments from "../Detail/DetailDocuments";
import DetailResponsible from "../Detail/DetailResponsible";

// interface FormRegisterDetailProps {}

const category = "LEGAL";

const FormRegisterPJDetail: React.FC<any> = () => {
	// Context
	const {dispatch} = React.useContext(RegisterContext);
	// States
	const [loading, setloading] = React.useState<boolean>(true);
	const [error, seterror] = React.useState<boolean>(false);
	const [individual, setindiviual] = React.useState<LegalPerson>();
	const [othersInformations, setothersInformations] = React.useState<boolean>(false);
	// Router
	const history = useHistory();
	const {registerid} = useParams<any>();
	// Effects
	React.useEffect(() => {
		if (registerid) {
			getIndividual();
		}
	}, [registerid]);
	// Handlers
	const toggleOthers = () => setothersInformations(!othersInformations);
	const getStatusLabel = (status: any) =>
		Object.entries(statusUser).find(([key, _]) => key === status)?.[1];
	const getIndividual = async () => {
		try {
			const response: LegalPerson = await getRegisterById(registerid, category);
			setindiviual({
				...response,
				tradeName: response.tradeName,
				category,
				statusLabel: getStatusLabel(response?.status),
			});
		} catch (_) {
			seterror(true);
		}
		setloading(false);
	};

	const editCurrentUser = () => {
		dispatch({
			type: "SET_REGISTER_DATA",
			payload: {
				mode: "EDIT",
				formLegalData: {
					// Common data
					status: individual?.status,
					statusLabel: individual?.statusLabel,
					cvmType: individual?.cvmType,
					tradeName: individual?.tradeName,
					corporateName: individual?.corporateName,
					cnpj: individual?.cnpj,
					suitability: individual?.suitability,
					suitabilityDismiss: individual?.suitabilityDismiss,
					risk: individual?.risk,
					reviewRegistration: individual?.reviewRegistration,
					incorporateDate: individual?.incorporateDate,
					incorporateType: individual?.incorporateType,
					differentIncorporateType: individual?.differentIncorporateType,
					mainActivity: individual?.mainActivity,
					// Address
					address: individual?.address,
					// Contact
					phone: individual?.phone,
					email: individual?.email,
				},
				formContactData: {
					contactName: individual?.contactName,
					contactPhone: individual?.contactPhone,
					contactEmail: individual?.contactEmail,
				},
				formFinancialData: {
					monthlyBilling: individual?.monthlyBilling,
					netPatrimony: individual?.netPatrimony,
					socialCapital: individual?.socialCapital,
					dateFinancial: individual?.dateFinancial,
				},
				formAdministrator: {
					administrator: individual?.administrator,
				},
				formRelatedCompany: {
					relatedCompany: individual?.relatedCompany,
				},
				formBankAccout: {
					externalAccount: individual?.externalAccount,
				},
				formPersonId: individual?.personId,
				formProcurator: {
					representative: individual?.representative,
				},
				formResidentInvestors: {
					extraTaxDomicile: individual?.extraTaxDomicile,
					taxId: individual?.taxId,
					thirtCanOperateName: individual?.thirtCanOperateName,
					thirtCanOperateCPF: individual?.thirtCanOperateCPF,
					portfolioAdministrator: individual?.portfolioAdministrator,
					custodyResponsible: individual?.custodyResponsible,
				},
			},
		});
		history.push("/FormRegisterPJ");
	};

	// Component
	return (
		<div className="container h-full overflow-y-auto">
			<div className="header">
				<div className="header flex justify-between items-center">
					<div className="title mb-4 flex flex-col pb-2">
						<div>
							<U.UIButton preset="LINK" icon="arrowBack" label="Voltar" onClick={history.goBack} />
						</div>
						<U.UIText preset="HEADLINE_05">Informações do cotista</U.UIText>
						<U.UIText preset="BODY" color="GREY">
							ID: {registerid}.
						</U.UIText>
					</div>
					<div className="flex flex-row gap-2">
						<U.UIButton
							righticon="chevronRight"
							label="Gerenciar cotista"
							onClick={editCurrentUser}
						/>
					</div>
				</div>
			</div>
			{loading ? (
				<C.CPNAlert
					title="Carregando"
					description="Aguarde um momento emquanto trazemos as informações do cotista"
					type="LOADING"
					loading
				/>
			) : (
				<div className="flex flex-col">
					<WrapperContainer>
						<WrapperTitle>
							<U.UIText preset="SUBTITLE" color="INHERIT">
								Dados básicos
							</U.UIText>
						</WrapperTitle>
						<WrapperGrid>
							<IndividualMetaData label="Razão Social" value={individual?.corporateName} />
							<IndividualMetaData label="Nome Fantasia" value={individual?.tradeName} />
							<IndividualMetaData label="CNPJ" value={individual?.cnpj} />
							<IndividualMetaData label="E-mail" value={individual?.email} />
							<IndividualMetaData label="Telefone" value={individual?.phone} />
							<IndividualMetaData
								label="Data de constituição"
								value={individual?.incorporateDate}
							/>
						</WrapperGrid>
					</WrapperContainer>
					<br />
					<WrapperContainer>
						<WrapperTitle>
							<U.UIText preset="SUBTITLE" color="INHERIT">
								Outras informações
							</U.UIText>
							<div
								className="flex flex-row items-center gap-2 cursor-pointer"
								onClick={toggleOthers}>
								<U.UIICon
									name={!othersInformations ? "chevronDown" : "chevronUp"}
									color="PRIMARY"
								/>
							</div>
						</WrapperTitle>
						{othersInformations ? (
							<>
								<WrapperGrid>
									<IndividualMetaData label="Status" value={getStatusLabel(individual?.status)} />
									<IndividualMetaData label="Suitability" value={individual?.suitability} />
									<IndividualMetaData label="Risco" value={individual?.risk} />
									<IndividualMetaData
										label="Data da próxima revisão cadastral"
										value={individual?.reviewRegistration}
									/>
								</WrapperGrid>
								<WrapperTitle>
									<U.UIText preset="SUBTITLE" color="INHERIT">
										Endereço
									</U.UIText>
								</WrapperTitle>
								<WrapperGrid>
									<IndividualMetaData label="CEP" value={individual?.address?.cep} />
									<IndividualMetaData label="Enedereço" value={individual?.address?.street} />
									<IndividualMetaData
										label="Número"
										value={individual?.address?.number?.toString()}
									/>
									<IndividualMetaData label="Bairro" value={individual?.address?.neighborhood} />
									<IndividualMetaData label="País" value={individual?.address?.country} />
									<IndividualMetaData label="Cidade" value={individual?.address?.city} />
									<IndividualMetaData label="Estado" value={individual?.address?.state} />
								</WrapperGrid>
							</>
						) : (
							<></>
						)}
					</WrapperContainer>
					<DetailFunds registerid={registerid} />
					<DetailDocuments
						registerid={registerid}
						documents={individual?.documentList}
						error={error}
					/>
					<DetailResponsible
						registerid={registerid}
						fullName={individual?.corporateName}
						responsibles={individual?.accessPerson || []}
						error={error}
					/>
				</div>
			)}
		</div>
	);
};

const IndividualMetaData: React.FC<{label: string; value?: string}> = ({label, value}) => {
	return (
		<div className="flex flex-col text-gray-common">
			<U.UIText preset="BODY_02" color="INHERIT">
				{label}
			</U.UIText>
			<U.UIText>{value || "-"}</U.UIText>
		</div>
	);
};

export default FormRegisterPJDetail;
