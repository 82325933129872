import React, {createContext, useState, useEffect, useReducer} from "react";

export type RegisterForm = {
	formPersonId?: string;
	formPersonalData?: any;
	formLegalData?: any;
	FormFundData?: any;
	formIdDocuments?: any;
	formProfessionalData?: any;
	formFinancialData?: any;
	formBankAccout?: any;
	formDeclaration?: any;
	formProcurator?: any;
	formResidentInvestors?: any;
	formBeneficiariesData?: any;
	formComplementData?: any;
	formComplementFundData?: any;
	formContactData?: any;
	formRelatedCompany?: any;
	formRepresentative?: any;
	formDocuments?: any;
};

export type RegisterState = {
	formPersonId?: string;
	formPersonalData?: any;
	formLegalData?: any;
	formFundData?: any;
	formIdDocuments?: any;
	formProfessionalData?: any;
	formFinancialData?: any;
	formAdministrator?: any;
	formBankAccout?: any;
	formDeclaration?: any;
	formProcurator?: any;
	formResidentInvestors?: any;
	formBeneficiariesData?: any;
	formComplementData?: any;
	formComplementFundData?: any;
	formContactData?: any;
	formRelatedCompany?: any;
	formRepresentative?: any;
	formFundStructure?: any;
	formDocuments?: any;
	mode?: "EDIT" | "NEW";
	userFunds?: any[];
	accessPerson?: any[];
};

enum EType {
	"SET_ADMINISTRATOR",
	"SET_PERSON_ID",
	"SET_PERSONAL_DATA",
	"SET_LEGAL_DATA",
	"SET_FUND_DATA",
	"SET_PERSONAL_DOCUMENTS",
	"SET_PROFESSIONAL_DATA",
	"SET_FINANCIAL_DATA",
	"SET_FAMILY_OFFICE",
	"SET_BANK_ACCOUNT",
	"SET_DECLARATION",
	"SET_PROCURATOR",
	"SET_RESIDENT_INVESTORS",
	"SET_BENEFICIARIES_DATA",
	"SET_COMPLEMENT_DATA",
	"SET_COMPLEMENT_FUND_DATA",
	"SET_RELATED_COMPANY",
	"SET_CONTACT_DATA",
	"SET_REPRESENTATIVE",
	"SET_OTHER_DOCUMENTS",
	"SET_REGISTER_DATA",
	"CLEAR_REGISTER_DATA",
	"SET_MODE",
	"SET_USER_FUNDS",
	"SET_FUND_DECLARATION",
	"SET_FUND_STRUCTURE",
	"SET_USER_ACCESS_PERSON",
}

interface IAppAction {
	readonly type?: keyof typeof EType;
	readonly payload?: RegisterState;
}

interface IAppContextData {
	readonly state: RegisterState | undefined;
	readonly dispatch: (data: IAppAction) => void;
}

const initialState: RegisterState = {
	formPersonId: "",
	formPersonalData: {},
	formLegalData: {},
	formFundData: {},
	formIdDocuments: {},
	formProfessionalData: {},
	formFinancialData: {},
	formBankAccout: [],
	formDeclaration: {},
	formProcurator: {},
	formResidentInvestors: {},
	formBeneficiariesData: {},
	formComplementData: {},
	formComplementFundData: {},
	formContactData: {},
	formRelatedCompany: {},
	formRepresentative: {},
	formFundStructure: {},
	formDocuments: [],
	mode: "NEW",
	userFunds: [],
};

const RegisterReducer = (state: RegisterState = initialState, action: IAppAction = {}) => {
	console.log("reducer: ", action);
	switch (action.type) {
		case "SET_BENEFICIARIES_DATA":
			return {...state, formBeneficiariesData: action.payload?.formBeneficiariesData};
		case "SET_COMPLEMENT_DATA":
			return {...state, formComplementData: action.payload?.formComplementData};
		case "SET_COMPLEMENT_FUND_DATA":
			return {...state, formComplementFundData: action.payload?.formComplementFundData};
		case "SET_RELATED_COMPANY":
			return {...state, formRelatedCompany: action.payload?.formRelatedCompany};
		case "SET_CONTACT_DATA":
			return {...state, formContactData: action.payload?.formContactData};
		case "SET_REPRESENTATIVE":
			return {...state, formRepresentative: action.payload?.formRepresentative};
		case "SET_PERSON_ID":
			return {...state, formPersonId: action.payload?.formPersonId};
		case "SET_PERSONAL_DATA":
			return {...state, formPersonalData: action.payload?.formPersonalData};
		case "SET_LEGAL_DATA":
			return {...state, formLegalData: action.payload?.formLegalData};
		case "SET_FUND_DATA":
			return {...state, FormFundData: action.payload?.formFundData};
		case "SET_PERSONAL_DOCUMENTS":
			return {...state, formIdDocuments: action.payload?.formIdDocuments};
		case "SET_PROFESSIONAL_DATA":
			return {...state, formProfessionalData: action.payload?.formProfessionalData};
		case "SET_FINANCIAL_DATA":
			return {...state, formFinancialData: action.payload?.formFinancialData};
		case "SET_BANK_ACCOUNT":
			return {...state, formBankAccout: action.payload?.formBankAccout};
		case "SET_DECLARATION":
			return {...state, formDeclaration: action.payload?.formDeclaration};
		case "SET_FUND_DECLARATION":
			return {...state, FormFundData: action.payload?.formDeclaration};
		case "SET_FUND_STRUCTURE":
			return {...state, FormFundData: action.payload?.formFundStructure};
		case "SET_PROCURATOR":
			return {...state, formProcurator: action.payload?.formProcurator};
		case "SET_RESIDENT_INVESTORS":
			return {...state, formResidentInvestors: action.payload?.formResidentInvestors};
		case "SET_OTHER_DOCUMENTS":
			return {...state, formDocuments: action.payload?.formDocuments};
		case "SET_FAMILY_OFFICE":
			return {...state, formProcurator: action.payload?.formProcurator};
		case "SET_REGISTER_DATA":
			return {...initialState, ...action.payload};
		case "CLEAR_REGISTER_DATA":
			return {...initialState};
		case "SET_USER_FUNDS":
			return {
				...state,
				userFunds: [
					...(() => state.userFunds || [])(),
					...(() => action.payload?.userFunds || [])(),
				],
			};
		case "SET_USER_ACCESS_PERSON":
			return {
				...state,
				accessPerson: [
					...(() => state.accessPerson || [])(),
					...(() => action.payload?.accessPerson || [])(),
				],
			};
		case "SET_MODE":
			return {...initialState, mode: action.payload?.mode};
		default:
			return {...initialState};
	}
};

export interface IRegisterContextData {
	formPersonId?: string;
	registerForm: RegisterForm;
	saveRegisterForm: (form: RegisterForm) => void;
	sendRegisterData: () => void;
	saveformPersonId: (id: string) => void;
}

export const RegisterContext = createContext<IAppContextData>({} as IAppContextData);

export const RegisterProvider: React.FC = ({children}) => {
	const [state, dispatch] = useReducer(RegisterReducer, initialState);
	// const [registerForm, saveform] = useState<RegisterForm>({});
	// const [formPersonId, saveformPersonId] = useState<string | undefined>();
	// const saveRegisterForm = (form: RegisterForm) => {
	// 	saveform({...registerForm, ...form});
	// };

	// const sendRegisterData = () => {
	// 	console.log("sendRegisterData");
	// };

	return (
		<RegisterContext.Provider
			value={{
				state,
				dispatch,
				// registerForm,
				// formPersonId,
				// saveRegisterForm,
				// sendRegisterData,
				// saveformPersonId,
			}}>
			{children}
		</RegisterContext.Provider>
	);
};

export default RegisterContext;
