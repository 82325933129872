import {RequestDocument, ResponseDocument, Document} from "./../../types/document";
import {axios, HTTP} from "./../http";

const BASE_DOC_URL = "/documents";

export const getDocumentList = async (): Promise<ResponseDocument> => {
	const result = await HTTP.get(`${BASE_DOC_URL}`);
	return result.data as ResponseDocument;
};

export const getDocumentById = async (id: string): Promise<ResponseDocument> => {
	const result = await HTTP.get(`${BASE_DOC_URL}/${id}`);
	return result.data as ResponseDocument;
};

export const putDocument = async (props: RequestDocument): Promise<ResponseDocument> => {
	const request = {
		id: props.id,
		name: props.document?.name,
		date: props.document?.date,
		// fileExtension: props.document?.fileExtension,
		// fileContent: props.file,
		// path: props.document?.path,
	};
	return await HTTP.put(`${BASE_DOC_URL}/${props.id}`, request);
};

export const postDocument = async (props: RequestDocument): Promise<ResponseDocument> => {
	if (props.document) {
		await uploadFile(props.document);
	}
	const response: ResponseDocument = {
		message: "",
		status: 200,
	};
	return response;
};

export const patchDocument = async (props: RequestDocument): Promise<ResponseDocument> => {
	return await HTTP.patch(`${BASE_DOC_URL}/${props.id}`, props);
};

export const removeDocumentById = async (id: string): Promise<ResponseDocument> => {
	return await HTTP.delete(`${BASE_DOC_URL}/${id}`);
};

export const downloadDocumentById = async (id: string): Promise<ResponseDocument> => {
	const download: ResponseDocument = await HTTP.get(`${BASE_DOC_URL}/url/adm/${id}`);
	console.log(download);
	return download.data;
};

export const downloadFile = async (fileUrl: string): Promise<any> => {
	// notice: axios directly because aws s3 url interceptor Authorization header error
	const downloadResult = await axios.get(fileUrl, {responseType: "blob"});
	return downloadResult;
};

export const uploadFile = async (document: Document): Promise<ResponseDocument> => {
	return new Promise(async (resolve, reject) => {
		try {
			const fileNameSplit = document.file[0].name.split(".");
			const fileExtension = fileNameSplit[fileNameSplit.length - 1];
			const name = document.file[0].name.replace(`.${fileExtension}`, ""); // file wit additional "." in the name
			const documentType = document.type;
			const contentType = document.file[0].type;
			const date = document.date;
			const bodyUpload = {
				name,
				fileExtension,
				documentType,
				contentType,
				date,
			};
			const result = await HTTP.post<any>(`${BASE_DOC_URL}`, bodyUpload);

			let responseDocument = result.data as ResponseDocument;
			if (responseDocument.fileUrl) {
				const file = document.file[0];
				// notice: axios directly because aws s3 url interceptor Authorization header error
				const resultUpload = await axios.put(responseDocument.fileUrl, file, {
					headers: {
						"Content-Type": contentType,
					},
				});
				const doc = {
					status: "ACTIVE",
				};
				if (resultUpload.status !== 200) {
					console.error("Error on file upload", resultUpload.data);
					throw new Error(`Erro on file upload ${resultUpload.data}`);
				}
				const resultStatus = await HTTP.patch<any>(`${BASE_DOC_URL}/${responseDocument.id}`, doc);
				if (resultStatus.status !== 200) {
					console.error("Error on updating file upload status", resultStatus.data.message);
					throw new Error(`Erro on file upload ${resultStatus.data.message}`);
				}
				responseDocument = {
					id: responseDocument.id,
					...resultStatus.data,
				};
			} else {
				console.log("Error on generate file url", responseDocument);
				throw new Error(`Erro on file upload ${responseDocument}`);
			}
			resolve(responseDocument);
		} catch (error) {
			reject(error);
		}
	});
};
