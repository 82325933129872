/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as U from "../../../ui";
import * as C from "../../../component";
import {WrapperContainer, WrapperTitle, WrapperGrid} from "../FormRegister.styled";
import {Individual} from "../../../types/register";
import {useHistory, useParams} from "react-router-dom";
import {getRegisterById, putRegister} from "../../../service/api/registry-api";
import {signupVC} from "../../../service/api/login-api";
import {statusUser} from "../../../enum/formEnums";
import {RegisterContext} from "../../../context/RegisterContext";
import {SignUpDTO} from "../../../types/signup";

import DetailFunds from "../Detail/DetailFunds";
import DetailDocuments from "../Detail/DetailDocuments";
import DetailResponsible from "../Detail/DetailResponsible";

const category = "INDIVIDUAL";

const FormRegisterDetail: React.FC<any> = () => {
	// Context
	const {dispatch} = React.useContext(RegisterContext);
	// States
	const [loading, setloading] = React.useState<boolean>(true);
	const [changingstatus, setchangingstatus] = React.useState<boolean>(false);
	const [hasaccess, sethasaccess] = React.useState<boolean>(false);
	const [error, seterror] = React.useState<boolean>(false);
	const [indiviual, setindiviual] = React.useState<Individual>();
	const [othersInformations, setothersInformations] = React.useState<boolean>(false);
	// Router
	const history = useHistory();
	const {registerid} = useParams<any>();
	// Effects
	React.useEffect(() => {
		if (registerid) {
			getIndividual();
		}
	}, [registerid]);

	/**
	 * It toggles the state of othersInformations.
	 */
	const toggleOthers = () => setothersInformations(!othersInformations);
	const getStatusLabel = (status: any) =>
		Object.entries(statusUser).find(([key, _]) => key === status)?.[1];
	const getIndividual = async () => {
		try {
			const response: Individual = await getRegisterById(registerid, category);
			setindiviual({
				...response,
				socialName: response.socialName,
				category,
				statusLabel: getStatusLabel(response?.status),
			});
			sethasaccess(indiviual?.status === "USER_ACTIVE");
		} catch (_) {
			seterror(true);
		}
		setloading(false);
	};

	/* A function that is responsible for enabling the user's access. */
	const toggleAccess = async () => {
		if (changingstatus && hasaccess) {
			return;
		}
		setchangingstatus(true);
		try {
			if (indiviual && indiviual.cpf) {
				const signUP: SignUpDTO = {
					username: indiviual.cpf,
					email: indiviual.email,
					socialName: indiviual.fullName,
					personId: indiviual.personId,
					profiles: [],
				};
				const result: any = await signupVC(signUP);
				await saveRegister();
				if (result.data && result.data.status === "Success") {
					sethasaccess(true);
				}
			} else {
				alert("O cotista não tem um CPF definido");
			}
		} catch (error: any) {
			const dataError: any = error.response?.dataError;
			if (dataError && dataError.message) {
				alert(
					`Aconteceu um error na hora de ativar o login do usuário, tente novamente: ${dataError.message}`,
				);
			} else {
				alert(`Aconteceu um error na hora de ativar o login do usuário, tente novamente: ${error}`);
			}
		}
		setchangingstatus(false);
	};

	const saveRegister = async () => {
		const request: any = {
			individual: {
				...indiviual,
				category,
				status: "USER_ACTIVE",
			},
			id: indiviual?.personId,
		};
		await putRegister(request);
	};

	const editCurrentUser = () => {
		dispatch({
			type: "SET_REGISTER_DATA",
			payload: {
				mode: "EDIT",
				formPersonalData: {
					// Common data
					personId: indiviual?.personId,
					status: indiviual?.status,
					cvmType: indiviual?.cvmType,
					statusLabel: indiviual?.statusLabel,
					relacionamento: indiviual?.relacionamento,
					socialName: indiviual?.socialName,
					fullName: indiviual?.fullName,
					cpf: indiviual?.cpf,
					suitability: indiviual?.suitability,
					suitabilityDismiss: indiviual?.suitabilityDismiss,
					risk: indiviual?.risk,
					reviewRegistration: indiviual?.reviewRegistration,
					birthDate: indiviual?.birthDate,
					// Address
					naturalnessCity: indiviual?.naturalnessCity,
					naturalnessState: indiviual?.naturalnessState,
					nationality: indiviual?.nationality2,
					extraNationality: indiviual?.extraNationality,
					nationality2: indiviual?.nationality2,
					taxDomicile: indiviual?.taxDomicile,
					address: indiviual?.address,
					// Contact
					phone: indiviual?.phone,
					email: indiviual?.email,
					// Others
					civilStatus: indiviual?.civilStatus,
					gender: indiviual?.gender,
					differentGender: indiviual?.differentGender,
					motherName: indiviual?.motherName,
					partnerName: indiviual?.partnerName,
					partnerCpf: indiviual?.partnerCpf,
				},
				formBankAccout: {
					externalAccount: indiviual?.externalAccount,
				},
				formDocuments: indiviual?.documentList,
				formDeclaration: {
					usPerson: indiviual?.usPerson,
					isProfessionalInvestidor: indiviual?.isProfessionalInvestidor,
					isQualifiedInvestidor: indiviual?.isQualifiedInvestidor,
					isLinkedVox: indiviual?.isLinkedVox,
					linkedVox: indiviual?.linkedVox,
					isPep: indiviual?.isPep,
					pep: indiviual?.pep,
					ofThirtParty: indiviual?.ofThirtParty,
				},
				formFinancialData: {
					income: {
						...indiviual?.income,
						resourceOrigin:
							indiviual?.income && indiviual?.income.resourceOrigin
								? indiviual?.income.resourceOrigin.toString()
								: "",
					},
				},
				formIdDocuments: {
					identityDocument: indiviual?.identityDocument,
				},
				formPersonId: indiviual?.personId,
				formProcurator: {
					representative: indiviual?.representative,
				},
				formProfessionalData: {
					occupation: indiviual?.occupation,
				},
				formResidentInvestors: {
					extraTaxDomicile: indiviual?.extraTaxDomicile,
					taxId: indiviual?.taxId,
					thirtCanOperateName: indiviual?.thirtCanOperateName,
					thirtCanOperateCPF: indiviual?.thirtCanOperateCPF,
					portfolioAdministrator: indiviual?.portfolioAdministrator,
					custodyResponsible: indiviual?.custodyResponsible,
				},
			},
		});
		history.push("/FormRegister");
	};

	// Component
	return (
		<div className="container h-full overflow-y-auto">
			{changingstatus && <U.UILoading />}
			<div className="header">
				<div className="header flex justify-between items-center">
					<div className="title mb-4 flex flex-col pb-2">
						<div>
							<U.UIButton preset="LINK" icon="arrowBack" label="Voltar" onClick={history.goBack} />
						</div>
						<U.UIText preset="HEADLINE_05">Informações do cotista</U.UIText>
						<U.UIText preset="BODY" color="GREY">
							ID: {registerid}.
						</U.UIText>
					</div>
					<div className="flex flex-row gap-2">
						<U.UIButton
							righticon={!hasaccess ? "check" : "checkFilled"}
							label={!hasaccess ? "Habilitar acesso" : "Cotista com acesso liberado"}
							outline
							preset="DARK"
							disabled={changingstatus || hasaccess}
							loading={changingstatus}
							onClick={toggleAccess}
						/>
						<U.UIButton
							righticon="chevronRight"
							label="Gerenciar cotista"
							onClick={editCurrentUser}
						/>
					</div>
				</div>
			</div>
			{loading ? (
				<C.CPNAlert
					title="Carregando"
					description="Aguarde um momento enquanto trazemos as informações do cotista"
					type="LOADING"
					loading
				/>
			) : (
				<div className="flex flex-col">
					<WrapperContainer>
						<WrapperTitle>
							<U.UIText preset="SUBTITLE" color="INHERIT">
								Dados básicos
							</U.UIText>
						</WrapperTitle>
						<WrapperGrid>
							<IndividualMetaData label="Nome completo" value={indiviual?.fullName} />
							<IndividualMetaData label="Nome Social" value={indiviual?.socialName} />
							<IndividualMetaData label="CPF" value={indiviual?.cpf} />
							<IndividualMetaData label="E-mail" value={indiviual?.email} />
							<IndividualMetaData label="Telefone" value={indiviual?.phone} />
							<IndividualMetaData label="Data de nascimento" value={indiviual?.birthDate} />
						</WrapperGrid>
					</WrapperContainer>
					<br />
					<WrapperContainer>
						<WrapperTitle>
							<U.UIText preset="SUBTITLE" color="INHERIT">
								Outras informações
							</U.UIText>
							<div
								className="flex flex-row items-center gap-2 cursor-pointer"
								onClick={toggleOthers}>
								<U.UIICon
									name={!othersInformations ? "chevronDown" : "chevronUp"}
									color="PRIMARY"
								/>
							</div>
						</WrapperTitle>
						{othersInformations ? (
							<>
								<WrapperGrid>
									<IndividualMetaData label="Status" value={getStatusLabel(indiviual?.status)} />
									<IndividualMetaData label="Suitability" value={indiviual?.suitability} />
									<IndividualMetaData label="Risco" value={indiviual?.risk} />
									<IndividualMetaData
										label="Data da próxima revisão cadastral"
										value={indiviual?.reviewRegistration}
									/>
									<IndividualMetaData
										label="Naturalidade Cidade"
										value={indiviual?.naturalnessCity}
									/>
									<IndividualMetaData
										label="Naturalidade Estado"
										value={indiviual?.naturalnessState}
									/>
									<IndividualMetaData label="Nacionalidade" value={indiviual?.nationality} />
									<IndividualMetaData label="Outra Nacionalidade" value={indiviual?.nationality2} />
									<IndividualMetaData
										label="País de Domicílio Fiscal"
										value={indiviual?.taxDomicile}
									/>
									<IndividualMetaData label="Estado Civil" value={indiviual?.civilStatus} />
									<IndividualMetaData label="Sexo" value={indiviual?.gender} />
									<IndividualMetaData label="Nome da mãe" value={indiviual?.motherName} />
								</WrapperGrid>
								<div className="my-4" />
								<WrapperTitle>
									<U.UIText preset="SUBTITLE" color="INHERIT">
										Informações do conjugue
									</U.UIText>
								</WrapperTitle>
								<WrapperGrid>
									<IndividualMetaData label="Nome do Cônjuge" value={indiviual?.partnerName} />
									<IndividualMetaData label="CPF do Cônjuge" value={indiviual?.partnerCpf} />
								</WrapperGrid>
								<div className="my-4" />
								<WrapperTitle>
									<U.UIText preset="SUBTITLE" color="INHERIT">
										Endereço
									</U.UIText>
								</WrapperTitle>
								<WrapperGrid>
									<IndividualMetaData label="CEP" value={indiviual?.address?.cep} />
									<IndividualMetaData label="Enedereço" value={indiviual?.address?.street} />
									<IndividualMetaData
										label="Número"
										value={indiviual?.address?.number?.toString()}
									/>
									<IndividualMetaData label="Bairro" value={indiviual?.address?.neighborhood} />
									<IndividualMetaData label="País" value={indiviual?.address?.country} />
									<IndividualMetaData label="Cidade" value={indiviual?.address?.city} />
									<IndividualMetaData label="Estado" value={indiviual?.address?.state} />
								</WrapperGrid>
							</>
						) : (
							<></>
						)}
					</WrapperContainer>
					<DetailFunds registerid={registerid} />
					<DetailDocuments
						registerid={registerid}
						documents={indiviual?.documentList}
						error={error}
					/>
					<DetailResponsible
						registerid={registerid}
						fullName={indiviual?.fullName}
						responsibles={indiviual?.accessPerson || []}
						error={error}
					/>
				</div>
			)}
		</div>
	);
};

const IndividualMetaData: React.FC<{label: string; value?: string}> = ({label, value}) => {
	return (
		<div className="flex flex-col text-gray-common">
			<U.UIText preset="BODY_02" color="INHERIT">
				{label}
			</U.UIText>
			<U.UIText>{value || "-"}</U.UIText>
		</div>
	);
};

export default FormRegisterDetail;
