// Component generated with util/create-component.js
import styled from "styled-components";
import tw from "twin.macro";

/**
 * Components
 */
export const FormAssetWrapper = styled.div`
	${tw`flex flex-col`}
	& > .forms {
		& > .form {
			${tw`flex flex-row flex-wrap`}
			& > .input {
				${tw`sm:w-full md:w-1/2 lg:w-1/3 2xl:w-1/4 mb-7 px-2`}
			}
		}
		& > .action {
			${tw`flex justify-end mb-6`}
		}
	}
`;

export const WrapperContainer = styled.div`
	${tw`flex flex-col border rounded border-gray-light bg-white pl-6 pr-6 pb-4 pt-4`}
`;

export const WrapperGrid = styled.div`
	${tw`grid grid-cols-3 gap-3`}
`;

export const WrapperTitle = styled.div`
	${tw`text-primary flex flex-row justify-between items-center  select-none`}
`;
