import * as React from "react";
import * as U from "../../../ui";
import * as C from "../../../component";
import {useHistory} from "react-router-dom";
import {downloadDocumentById, downloadFile} from "../../../service/api/doc-api";

interface DetailDocumentsProps {
	documents?: any[];
	registerid: string;
	error: boolean;
}

const DetailDocuments: React.FC<DetailDocumentsProps> = ({
	registerid,
	documents = [],
	error = false,
}) => {
	const history = useHistory();
	// States
	const [loading, setloading] = React.useState<boolean>(false);
	// Handlers
	const addNewDocument = () => {
		history.push(`/FormRegisterDocuments/${registerid}`, {documents});
	};
	const downloadCurrentFile = async (file: any) => {
		setloading(true);
		try {
			const documentDownload: any = await downloadDocumentById(file);
			const filename = documentDownload.name;
			const fileExtension = documentDownload.fileExtension;
			if (documentDownload.name && documentDownload.fileUrl) {
				const response = await downloadFile(documentDownload.fileUrl);
				const objectURL = URL.createObjectURL(response.data);
				const link = document.createElement("a");
				link.href = objectURL;
				link.setAttribute("download", filename + "." + fileExtension);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (e) {
			alert(`Aconteceu um error na hora de baixar o arquivo: ${JSON.stringify(e)}`);
		}
		setloading(false);
	};
	// Components
	return (
		<div className="fle flex-col mt-6">
			{loading && <U.UILoading />}
			<div className="flex pb-2 justify-between items-center">
				<U.UIText preset="SUBTITLE">Documentos do cotista</U.UIText>
				{documents.length > 0 && (
					<div className="flex gap-1 items-center cursor-pointer" onClick={addNewDocument}>
						<span className="prose prose-sm font-bold underline text-gray-common hover:text-gray">
							Adicionar novo documento
						</span>
						<U.UIICon color="GREY" name="addCircle" />
					</div>
				)}
			</div>
			{error ? (
				<C.CPNAlert
					title="Oops!"
					description="Aconteceu um error trazendo os fundos do usuário, tente novamente"
					type="ERROR"
				/>
			) : !documents.length ? (
				<C.CPNAlert
					title="Sem documentos"
					description="Este cotista não tem documentos anexados, se for necessario, adicione documentos aqui"
					type="INFO"
					onFirstAction={addNewDocument}
					firstActionLabel="Subir arquivos"
				/>
			) : (
				<C.CPNTable
					onDownload={({values: {id}}) => downloadCurrentFile(id)}
					columns={[
						{
							Header: "Nome",
							accessor: "name",
						},
						{
							Header: "id",
							accessor: "id",
						},
					]}
					data={documents}
				/>
			)}
		</div>
	);
};

export default DetailDocuments;
