/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as U from "../../../ui";
import * as C from "../../../component";
import * as R from "ramda";
import {
	getProductFundByPersonId,
	getAllProductsFund,
	postFundToUser,
	getClientIdByPersonId,
	patchBackofficeClient,
} from "../../../service/api/fund-api";
import {WrapperContainer} from "../FormRegister.styled";
import {RegisterContext} from "./../../../context/RegisterContext";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, SchemaOf, string} from "yup";

type FormClientIdSchema = {
	clientId: string;
};

export const FormClientIdResolver: SchemaOf<FormClientIdSchema> = object().shape({
	clientId: string().required(),
});

interface DetailFundsProps {
	registerid: string;
}

const DetailFunds: React.FC<DetailFundsProps> = ({registerid}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// States
	const [loading, setloading] = React.useState<boolean>(false);
	const [loadingClientId, setloadingClientId] = React.useState<boolean>(false);
	const [loadFunds, setloadFunds] = React.useState<boolean>(false);
	const [loadAddFunds, setloadAddFunds] = React.useState<boolean>(false);
	const [error, seterror] = React.useState<boolean>(false);
	const [errorclientid, seterrorclientid] = React.useState<any>(false);
	const [showmodal, setshowmodal] = React.useState<boolean>(false);
	const [showmodalClientId, setshowmodalClientId] = React.useState<boolean>(false);
	const [listFund, setlistFund] = React.useState<any[]>([]);
	const [clientId, setClientId] = React.useState<string>();
	const [fundsadded, setfundsadded] = React.useState<any>([]);
	const [unassociatedfunds, setunassociatedfunds] = React.useState<any[]>([]);
	// Effects
	React.useEffect(() => {
		if (registerid) {
			getIndividualFunds();
			getClientId();
		}
	}, [registerid]);
	// Handlers
	const {
		register,
		handleSubmit,
		formState: {errors},
	} = useForm<FormClientIdSchema>({
		resolver: yupResolver(FormClientIdResolver),
		mode: "onSubmit",
	});

	// Handlers
	const submitForm = handleSubmit(async (data) => {
		setloadingClientId(true);
		try {
			const request = {
				personId: registerid,
				clientId: data.clientId,
			};

			await patchBackofficeClient(request);
			setClientId(request.clientId);
			setshowmodalClientId(false);
		} catch (error) {
			seterrorclientid(error);
		}
		setloadingClientId(false);
	});

	const getIndividualFunds = async () => {
		setloading(true);
		try {
			const fund = await getProductFundByPersonId(registerid);
			if (fund) {
				const fundList = (fund.message as any[]).map((fundProduct: any) => {
					return {
						...fundProduct?.fund,
					};
				});
				setlistFund(fundList);
			}
		} catch (error) {
			seterror(true);
		}
		setloading(false);
	};

	const getClientId = async () => {
		setloading(true);
		try {
			const positions = await getClientIdByPersonId(registerid);
			if (positions) {
				const position = positions.message as any[];
				if (position) {
					setClientId(position[0]?.clientId);
				}
			}
		} catch (error) {
			seterror(true);
		}
		setloading(false);
	};
	const clearAllFunds = () => {
		setunassociatedfunds([]);
		setfundsadded({});
		setshowmodal(false);
		setshowmodalClientId(false);
	};
	const getAllFunds = async () => {
		try {
			setloadFunds(true);
			const response: any = await getAllProductsFund();
			if (response.status === "success" && response.message.length > 0) {
				setunassociatedfunds([...response.message.filter((f: any) => f.active)]);
				setshowmodal(true);
			} else if (response.status === "success") {
				alert("Não há nehum fundo disponivel no momento");
			} else {
				alert("Aconteceu um error trazendo os fundos disponiveis");
			}
		} catch (error) {
			alert("Aconteceu um error trazendo os fundos disponiveis");
		}
		setloadFunds(false);
	};

	const openClientIdModal = async () => {
		setshowmodalClientId(true);
	};

	const closeClientIdModal = async () => {
		if (!loadingClientId) {
			setshowmodalClientId(false);
		}
	};
	// const pushNewFundsToList = (fund: any) => {
	// 	const exists = fundsadded.find((f) => f.id === fund.id);
	// 	if (exists) {
	// 		setfundsadded([...fundsadded.filter((f) => f.id !== fund.id)]);
	// 	} else {
	// 		setfundsadded([...fundsadded, fund]);
	// 	}
	// };
	const addFundsToUser = async () => {
		setloadAddFunds(true);
		try {
			const result = await postFundToUser(registerid, fundsadded.id);
			if (result.status === "success") {
				dispatch({
					type: "SET_USER_FUNDS",
					payload: {
						userFunds: [fundsadded],
					},
				});
				clearAllFunds();
			}
		} catch (error) {
			alert(`error: ${error}`);
		}
		setloadAddFunds(false);
	};
	const deleteFund = async () => {};
	// Component
	return (
		<div className="fle flex-col mt-6">
			{loadFunds && (
				<div className="fixed left-0 top-0 right-0 bottom-0 z-50 bg-gray bg-opacity-70 flex items-center justify-center">
					<U.UIText color="WHITE">Aguarde um momento</U.UIText>
				</div>
			)}
			<C.CPNModal
				showModal={showmodal}
				title="Fundos disponíveis para associar"
				onModalClose={clearAllFunds}
				secondaryAction={{
					label: "Fechar",
					onClick: clearAllFunds,
				}}
				primaryAction={{
					label: fundsadded.id ? `Associar fundo(s)` : "Selecione pelo menos um fundo",
					disabled: !fundsadded.id || loadAddFunds,
					loading: loadAddFunds,
					onClick: () => (fundsadded.id ? addFundsToUser() : {}),
				}}>
				{unassociatedfunds.length > 0 ? (
					<div className="w-full">
						{/* TODO: Add filter */}
						{/* <div className="w-full mb-4">
							<input
								type="text"
								className="w-full py-3 px-3 focus:outline-none focus:border-gray-common focus:ring-gray-common border border-gray-light rounded"
								placeholder="Buscar fundo"
							/>
						</div> */}
						<div className="w-full flex flex-col border rounded border-gray-light">
							{R.sortBy(R.prop("fullName"), unassociatedfunds).map((uf: any) => {
								const exists = [...listFund, ...(state?.userFunds || [])].find(
									(r: any) => r.id === uf.id,
								);
								return (
									<div
										key={uf.id}
										className={`py-3 px-4 flex hover:bg-gray-glow cursor-pointer border-b border-gray-light justify-between ${
											exists ? "bg-gray-glow cursor-not-allowed" : "cursor-pointer"
										}`}
										onClick={() => setfundsadded(uf)}>
										<U.UIText preset="BODY_02">{uf.fullName}</U.UIText>
										{fundsadded.id === uf.id && !exists ? (
											<U.UIICon name="checkFilled" size="xs" color="PRIMARY" />
										) : exists ? (
											<U.UIText preset="BODY_03" color="PRIMARY">
												Este fundo já está associado
											</U.UIText>
										) : (
											<></>
										)}
									</div>
								);
							})}
						</div>
					</div>
				) : (
					<C.CPNAlert
						title="Oops!"
						description="Aparentemente não há fundos dispon;iveis no momento, adicione novos fundos."
						type="INFO"
					/>
				)}
			</C.CPNModal>
			<C.CPNModal
				showModal={showmodalClientId}
				title="Id cotista Inoa"
				loading={loadingClientId}
				onModalClose={closeClientIdModal}
				secondaryAction={{
					label: "Fechar",
					onClick: closeClientIdModal,
					disabled: loadingClientId,
				}}
				primaryAction={{
					label: `Associar id cotista`,
					loading: loadingClientId,
					onClick: submitForm,
				}}>
				<div className="w-full">
					<div className="w-full flex flex-col border rounded border-gray-light">
						<div>
							<div className="input">
								<C.CPNTextInput disabled={loadingClientId} {...register("clientId")} />
							</div>
						</div>
					</div>
				</div>
			</C.CPNModal>
			<WrapperContainer className="mb-4">
				<div className="flex flex-col">
					<div className="flex justify-left items-center gap-2">
						<U.UIText preset="SUBTITLE">Id cotista Inoa: </U.UIText>
						{!clientId ? (
							<div
								className="flex gap-1 items-center cursor-pointer ml-2"
								onClick={openClientIdModal}>
								<span className="prose prose-sm font-bold underline text-gray-common hover:text-gray">
									Associar id Inoa ao cotista
								</span>
								<U.UIICon color="GREY" name="addCircle" />
							</div>
						) : (
							<div className="gap-1 flex items-center">
								<U.UIText>{"  " + clientId || "-"}</U.UIText>
								<U.UIICon color="PRIMARY" name="checkFilled" />
							</div>
						)}
					</div>
				</div>
			</WrapperContainer>
			<div className="flex flex-col pb-2">
				<div className="flex justify-between items-center">
					<U.UIText preset="SUBTITLE">Fundos associados ao cotista</U.UIText>
					{(listFund.length > 0 || (state?.userFunds || []).length > 0) && (
						<div className="flex gap-1 items-center cursor-pointer" onClick={getAllFunds}>
							<span className="prose prose-sm font-bold underline text-gray-common hover:text-gray">
								Associar novo fundo ao cotista
							</span>
							<U.UIICon color="GREY" name="addCircle" />
						</div>
					)}
				</div>
			</div>
			{loading ? (
				<C.CPNAlert
					title="Carregando"
					description="Aguarde um momento enquanto trazemos as informações do cotista"
					type="LOADING"
					loading
				/>
			) : error ? (
				<C.CPNAlert
					title="Oops!"
					description="Aconteceu um error trazendo os fundos do usuário, tente novamente"
					type="ERROR"
				/>
			) : !listFund.length && !(state?.userFunds || []).length ? (
				<C.CPNAlert
					title="Sem fundos"
					description="Olá, este cotista não apresenta fundos, se desejar, pode associar fundos clicando no botão da direita"
					type="INFO"
					onFirstAction={() =>
						clientId && !errorclientid
							? getAllFunds()
							: alert("Para asocciar um fundo, precisa Associar id Inoa ao cotista primeiramente")
					}
					loading={loadFunds}
					firstActionLabel="Associar fundo"
				/>
			) : (
				<div>
					<C.CPNTable
						loading={loading}
						columns={[
							{
								Header: "Id",
								accessor: "id",
							},
							{
								Header: "Nome",
								accessor: "name",
							},
						]}
						data={[...listFund, ...(() => (state?.userFunds ? state.userFunds : []))()]}
					/>
				</div>
			)}
		</div>
	);
};

export default DetailFunds;
