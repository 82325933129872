// Component generated with util/vox-create-component.js

import React, {useState} from "react";
import {AuthSigninWrapper} from "./AuthSignin.styled";
import {AuthSigninProps} from "./AuthSignin.types";
import {UIButton} from "./../../ui";
import {AuthContext} from "./../../context/AuthContext";
import {federatedSignIn} from "./../../service/aws/auth";

export const AuthSignin: React.VFC<AuthSigninProps> = ({...args}) => {
	// Context Here
	const {setSession} = React.useContext(AuthContext);
	const [user, setUser] = useState(null);
	// Validations
	// Form
	// States Here
	// Effects Here
	// // Handlers Here

	// Component
	return (
		<AuthSigninWrapper {...args}>
			<div>
				<UIButton onClick={federatedSignIn} label="login" />
			</div>
		</AuthSigninWrapper>
	);
};

export default AuthSignin;
