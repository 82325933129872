export enum statusUser {
	USER_CREATING = "Criando Usuario",
	USER_WAITING_BACKGOUND_CHECKING = "Aguardando análise básica CAF",
	USER_PROCESSING_BACKGROUND_CHECKING_RESPONSE = "Processando análise básica CAF",
	USER_APPROVED = "Usuário Aprovado",
	USER_ACTIVE = "Usuário Ativo",
	USER_BLOCKED = "Usuário Bloqueado",
	CUSTOMER_INCOMPLETE = "Cadastro de cliente incompleto",
	CUSTOMER_WAITING_BACKGROUND_CHECKING_RESPONSE = "Aguardando Análise de Compliance CAF",
	CUSTOMER_PROCESSING_BACKGROUND_CHECKING_RESPONSE = "Processando Análise de Compliance CAF",
	CUSTOMER_ACTIVE = "Cliente Ativo",
	CUSTOMER_BLOCKED = "Cliente Bloqueado",
	CUSTOMER_WITHOUT_LOGIN = "Cliente sem Usuário",
}
export enum statusCognitoUser {
	CONFIRMED = "Confirmado",
	FORCE_CHANGE_PASSWORD = "Pendente alteração senha",
}
