import React, {createContext, useState, useEffect} from "react";
import {User} from "../types/auth";
import {signout, currentAuthenticatedUser, Hub} from "../service/aws/auth";
export interface IAuthContextData {
	appLoading: boolean;
	signed: boolean;
	accessToken: string | null;
	user: User | null;
	userSecret: any | null;
	resetUserName: any | null;
	removeSession: () => void;
	saveUserName: (user: any) => void;
	saveUserScret: (secret: any) => void;
	setSession: (user: User, accessToken: string, refreshToken: string) => void;
	clearSession: () => void;
}

export const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider: React.FC = ({children}) => {
	// States
	const [user, setUser] = useState<User | null>(null);
	const [userSecret, setUserScret] = useState<any | null>(null);
	const [signed, setSigned] = useState<boolean>(false);
	const [accessToken, setAccessToken] = useState<string | null>(null);
	const [resetUserName, setResetUserName] = useState<any | null>(null);
	const [appLoading, setAppLoading] = useState<boolean>(true);
	// Effects
	useEffect(() => {
		Hub.listen("auth", ({payload: {event, data}}) => {
			switch (event) {
				case "signIn":
					setSigned(true);
					break;
				case "signOut":
					setSigned(false);
					setUser(null);
					break;
			}
		});
		currentAuthenticatedUser()
			.then((userData) => {
				const userLocal: User = {
					idToken: userData.signInUserSession.idToken.jwtToken,
					name: userData.signInUserSession.idToken.payload.name,
					userName: userData.username,
				};
				const accessTokenLocal = userData.signInUserSession.accessToken.jwtToken;
				const refreshTokenLocal = userData.signInUserSession.refreshToken.token;
				setUser(userLocal);
				setAccessToken(accessTokenLocal);
				setSession(userLocal, accessTokenLocal, refreshTokenLocal);
				setSigned(true);
				localStorage.setItem("@Auth:token", userData.signInUserSession.idToken.jwtToken);
			})
			.catch((e) => {
				setSigned(false);
			});
		setAppLoading(false);
		return () => {};
	}, [signed]);

	// removeSession
	const removeSession = async () => {
		await signout();
		sessionStorage.clear();
		setUser(null);
		setAccessToken(null);
		setSigned(false);
		setResetUserName(null);
	};
	// setSession
	const setSession = async (pUser: User, pAccessToken: string, pRefreshToken: string) => {
		sessionStorage.setItem("@Auth:GDNI:user", JSON.stringify(pUser));
		sessionStorage.setItem("@Auth:GDNI:token", pAccessToken);
		setSigned(true);
		setResetUserName(null);
		setUserScret(null);
	};
	// clearSession
	const clearSession = () => {
		sessionStorage.removeItem("@Auth:GDNI:user");
		sessionStorage.removeItem("@Auth:GDNI:token");
		setSigned(false);
		setUserScret(null);
		setResetUserName(null);
	};
	// Save Username
	const saveUserName = (userName: any) => {
		setResetUserName(userName);
	};
	// Save User Secret
	const saveUserScret = (secret: any) => {
		setUserScret(secret);
	};
	return (
		<AuthContext.Provider
			value={{
				user,
				userSecret,
				resetUserName,
				saveUserName,
				saveUserScret,
				signed,
				removeSession,
				accessToken,
				appLoading,
				setSession,
				clearSession,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
