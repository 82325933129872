// Component generated with util/vox-create-component.js
import React, {useContext} from "react";
import {FormFundProductSchema} from "../FormFundProduct.types";
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import {string, SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as C from "../../../component";
import * as U from "../../../ui";
import {createProductFund, putProductFund} from "../../../service/api/fund-api";
import {getAssetListByType} from "../../../service/api/assets-api";
import {FundProduct} from "../../../types/fund";
import {Asset, ResponseGetAsset} from "../../../types/asset";
import {useHistory} from "react-router-dom";
import FundProductContext from "../../../context/FundProductContext";
import {FormAgentWrapper} from "../../FormAgent/FormAgent.styled";

const FormFundProductResolver: SchemaOf<FormFundProductSchema> = object().shape({
	id: string().required(),
	name: string().required(),
	fullName: string().required(),
	assetList: string().optional(),
	documentList: mixed().notRequired(),
	profitTarget: string().notRequired(),
	summary: string().notRequired(),
	expectation: string().notRequired(),
	risk: string().notRequired(),
	taxation: string().notRequired(),
	image: string().notRequired(),
	imageFile: mixed().notRequired(),
});

const FormFundProduct: React.FC<any> = () => {
	const [assetComboList, setAssetComboList] = React.useState<any>([]);
	const {state, dispatch} = React.useContext(FundProductContext);
	const [loading, setloading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<boolean>(true);
	const history = useHistory();
	const [isEdit, setIsEdit] = React.useState<boolean>(false);

	const {register, handleSubmit, reset} = useForm<FormFundProductSchema>({
		resolver: yupResolver(FormFundProductResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.FormFundProduct,
		},
	});

	const submitForm = handleSubmit(async (data) => {
		sendFormData({...data});
		history.goBack();
	});

	const sendFormData = async (data: any) => {
		setloading(true);
		const fund: FundProduct = {
			id: data.id,
			name: data.name,
			fullName: data.fullName,
			assetList: data.assetList?.length === 0 ? [] : data.assetList?.split(","),
			profitTarget: data.profitTarget,
			expectation: data.expectation,
			risk: data.risk,
			summary: data.summary,
			taxation: data.taxation,
			imageFile: data.imageFile,
		};

		const result = !isEdit ? await createProductFund(fund) : await putProductFund(fund);
		setIsEdit(false);
		if (result.status !== "success") {
			console.error("Error on creating fund", result.message);
			setloading(false);
			setError(true);
			return;
		}
		setloading(false);
	};

	// Effects
	React.useEffect(() => {
		(async () => {
			const response: ResponseGetAsset = await getAssetListByType({type: "BUSINESS"});
			if (response.status !== "success") {
				console.error("Error", response.message);
				setloading(false);
				setError(true);
				return;
			}
			const assetListResult = response.message as Asset[];
			const resultList = assetListResult.map((asset: Asset) => {
				return {
					id: asset.identity?.type + "#" + asset.identity?.id,
					label: asset.name,
					value: asset.identity?.type + "#" + asset.identity?.id,
				};
			});
			setAssetComboList(resultList);
			reset(state?.FormFundProduct);
		})();
	}, [state]);

	return (
		<>
			<FormAgentWrapper {...state?.FormFundProduct}>
				<div className="flex gap-8 h-full">
					{loading && <U.UILoading />}
					<div className="content flex-1 flex flex-col gap-4 h-full overflow-y-auto">
						<div className="flex justify-between items-center">
							<div className="title flex flex-col pb-2 justify-start">
								<U.UIText preset="HEADLINE_03">
									{state?.FormFundProduct?.id ? "Editar fundo" : "Adicionar novo fundo"}
								</U.UIText>
							</div>
						</div>
						<div className="flex-1 h-full">
							<div className="flex flex-col gap-4 mb-4">
								<div className="form grid grid-cols-3 gap-4">
									<div className="input">
										<C.CPNTextInput
											label="ID Carteira (Inoa)"
											disabled={loading}
											{...register("id")}
										/>
									</div>
									<div className="input">
										<C.CPNTextInput label="Nome" {...register("name")} />
									</div>
									<div className="input">
										<C.CPNTextInput label="Nome Completo" {...register("fullName")} />
									</div>
									{assetComboList.length > 0 && (
										<div className="input">
											<C.CPNSelectInput
												label="Ativo"
												options={assetComboList} // Add here ll options nedded
												type="MULTICHECK" // Single check or multicheck
												{...register("assetList")}
											/>
										</div>
									)}
									<div className="input">
										<C.CPNTextInput label="Rentabilidade Alvo" {...register("profitTarget")} />
									</div>
									<div className="input group">
										<U.UIText preset="BODY_02">Imagem do Fundo</U.UIText>
										<C.CPNTextInput
											type="file"
											placeholder="Upload imagem"
											disabled={loading}
											{...register(`imageFile`)}
										/>
										<U.UIText preset="BODY_03" color="GREY">
											Adicione a imagem que ira aparecer no fundo
										</U.UIText>
									</div>
								</div>
								<div className="form">
									<div className="input">
										<C.CPNTextarea
											label="Resumo"
											maxLength={250}
											disabled={loading}
											{...register("summary")}
										/>
									</div>
									<div className="input">
										<C.CPNTextarea
											label="Expectativa da VOX"
											maxLength={350}
											disabled={loading}
											{...register("expectation")}
										/>
									</div>
								</div>
								<div className="form">
									<div className="input">
										<C.CPNTextarea
											label="Risco"
											maxLength={20}
											disabled={loading}
											{...register("risk")}
										/>
									</div>
									<div className="input">
										<C.CPNTextInput label="Tributação" {...register("taxation")} />
									</div>
								</div>
								<div className="flex gap-4 justify-between">
									<div className="flex gap-4 justify-end">
										<U.UIButton
											label="Cancelar"
											outline
											loading={loading}
											onClick={history.goBack}
										/>
										<U.UIButton label="Enviar" loading={loading} onClick={submitForm} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</FormAgentWrapper>
		</>
	);
};

export default FormFundProduct;
