import React, {useContext} from "react";
import {BrowserRouter, Switch, Route, Redirect, RouteProps} from "react-router-dom";
import AuthContext from "./../context/AuthContext";
import * as Pages from "./../page";
import {CPNMenu, CPNHeader} from "./../component";

import PRIVATE_ROUTES from "./../data/routes.json";

export const PrivateRouter: React.FC<RouteProps> = ({children, ...args}) => {
	const {signed} = useContext(AuthContext);

	return (
		<Route
			{...args}
			render={({location}) =>
				signed ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/AuthSignIn",
							state: {from: location},
						}}
					/>
				)
			}
		/>
	);
};

export const Router: React.VFC = () => {
	const {signed, appLoading} = useContext(AuthContext);
	// tslint:disable-next-line:no-console
	console.log("PRIVATE_ROUTES: ", PRIVATE_ROUTES);
	if (appLoading) {
		return <span>Loading</span>;
	}
	if (signed) {
		return (
			<BrowserRouter>
				<div className="app-container flex w-full bg-white overflow-hidden">
					<div className="app-content flex flex-col w-full">
						<div className="app-header">
							<CPNHeader />
						</div>
						<div className="app-body app-body flex h-full overflow-hidden">
							<div className="app-menu">
								<CPNMenu />
							</div>
							<div className="p-7 w-full container overflow-y-auto">
								<Switch>
									{/* Private routes */}
									{PRIVATE_ROUTES.map((r) => (
										<PrivateRouter
											key={r}
											path={`/${r}`}
											component={(Pages as any)[r.split("/")[0]]}
										/>
									))}
									<Route component={() => <Redirect to="/AppHome" />} />
								</Switch>
							</div>
						</div>
					</div>
				</div>
			</BrowserRouter>
		);
	}
	return (
		<BrowserRouter>
			<Switch>
				{/* Public routes */}
				<Route path="/AuthSignIn" component={Pages.AuthSignIn} />
				<Route component={() => <Redirect to="/AuthSignIn" />} />
			</Switch>
		</BrowserRouter>
	);
};

export default Router;
