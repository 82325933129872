import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormPersonalDocumentSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import uniqid from "uniqid";

// interface FormPersonalDocumentsProps {}

const FormPersonalDocumentsResolver: SchemaOf<FormPersonalDocumentSchema> = object().shape({
	identityDocument: mixed().notRequired(),
});

const FormPersonalDocuments: React.FC<any> = ({sendFormData, loading, visible}) => {
	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// Form
	const {
		register,
		handleSubmit,
		formState: {errors},
		getValues,
	} = useForm<FormPersonalDocumentSchema>({
		resolver: yupResolver(FormPersonalDocumentsResolver),
		mode: "onSubmit",
		defaultValues: {
			...state?.formIdDocuments,
		},
	});
	// Handlers
	const submitForm = handleSubmit(async (data) => {
		dispatch({
			type: "SET_PERSONAL_DOCUMENTS",
			payload: {
				formIdDocuments: data,
			},
		});
		sendFormData({...data, personId: state?.formPersonId});
	});
	// Component
	return (
		<div className={`${visible ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			<U.UIText preset="HEADLINE_05">Documentos de identificação</U.UIText>
			<div className="flex-1 flex flex-col gap-2">
				<div className="grid grid-cols-3 gap-4">
					<div className="input">
						<C.CPNSelectInput
							label="Tipo de Documento"
							options={[
								{
									id: uniqid(),
									label: "RG",
									value: "RG",
								},
								{
									id: uniqid(),
									label: "CNH",
									value: "CNH",
								},
								{
									id: uniqid(),
									label: "Passaporte",
									value: "Passaporte",
								},
								{
									id: uniqid(),
									label: "Outro",
									value: "Outro",
								},
							]}
							type="SIMPLE"
							disabled={loading}
							{...register("identityDocument.type")}
						/>
					</div>
					{getValues("identityDocument.type") === "Outro" && (
						<div className="input">
							<C.CPNTextInput
								label="Tipo de Documento"
								error={errors.identityDocument?.differentDocType?.message}
								{...register("identityDocument.differentDocType")}
							/>
						</div>
					)}
					<div className="input">
						<C.CPNTextInput label="Número do Documento" {...register("identityDocument.number")} />
					</div>
					<div className="input">
						<C.CPNDatePicker
							label="Data de emissão"
							disabled={loading}
							error={errors.identityDocument?.issuanceDate?.message}
							{...register("identityDocument.issuanceDate")}
						/>
					</div>
					<div className="input">
						<C.CPNDatePicker
							label="Data de vencimento"
							disabled={loading}
							error={errors.identityDocument?.expirationDate?.message}
							{...register("identityDocument.expirationDate")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Órgão emissor"
							error={errors.identityDocument?.issuingAgency?.message}
							{...register("identityDocument.issuingAgency")}
						/>
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Estado emissor"
							error={errors.identityDocument?.issuingState?.message}
							{...register("identityDocument.issuingState")}
						/>
					</div>
				</div>
			</div>
			<div className="p-4 bg-primary-lightest flex justify-end bottom-0 left-0 right-0">
				<U.UIButton
					label="Enviar informações"
					onClick={submitForm}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default FormPersonalDocuments;
