// Component generated with util/vox-create-component.js
import React from "react";
import {icons} from "../../theme/icon";
import {UIICon, UIText, UIButton} from "../../ui";
import {Color} from "../../ui/Icon/Icon.types";
import {AlertWrapper, AlertModal} from "./Alert.styled";
import {AlertProps} from "./Alert.types";

const COLORS = {
	ATENTION: "SECONDARY",
	INFO: "BLUE",
	ERROR: "DANGER",
	SUCCESS: "GREEN",
	LOADING: "LOADING",
};

const ICONS = {
	ATENTION: "alert",
	INFO: "flag",
	ERROR: "errorFilled",
	SUCCESS: "checkFilled",
	LOADING: "loading",
};

export const Alert: React.VFC<AlertProps> = ({
	type = "ATENTION",
	body,
	title = "",
	description,
	loading,
	isModal,
	onCancel,
	onAccept,
	firstActionLabel,
	onFirstAction,
	...args
}) => {
	// Context Here
	// States Here
	// Effects Here
	// Handlers Here
	if (isModal) {
		return (
			<AlertModal>
				<div className="overlay" />
				<div className="content">
					<div className="body">
						<UIText preset="SUBTITLE">{title}</UIText>
						{description && (
							<div>
								<UIText>{description}</UIText>
							</div>
						)}
						{body}
					</div>
					<div className="footer">
						{onCancel && <UIButton label="Cancel" loading={loading} outline onClick={onCancel} />}
						{onAccept && <UIButton label="Aceitar" loading={loading} onClick={onAccept} />}
					</div>
				</div>
			</AlertModal>
		);
	} else {
		return (
			<AlertWrapper type={type} {...args}>
				<div className="">
					<div className={loading ? "animate-spin" : ""}>
						<UIICon
							name={ICONS[type] as keyof typeof icons}
							color={COLORS[type] as keyof typeof Color}
						/>
					</div>
				</div>
				<div className="ml-3 flex flex-1 justify-between ">
					<div className="flex flex-col flex-1">
						{title && <UIText preset="SUBTITLE">{title}</UIText>}
						{description && (
							<div className="mt-1">
								<UIText>{description}</UIText>
							</div>
						)}
					</div>
					{onFirstAction && (
						<div className="ml-4">
							<UIButton label={firstActionLabel} onClick={onFirstAction} preset="DARK" />
						</div>
					)}
				</div>
			</AlertWrapper>
		);
	}
};

export default Alert;
