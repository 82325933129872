import {Document} from "../../types/document";
import {Individual, RequestRegister, ResponseRegister, ResumeDocument} from "../../types/register";
import {uploadFile} from "../../service/api/doc-api";
import {HTTP} from "./../http";
import moment from "moment";

const BASE_REGISTRIES_URL = `/registries`;

export const getRegister = async (category?: string): Promise<ResponseRegister> => {
	let query = `${BASE_REGISTRIES_URL}/individual`;
	if (category) {
		query += "?category=" + category;
	}
	return await HTTP.get(query);
};

export const getRegisterById = async (id: string, category?: string): Promise<any> => {
	let query = `${BASE_REGISTRIES_URL}/individual/${id}`;
	if (category) {
		query += "?category=" + category;
	}
	const result: any = await HTTP.get(query);
	return result.data?.message;
};

export const putRegister = async (props: RequestRegister): Promise<ResponseRegister> => {
	return await HTTP.put(`${BASE_REGISTRIES_URL}/individual`, props.individual);
};

export const postRegister = async (props: RequestRegister): Promise<any> => {
	console.log("RequestRegister: ", props);
	const result: any = await HTTP.post(`${BASE_REGISTRIES_URL}/individual/`, props.individual);
	return result?.data?.message;
};

export const saveFile = async (props: Individual, document: Document): Promise<any> => {
	if (document && props) {
		document.type = "register";
		document.date = moment(Date.now()).format("DD/MM/YYYY");
		const idDoc = await uploadFile(document);
		return idDoc;
	}
};

export const getIndividualByAgent = async (agentId?: string): Promise<ResponseRegister> => {
	const query = `${BASE_REGISTRIES_URL}/agent/${agentId}/individual`;
	return await HTTP.get(query);
};

export const getAgentLegallByAgent = async (agentId?: string): Promise<ResponseRegister> => {
	const query = `${BASE_REGISTRIES_URL}/agentLegal/${agentId}/individual`;
	return await HTTP.get(query);
};

export const getAgentByAgentLegal = async (agentId?: string): Promise<ResponseRegister> => {
	const query = `${BASE_REGISTRIES_URL}/agentLegal/${agentId}/agent`;
	return await HTTP.get(query);
};
