import * as React from "react";
import {CPNTable} from "../../../component";
import * as U from "../../../ui";
import * as C from "../../../component";
import {getRegister} from "../../../service/api/registry-api";
import {ResponseRegister} from "../../../types/register";
import {statusUser} from "../../../enum/formEnums";
import {useHistory} from "react-router-dom";
import {RegisterContext} from "../../../context/RegisterContext";

interface FormRegisterPJListProps {
	list: any[];
	onEdit: (row: any) => void;
}

interface RegisterPJItemModel {
	cpf: string;
	fullName: string;
	personId: string;
	relacionamento: string;
	risk: string;
	socialName: string;
	status: string;
	statusLabel: string | undefined;
	suitability: string;
}

const category = "LEGAL";

const FormRegisterPJList: React.FC<FormRegisterPJListProps> = ({onEdit}) => {
	// Context
	const {dispatch} = React.useContext(RegisterContext);
	const history = useHistory();
	const [loading, setLoading] = React.useState<boolean>(true);
	const [error, setError] = React.useState<boolean>(true);
	const [list, setList] = React.useState<any[]>([]);

	React.useEffect(() => {
		getRegisterListHandler();
	}, []);

	// handlers
	const getRegisterListHandler = (): any => {
		try {
			setError(false);
			getRegister(category)
				.then((result: ResponseRegister) => {
					const resultList: RegisterPJItemModel[] = Array<RegisterPJItemModel>();
					result.data.message.forEach((e: RegisterPJItemModel) => {
						resultList.push({
							...e,
							statusLabel: Object.entries(statusUser).find(([key, val]) => key === e.status)?.[1],
						});
					});
					setList(resultList);
					setLoading(false);
				})
				.catch((reason: any) => {
					return () => setList([]);
				});
		} catch (error) {
			setError(true);
		}
	};

	const onResgisterItemSelected = (item: {values: RegisterPJItemModel}) => {
		dispatch({type: "CLEAR_REGISTER_DATA"});
		history.push(`/FormRegisterPJDetail/${item.values.personId}`);
	};

	const addNewIndividual = () => {
		dispatch({type: "CLEAR_REGISTER_DATA"});
		history.push("/FormRegisterPJ");
	};

	// Componente
	return (
		<div className="h-full overflow-y-auto">
			<br />
			<div className="header">
				<div className="header flex justify-between items-center mb-4">
					<div className="title flex flex-col">
						<U.UIText preset="HEADLINE_03">Lista de cotistas PJ</U.UIText>
						<U.UIText preset="BODY">Lista de cotistas PJ da Vox.</U.UIText>
					</div>
					<div className="action">
						<U.UIButton
							righticon="chevronRight"
							label="Adicionar novo PJ"
							onClick={addNewIndividual}
							// disabled={loading}
						/>
					</div>
				</div>
			</div>
			<br />
			<div className="content">
				<div className="list">
					{loading ? (
						<C.CPNAlert
							title="Aguarde"
							description="Aguarde um momento enquanto trazemos todos os cotistas"
							type="LOADING"
							loading
						/>
					) : error ? (
						<C.CPNAlert
							title="Tentar novamente"
							description="Aconteceu um error, tente novamente"
							type="ERROR"
							firstActionLabel="Tentar novamente"
							onFirstAction={getRegisterListHandler}
						/>
					) : !list.length && !loading ? (
						<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
							<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
						</div>
					) : (
						<CPNTable
							onEdit={onResgisterItemSelected}
							columns={[
								{
									accessor: "personId",
								},
								{
									Header: "Razão Social",
									accessor: "corporateName",
								},
								{
									Header: "Status",
									accessor: "statusLabel",
								},
								{
									Header: "CNPJ",
									accessor: "cnpj",
								},
								{
									Header: "Suitability",
									accessor: "suitability",
								},
								{
									Header: "Risco",
									accessor: "risk",
								},
								{
									Header: "Revisão Cadastral",
									accessor: "reviewRegistration",
								},
							]}
							data={list}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default FormRegisterPJList;
