// Component generated with util/create-component.js
import styled from "styled-components";
import tw from "twin.macro";

type ItemProps = {
	readonly selected?: boolean;
};

/**
 * Components
 */
export const MenuWrapper = styled.div`
	scrollbar-width: auto;
	scrollbar-color: #ee5fa8 #c9c9c9;
	::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background: rgba(247, 249, 255, var(--tw-bg-opacity));
	}

	::-webkit-scrollbar-thumb {
		background-color: #ee5fa8;
		border-radius: 0px;
		border: 3px none #ffffff;
	}

	overflow-y: auto;
	${tw`h-full`}
	width: 280px;
	& .logo {
		${tw`flex items-center mb-5`}
	}
`;

export const MenuItemWrapper = styled.div<ItemProps>`
	${tw`px-3 py-4 border-b border-gray-light`}
	& > .item {
		${({selected}) => (selected ? tw`bg-gray-light` : "")}
		${tw`flex rounded cursor-pointer my-0.5`}
		& > span {
			line-height: 1.3rem;
		}
	}
	& > .drop {
		${tw`py-1`}
	}
`;

export const SubMenuItemWrapper = styled.div<ItemProps>`
	${tw`flex items-center cursor-pointer rounded py-2 mb-0.5 px-4 border border-transparent hover:bg-gray-glow hover:text-gray`}
	${({selected}) => (selected ? tw`bg-gray-light` : ` `)}
`;
